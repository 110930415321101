import React from "react";
import {FieldProps} from "formik";
import {MenuItem, StandardTextFieldProps, TextField} from "@material-ui/core";
import {useAppCache} from "contexts/AppCacheProvider";

interface Props extends FieldProps, StandardTextFieldProps {
}

const CustomInputComponent: React.FC<Props> =
    ({field, form: {touched, errors}, ...props}) => (
        <TextField
            {...field}
            {...props}
            variant="outlined"
            helperText={errors[field.name]}
            error={(errors[field.name] && touched[field.name]) as boolean}
            fullWidth
        />
    );

interface LookupFieldProps extends FieldProps, StandardTextFieldProps {
    lookup: string;
}

export const LookupFieldComponent: React.FC<LookupFieldProps> =
    ({field, form: {touched, errors}, ...props}) => {
        const cache = useAppCache();
        return (
            <TextField
                {...field}
                {...props}
                select
                fullWidth
                value={field.value}
                variant="outlined"
                helperText={errors[field.name]}
                error={(errors[field.name] && touched[field.name]) as boolean}>

                {props.lookup && cache.lookups[props.lookup]?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                        {i.name}
                    </MenuItem>
                ))}
            </TextField>
        );
    }

export default CustomInputComponent;