import React from 'react'
import './FileUploadControl.scss';
import RUG from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';
import UploadHelper from "../../../util/UploadHelper";
import MessagingManager from "util/MessagingManager";
import {UploadPurpose} from "util/api/models/storage/UploadParameters";

interface Props {
    purpose: UploadPurpose;
    initialState?: string | string[];
    shopId?: string;
    limit?: number;
}

// NOTE: There are more properties for the Image, some of them might be useful later.
interface Image {
    source: string;
    done: boolean;
}

interface State {
    images: Image[];
}

interface CustomRequestProps {
    uid: string;
    file: File;
    error: string;
    onProgress: Function;
    onSuccess: Function;
    onError: Function;
}

// TODO: Support different file types
class FileUploadControl extends React.Component<Props, State> {

    readonly state: State = {
        images: []
    }

    constructor(props: Props) {
        super(props);
        this.customRequest = this.customRequest.bind(this);
    }

    private async getFileUrlAsync(file: File): Promise<string | undefined> {
        return new UploadHelper().upload(this.props.purpose, file, this.props.shopId)
            .then(async (url) => {
                return (url);
            })
            .catch(() => {
                return undefined;
            });
    }

    // TODO: Support onProgress
    private async customRequest(props: CustomRequestProps) {
        const url = await this.getFileUrlAsync(props.file);
        if (url) {
            props.onSuccess(props.uid, url);
        } else {
            props.onError(props.uid, {
                status: props.error,
                response: props.error
            });
        }
    }

    public async getUrls(): Promise<string[]> {
        return new Promise<string[]>(resolve => {
            if (this.state.images.some(i => !i.done)) {
                MessagingManager.toast({message: "Please wait for the image(s) to upload", type: "info"});
            }

            let interval = setInterval(() => {
                if (this.state.images.some(i => !i.done)) {
                    return;
                }

                clearInterval(interval);
                resolve(this.state.images.map(i => i.source));
            }, 500);
        })
    }

    render() {
        const initialState = (typeof this.props.initialState === "string") ? [{source: this.props.initialState}] :
            this.props.initialState?.map(url => {
                return {source: url};
            }).reverse();

        const rules = {
            limit: this.props.limit
        };

        return (
            <RUG
                customRequest={this.customRequest.bind(this)}
                source={(url: string) => url}
                initialState={initialState}
                rules={rules}
                onChange={(images: Image[]) => {
                    this.setState({images})
                }}/>
        );
    }
}

export default FileUploadControl;