import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import TempStore from "./redux/messageStore";
import './extension.d.ts';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={TempStore}>
            <SnackbarProvider maxSnack={5}>
                <App/>
            </SnackbarProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
