import config from "util/config";

const cryptoJS = require("crypto-js");

export default class CryptoProvider {

    static getHashedBase64(text, secret = config.identity.clientSecret) {
        const hash = cryptoJS.HmacSHA256(text, secret);
        return cryptoJS.enc.Base64.stringify(hash);
    }

};