import React, {FC} from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {default as AdminRouter} from 'admin/routers/Router';
import StatusPage from 'pages/StatusPage';
import ScrollToTop from "../ScrollToTop";
import AuthManager from "util/AuthManager";
import terminologies from "terminologies";
import LoginPage from "pages/account/LoginPage";
import ForgotPasswordPage from "pages/account/ForgotPasswordPage";

const Router: FC = () => {
    const t = terminologies;

    return (
        <BrowserRouter>
            <ScrollToTop>
                {AuthManager.user.isAuthenticated ?
                    <Switch>
                        {AuthManager.user.isAppAdmin && <Route exact path="/status" component={StatusPage}/>}
                        <Route path={"/"} component={AdminRouter}/>
                    </Switch>
                    :
                    <Switch>
                        <Route exact path={`/${t.account}/${t.login}`} component={LoginPage}/>
                        <Route exact path={`/${t.account}/${t.forgotPassword}`} component={ForgotPasswordPage}/>
                        <Redirect to={`/${t.account}/${t.login}`}/>
                    </Switch>
                }
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default Router;