import BaseApi from "./BaseApi";
import ApiResponseOptions from "./models/ApiResponseOptions";
import ApiResponse from "./models/ApiResponse";
import Lookup from "models/Lookup";

class LookupApi extends BaseApi {
    private _admin?: AdminLookupApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminLookupApi();
        }
        return this._admin;
    }

    async getLookupsAsync(options?: ApiResponseOptions):
        Promise<Record<string, Lookup[]> | ApiResponse<Record<string, Lookup[]>> | undefined> {
        return this.get("lookups", options);
    }
}

class AdminLookupApi extends BaseApi {
    async createLookupAsync(key: string, lookup: Partial<Lookup>, options?: ApiResponseOptions):
        Promise<Lookup | ApiResponse<Lookup> | undefined> {
        return this.post<Lookup>(`lookups/${key}`, lookup, options);
    }

    async updateLookupAsync(key: string, lookup: Lookup, options?: ApiResponseOptions):
        Promise<Lookup | ApiResponse<Lookup> | undefined> {
        const path = `lookups/${key}/${lookup.id}`;
        return this.put<Lookup>(path, lookup, options);
    }

    async deleteLookupAsync(key: string, id: string, options?: ApiResponseOptions):
        Promise<Lookup | ApiResponse<Lookup> | undefined> {
        const path = `lookups/${key}/${id}`;
        return this.delete<Lookup>(path, null, options);
    }
}

export default LookupApi;