import React, {FC} from 'react';
import {Redirect, Switch} from "react-router-dom";
import AuthManager from "util/AuthManager";

const CategoryRouter: FC = () => {
    return (
        <Switch>
            {!AuthManager.user.isAppAdmin && <Redirect to={"/404"}/>}

            <Redirect to="/404"/>
        </Switch>
    );
}

export default CategoryRouter;