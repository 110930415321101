import AppCache from "util/AppCache";
import Transaction from "models/Transaction";
import ApiResponseOptions from "util/api/models/ApiResponseOptions";
import BaseApi from "util/api/BaseApi";
import ApiResponse from "util/api/models/ApiResponse";

class TransactionApi extends BaseApi {

    private _admin?: AdminTransactionApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminTransactionApi();
        }
        return this._admin;
    }

    async getTransaction(transactionId: string, options?: ApiResponseOptions):
        Promise<Transaction[] | ApiResponse<Transaction[]> | undefined> {
        const anonymousCartId = AppCache.getAnonymousCartId();
        return this.get(`transaction/${transactionId}${anonymousCartId ? `?anonymousCartId=${anonymousCartId}` : ""}`, options);
    }

    async getTransactions(options?: ApiResponseOptions):
        Promise<Transaction[] | ApiResponse<Transaction[]> | undefined> {
        const anonymousCartId = AppCache.getAnonymousCartId();
        return this.get(`transaction${anonymousCartId ? `?anonymousCartId=${anonymousCartId}` : ""}`, options);
    }

}

class AdminTransactionApi extends BaseApi {

    async getTransaction(shopId: string, transactionId: string, customerUserId: string, options?: ApiResponseOptions):
        Promise<Transaction[] | ApiResponse<Transaction[]> | undefined> {
        const path = `transaction/${transactionId}?shopId=${shopId}&customerUserId=${customerUserId}`;
        return this.get(path, options);
    }

    async getAnonymousTransaction(shopId: string, transactionId: string, anonymousCartId: string, options?: ApiResponseOptions):
        Promise<Transaction[] | ApiResponse<Transaction[]> | undefined> {
        const path = `transaction/${transactionId}?shopId=${shopId}&anonymousCartId=${anonymousCartId}`;
        return this.get(path, options);
    }

    async confirmTransaction(transactionId: string, userId: string, isAnonymous: boolean,
                             provider: string, details: Record<string, any>, options?: ApiResponseOptions):
        Promise<any | ApiResponse<any> | undefined> {
        const _userId = isAnonymous ? `Anon-${userId}` : userId;
        const path = `transaction/${provider}?userId=${_userId}&transactionId=${transactionId}`;
        return this.post(path, details, options);
    }

}

export default TransactionApi;