import { AxiosRequestConfig, AxiosResponse } from "axios";
import AuthTokenManager from "util/tokens/AuthTokenManager";
import UserContext from "util/UserContext";
import Api from "./api";
import {
    ApiClientHandlers,
    ApiClientHandlerResult,
    ResultStatus
} from "util/api/client";

class AuthManager {

    private _user: UserContext;
    private _token: AuthTokenManager;

    constructor() {
        this._token = new AuthTokenManager();
        this._user = new UserContext(this._token);

        if (this._user.isAuthenticated) {
            this.subscribeApiClientHandlers();
        }
    }

    subscribeApiClientHandlers() {
        ApiClientHandlers.addRequestHandler(async (request: AxiosRequestConfig) => {
            return this.shouldRefreshToken(request);
        });

        ApiClientHandlers.addResponseHandler(async (response: AxiosResponse) => {
            return this.checkResponseTokenExpiry(response);
        });
    }

    unsubscribeApiClientHandlers() {
        ApiClientHandlers.clearRequestHandlers();
        ApiClientHandlers.clearResponseHandlers();
    }

    async shouldRefreshToken(request: AxiosRequestConfig) {
        if (!request.url?.startsWith("authentication/refresh") && this._token.isExpired && !await this.refreshTokenAsync()) {
            return new ApiClientHandlerResult({
                status: ResultStatus.ABORT,
                message: "Session Expired",
                data: request
            });
        }

        if (this.user.isAuthenticated) {
            const token = this.token;
            request.headers['x-lit-access-token'] = token.accessToken;
            request.headers['x-lit-id-token'] = token.idToken;
        }

        return new ApiClientHandlerResult({ data: request });
    }

    async checkResponseTokenExpiry(response: AxiosResponse) {
        if (!response.config.url?.startsWith("authentication/refresh") && response.data && response.data.success === false &&
            response.data.status === "Expired") {

            console.log("Token is Expired");

            if (await this.refreshTokenAsync()) {
                return new ApiClientHandlerResult({ status: ResultStatus.RESEND, data: response });
            } else {
                response.status = 401;
            }
        }

        return new ApiClientHandlerResult({ data: response });
    }

    // TODO: This could be moved to Redux later
    get user(): UserContext {
        return this._user;
    }

    get token(): AuthTokenManager {
        return this._token;
    }

    async authenticateAsync(username: string, password: string) {
        this.clear();

        const data = await Api.auth.authenticateAsync(username, password);

        if (data && data.success) {
            if (data.output.challenge && data.output.challenge === "NEW_PASSWORD_REQUIRED") {
                this.setSession(data.output.session);
                return data.output.challenge;
            } else if (data.status === "Incomplete") {
                return data.status;
            } else {
                this.setAuth(data.output.token);
                return "Success";
            }
        } else {
            // TODO: Remove once ResponseHandle implemented
            return data;
        }
    }

    async refreshTokenAsync(): Promise<boolean> {
        const data = await Api.auth.refreshTokenAsync(this.user.cognitoUsername,
            this.token.refreshToken, this.token.deviceKey);

        if (data && data.success === true && data.output) {
            this.setAuth(data.output.token);
            console.log("Token Refreshed");
            return true;
        }

        this.clear();
        console.log("Cannot Refresh Token");
        return false;
    }

    setAuth(token: any) {
        // TODO: Handle storing the data in case of don't remember me
        this._token = new AuthTokenManager(token);
        this._user = new UserContext(token);
        this.subscribeApiClientHandlers();
    }

    clear() {
        this._token.clearToken();
        this._token = new AuthTokenManager();
        this._user = new UserContext();
        this.unsubscribeApiClientHandlers();
    }

    setSession(session: string) {
        localStorage.setItem("Session", session);
    }

}

const instance = new AuthManager();
export default instance;