import React, {FC} from "react";
import {createTheme, MuiThemeProvider} from "@material-ui/core";
import Model from "models/Model";
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";
import {useAppCache} from "contexts/AppCacheProvider";
import CustomField from "models/CustomField";

interface Props {
    onItemClicked: (model: Model) => void;
    data: Model[];
    fields: CustomField[];
}

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: "bold"
            }
        }
    }
});

const CustomFieldsListControl: FC<Props> = (props: Props) => {
    const cache = useAppCache();

    const tableColumns: MUIDataTableColumnDef[] = props.fields
        .filter(f => f.settings?.indexable ?? true)
        .map(f => ({
            name: f.name,
            label: f.label,
            options: {
                display: f.settings?.indexed,
                filter: f.settings?.filterable,
                searchable: f.settings?.searchable
            }
        }))

    // NOTE: The attributes are mapped like to fix the sorting issue; using 'customBodyRender'
    //  will prevent the table to sort the data properly.
    const data = props.data.map(d => {
        if (d.customAttributes) {
            const attributes = {...d.customAttributes}
            props.fields.forEach(f => {
                if (attributes && f.type === "lookup" && f.values) {
                    let val = attributes[f.name];
                    attributes[f.name] = cache.lookups[f.values]?.find(l => l.id === val)?.name || "N/A"
                }
            })
            return attributes
        }
        return null
    })

    return (
        <div style={{display: 'table', tableLayout: 'fixed', width: '100%'}}>
            <MuiThemeProvider theme={theme}>
                <MUIDataTable
                    title={""}
                    data={data as object[]}
                    columns={tableColumns}
                    options={{
                        selectableRows: "none",
                        rowsPerPageOptions: [],
                        print: "false",
                        download: "false",
                        responsive: "standard",
                        onRowClick: (rowData, rowMeta) => {
                            props.onItemClicked(props.data[rowMeta.dataIndex]);
                        }
                    }}
                />
            </MuiThemeProvider>
        </div>
    );
}

export default CustomFieldsListControl;