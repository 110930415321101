import React, {FC} from 'react';
import Table from "@material-ui/core/Table";
import {Divider, Grid, TableContainer, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import OrderItem from "models/OrderItem";
import RatioContainer from "components/controls/common/RatioContainer";
import {HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Typography} from "components/controls/helpers/Typography";

interface Props {
    items: OrderItem[];
}

const OrderItemsList: FC<Props> = (props: Props) => {

    return (
        <TableContainer>
            <Table style={{minWidth: 650}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align={"right"}>Price</TableCell>
                        <TableCell align={"right"}>Qty.</TableCell>
                        <TableCell align={"right"}>Total</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        props.items.map((item) => {
                            let totalUnitPrice = item.unitPrice;
                            const currency = item.currency;

                            const options = item.extras.map(e => {
                                return (
                                    <HorizontalGridLayout key={e.id} spacing={1}>
                                        <Typography color={"textSecondary"} variant={"caption"}>
                                            {`${e.name}:`}
                                        </Typography>
                                        {
                                            e.options.map((o, index) => {
                                                totalUnitPrice += o.price;

                                                return (
                                                    <HorizontalGridLayout key={o.id} spacing={1}>
                                                        {index > 0 && <Divider orientation={"vertical"}/>}

                                                        {/* TODO: Options such as Inputs might be displayed differently.*/}
                                                        <Typography color={"textSecondary"} variant={"caption"}>
                                                            <strong>{o.name}</strong>
                                                            {
                                                                Boolean(o.price) &&
                                                                <strong>{` (${o.price.padZero()} ${currency})`}</strong>
                                                            }
                                                        </Typography>
                                                    </HorizontalGridLayout>
                                                );
                                            })
                                        }
                                    </HorizontalGridLayout>
                                );
                            });

                            const totalPrice = totalUnitPrice * item.quantity;

                            return (
                                <TableRow key={item.id}>

                                    <TableCell>
                                        <HorizontalGridLayout spacing={2}>

                                            <div style={{width: 60, height: 60}}>
                                                <RatioContainer ratio={1 / 1}>
                                                    <img
                                                        width={"100%"}
                                                        height={"100%"}
                                                        src={item.imageUrls[0]}
                                                        alt={item.name}
                                                        style={{objectFit: "cover"}}/>
                                                </RatioContainer>
                                            </div>

                                            <Grid style={{flexGrow: 1}}>
                                                <VerticalGridLayout spacing={1}>
                                                    <Typography variant={"body2"}>{item.name}</Typography>

                                                    {
                                                        item.sku &&
                                                        <Typography style={{fontSize: 12}}>
                                                            {`SKU: ${item.sku}`}
                                                        </Typography>
                                                    }

                                                    {options}

                                                    {
                                                        item.comment &&
                                                        <Typography style={{fontSize: 12, color: "gray"}}>
                                                            {`Comment: ${item.comment}`}
                                                        </Typography>
                                                    }
                                                </VerticalGridLayout>
                                            </Grid>

                                        </HorizontalGridLayout>
                                    </TableCell>

                                    <TableCell align={"right"}>
                                        <Typography variant={"body2"}>
                                            {totalUnitPrice.padZero()} {currency}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align={"right"}>
                                        <Typography variant={"body2"}>
                                            x{item.quantity}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align={"right"}>
                                        <Typography variant={"body2"}>
                                            <strong>{totalPrice.padZero()} {currency}</strong>
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OrderItemsList;