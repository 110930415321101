import { AxiosInstance } from "axios";
import config from "util/config";
import ResultStatus from "./enums/ResultStatus";
import ApiClientHandlersManager from "./ApiClientHandlersManager";

class ApiClient {

    private static _instance: ApiClient;

    private _axiosInstance: AxiosInstance;
    private _handlers: ApiClientHandlersManager;

    private constructor() {

        this._handlers = new ApiClientHandlersManager();

        const axios = require('axios');

        this._axiosInstance = axios.create({
            baseURL: config.api.url,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': config.api.apiKey
            }
        });

        this._axiosInstance.interceptors.request.use(async request => {
            for (const handler of this._handlers.requestHandlers) {
                const result = await handler.callback(request);

                if (result.status === ResultStatus.ABORT) {
                    console.log(`Abort Request: ${result.message}`);
                    return Promise.reject(result.message);
                }

                if (result.data) {
                    request = result.data;
                }
            }

            return request;
        });

        this._axiosInstance.interceptors.response.use(async response => {
            for (const handler of this._handlers.responseHandlers) {
                const result = await handler.callback(response);

                if (result.status === ResultStatus.ABORT) {
                    console.log(`Abort response: ${result.message}`);
                    return Promise.reject(result.message);
                } else if (result.status === ResultStatus.RESEND) {
                    console.log("Re-send Original Request");
                    response = await this._axiosInstance.request(response.config);
                }

                if (result.data) {
                    response = result.data;
                }
            }

            console.log(`${response.config.method?.toUpperCase()}: ${response.config.url}`);
            console.log(response);
            return response;
        });
    }

    public static get instance() {
        return ApiClient._instance || (ApiClient._instance = new this());
    }

    get axiosInstance() {
        return this._axiosInstance;
    }

    get handlers(): Omit<ApiClientHandlersManager, "requestHandlers" | "responseHandlers"> {
        return this._handlers;
    }

}


const axiosInstance = ApiClient.instance.axiosInstance;
const handlers = ApiClient.instance.handlers;
Object.freeze(axiosInstance);
Object.freeze(handlers);
export default axiosInstance;
export { handlers as ApiClientHandlers };

