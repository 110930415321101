import ProductPicker from 'components/product/ProductPicker';
import Modal from 'components/controls/Modal';
import Product, {productFields} from 'models/Product';
import React, {FC, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import {Button, Grid} from "@material-ui/core";
import {createProductPath, productPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";
import CustomFieldsListControl from "admin/components/CustomFieldsListControl";

interface Props {
    title: string,
    products: Product[],
    shopId: string;
    categoryId?: string
    canManage: boolean;
    onAddAsync: (product: Product) => Promise<boolean>;
    onRemoveAsync: (product: Product) => Promise<boolean>;
}

// TODO: Wrap long texts.
const ProductList: FC<Props> = (props: Props) => {
    const history = useHistory();

    const [isPickerModalShown, setIsPickerModalShown] = useState(false);

    function onItemClicked(product: Product) {
        history.push(productPath(props.shopId, product.id));
    }

    function onCreateButtonClicked() {
        history.push(createProductPath(props.shopId, props.categoryId));
    }

    function onManageProductsButtonClicked() {
        toggleManageModal()
    }

    function toggleManageModal() {
        setIsPickerModalShown(ps => !ps);
    }

    return (<>
        <VerticalGridLayout spacing={2}>
            <HorizontalGridLayout>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant={"h6"}>
                        <strong>{props.title}</strong>
                    </Typography>
                </Grid>

                <HorizontalGridLayout spacing={1}>
                    {
                        props.canManage &&
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<EditIcon/>}
                            onClick={onManageProductsButtonClicked}>Manage</Button>
                    }

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon/>}
                        onClick={onCreateButtonClicked}>New Account</Button>
                </HorizontalGridLayout>
            </HorizontalGridLayout>

            <CustomFieldsListControl
                data={props.products}
                fields={productFields}
                onItemClicked={p => onItemClicked(p as Product)}/>
        </VerticalGridLayout>

        <Modal
            title={`Manage Accounts`}
            open={isPickerModalShown}
            toggleModal={toggleManageModal}>
            <ProductPicker
                currentProducts={props.products}
                categoryId={props.categoryId ?? ""}
                shopId={props.shopId}
                onAddAsync={props.onAddAsync}
                onRemoveAsync={props.onRemoveAsync}/>
        </Modal>
    </>);
}

export default ProductList;