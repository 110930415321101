import UserIdentity from "./UserIdentity";

class RefreshAuthRequest extends UserIdentity {

    refreshToken: string;
    deviceKey: string;

    constructor(username: string, refreshToken: string, deviceKey: string) {
        super(username);
        this.refreshToken = refreshToken;
        this.deviceKey = deviceKey;
    }

}

export default RefreshAuthRequest;