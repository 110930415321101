import React, {FC, useEffect, useState} from 'react';
import Api from 'util/api';
import MessagingManager from 'util/MessagingManager';
import Product, {productFields} from 'models/Product';
import {Box, Container} from "@material-ui/core";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";
import DataNotFound from "components/controls/common/DataNotFound";
import {useHistory} from "react-router-dom";
import {productPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";
import Panel from "components/controls/common/Panel";
import UpsertCustomFieldsControl from "admin/components/UpsertCustomFieldsControl";

interface Props {
    productId: string;
    shopId: string;
}

interface State {
    product?: Product;
    isLoading: boolean;
}

const EditPage: FC<Props> = (props) => {
    const history = useHistory();

    const [state, setState] = useState<State>({
        isLoading: true
    });

    useEffect(() => {
        void async function fetchData() {
            if (!state.product || state.product.id !== props.productId) {
                await Api.product.admin.getProductAsync(props.shopId, props.productId)
                    .then((data) => {
                        const d = data as Product[];
                        if (d.length) {
                            setState(s => ({...s, product: d[0]}));
                        }
                    });

                setState(s => ({...s, isLoading: false}));
            }
        }();
    }, [props.shopId, props.productId, state.product]);

    async function onFormSubmitted(product: Partial<Product>) {
        return await Api.product.admin.updateProductAsync(props.shopId, product)
            .then(() => {
                MessagingManager.toast({
                    message: `Updated successfully!`,
                    type: "success"
                });

                history.push(productPath(props.shopId, props.productId));
                return true;
            })
            .catch(() => false);
    }

    const product = state.product;

    if (state.isLoading) {
        return (<WaveLoadingPage/>);
    } else if (!product) {
        return (<DataNotFound/>);
    } else {
        return (<>
            <HtmlMetaDecorator title={"Edit Account"}/>

            <Container>
                <Panel>
                    <Box p={2}>
                        <VerticalGridLayout spacing={4}>
                            <Typography variant={"h6"}>
                                <strong>Edit Account</strong>
                            </Typography>

                            {/* NOTE: It's important that the UpsertControl gets render after the product is actually loaded,
                                 because if the FileUploadControl was initialized with an empty initialState, then the date that
                                 get loaded later will not be set as initialState. */}
                            <UpsertCustomFieldsControl
                                model={product}
                                fields={productFields}
                                actionText="Save"
                                onSubmit={async p => await onFormSubmitted(p)}/>
                        </VerticalGridLayout>
                    </Box>
                </Panel>
            </Container>
        </>);
    }
}

export default EditPage;