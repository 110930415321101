interface Terminologies {
    appCategory: string;
    appCategoryId: string;
    category: string;
    categoryId: string;
    shop: string;
    shopId: string;
    product: string;
    productId: string;
    productExtra: string;
    advertisement: string;
    adId: string;
    user: string;
    userId: string;
    order: string;
    orderId: string;
    transId: string;
    cart: string;
    checkout: string;
    account: string;
    login: string;
    register: string;
    forgotPassword: string;
    aboutShop: string;
    subscriptions: string;
    profile: string;
    search: string;
    link: string;
    linkId: string;
    code: string;
    searchTerm: string;
    lookup: string;
}

// NOTE: (IMPORTANT) These terminologies MUST be unique;
//  since they're used in the URL paths as well.
const terminologies: Terminologies = {
    appCategory: "app-category",
    appCategoryId: "categoryId",
    category: "contacts",
    categoryId: "contactId",
    shop: "shop",
    shopId: "shopId",
    product: "accounts",
    productId: "accountId",
    productExtra: "deal",
    advertisement: "advertisement",
    adId: "adId",
    user: "users",
    userId: "userId",
    order: "order",
    orderId: "orderId",
    transId: "transId",
    cart: "cart",
    checkout: "checkout",
    account: "auth",
    login: "login",
    register: "register",
    forgotPassword: "forgot-password",
    aboutShop: "about",
    subscriptions: "favourites",
    profile: "profile",
    search: "search",
    link: "link",
    linkId: "linkId",
    code: "code",
    searchTerm: "term",
    lookup: "lookup",
};

export default terminologies;