import appSettings from "appSettings";

declare global {
    interface Number {
        padZero(): string;
    }

    interface String {
        toFriendlyDateTime(): string;

        toFlexAlign(): "flex-end" | "flex-start" | "center";

        toTextAlign(): "right" | "left" | "center" | "justify";

        toDate(): Date;
    }
}

// eslint-disable-next-line no-extend-native
Number.prototype.padZero = function (this: number) {
    return this.toFixed(appSettings.priceDecimals);
};

// eslint-disable-next-line no-extend-native
String.prototype.toFriendlyDateTime = function (this: string) {
    if (this) {
        const date = new Date(this);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
    return "";
};

// TODO: Return the alignment based of layout direction.
// eslint-disable-next-line no-extend-native
String.prototype.toFlexAlign = function (this: string) {
    return this === "end" ? "flex-end" :
        this === "start" ? "flex-start" : "center";
};

// TODO: Return the alignment based of layout direction.
// eslint-disable-next-line no-extend-native
String.prototype.toTextAlign = function (this: string) {
    return this === "end" ? "right" :
        this === "start" ? "left" : this;
};

// eslint-disable-next-line no-extend-native
String.prototype.toDate = function (this: string) {
    return new Date(this);
};

export {}