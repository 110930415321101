import messageStore from "../redux/messageStore";
import {enqueueSnackbar} from "../redux/actions";

interface Params {
    message: string;
    errors: Record<string, string>;
    type: 'default' | 'error' | 'success' | 'warning' | 'info';
}

class MessagingManager {

    toast(params: Partial<Params>) {
        if (params.message) {
            messageStore.dispatch(enqueueSnackbar({
                message: params.message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: params.type ?? 'default'
                }
            }));
        } else if (params.errors) {
            let message = "";
            Object.entries(params.errors).forEach(([key, value]) => {
                if (message) {
                    message += "\n";
                }
                message += `${key}: ${value}`;
            });

            messageStore.dispatch(enqueueSnackbar({
                message: message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: params.type ?? 'error'
                }
            }));
        }
    }

}

const instance = new MessagingManager();
Object.freeze(instance);
export default instance;