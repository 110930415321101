export default class JwtObject {

    constructor(jwt) {
        if (jwt) {
            const tokens = jwt.split('.');

            this.header = this.decode(tokens[0]);
            this.payload = this.decode(tokens[1]);
            this.signature = tokens[2];
        }
    }

    decode(token) {
        const decoded = atob(token);
        return JSON.parse(decoded);
    }

}