import BaseApi from "./BaseApi";
import ApiResponseOptions from "./models/ApiResponseOptions";
import ApiResponse from "./models/ApiResponse";
import Address from "../../models/Address";
import User from "models/User";

class UserApi extends BaseApi {

    private _admin?: AdminUserApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminUserApi();
        }
        return this._admin;
    }

    async getProfileAsync(options?: ApiResponseOptions):
        Promise<User | ApiResponse<User> | undefined> {
        return this.get("profile", options);
    }

    async updateProfileAsync(user: User, options?: ApiResponseOptions):
        Promise<User | ApiResponse<User> | undefined> {
        return this.put("profile", user, options);
    }

    async getAddresses(options?: ApiResponseOptions):
        Promise<Address[] | ApiResponse<Address[]> | undefined> {
        const path = `address`;
        return this.get(path, options);
    }

    async createAddress(address: Partial<Address>, options?: ApiResponseOptions):
        Promise<Address | ApiResponse<Address> | undefined> {
        const path = `address`;
        return this.post(path, address, options);
    }

    async updateAddress(address: Address, options?: ApiResponseOptions):
        Promise<Address | ApiResponse<Address> | undefined> {
        const path = `address/${address.id}`;
        return this.put(path, address, options);
    }

}

class AdminUserApi extends BaseApi {

    async getShopAdminUsersAsync(shopId: string, options?: ApiResponseOptions):
        Promise<User[] | ApiResponse<User[]> | undefined> {
        const path = `user/shop/${shopId}`;
        return this.get(path, options);
    }

    async createShopAdminUsersAsync(user: Partial<User>, options?: ApiResponseOptions):
        Promise<any | ApiResponse<any> | undefined> {
        return this.post("user/shop", user, options);
    }

}

export default UserApi;