import ProductList from 'admin/components/product/ProductList';
import Product from 'models/Product';
import React, {FC, useEffect, useState} from 'react';
import Api from 'util/api';
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";
import {Container} from "@material-ui/core";

interface Props {
    shopId: string;
}

const IndexPage: FC<Props> = (props) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        void async function fetchData() {
            await Api.product.admin.getProductsAsync(props.shopId)
                .then((data) => {
                    setProducts(data as Product[]);
                });
            setIsLoading(false);
        }();
    }, [props.shopId]);

    if (isLoading) {
        return (<WaveLoadingPage/>);
    } else {
        return (<>
            <HtmlMetaDecorator title={"Accounts"}/>

            <Container maxWidth={"xl"}>
                <ProductList
                    title={"Accounts"}
                    shopId={props.shopId}
                    canManage={false}
                    products={products}
                    onAddAsync={async () => {
                        return false;
                    }}
                    onRemoveAsync={async () => {
                        return false;
                    }}/>
            </Container>
        </>);
    }
}

export default IndexPage;