import React, {FC, useEffect, useState} from 'react';
import {GridItem, HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Box, Button, Grid, Link, Paper} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ProductExtras from "models/ProductExtras";
import Api from "util/api";
import MessagingManager from "util/MessagingManager";
import Modal from "components/controls/Modal";
import {Typography} from "components/controls/helpers/Typography";
import WaveLoading from "components/controls/common/WaveLoading";
import CustomFieldsListControl from "admin/components/CustomFieldsListControl";
import {optionGroupFields} from "models/ProductOptionGroup";
import UpsertCustomFieldsControl from "admin/components/UpsertCustomFieldsControl";
import {useAppCache} from "contexts/AppCacheProvider";
import Media from "models/Media";
import EditIcon from "@material-ui/icons/Edit";

interface Props {
    productId?: string;
    shopId: string;
}

const OptionGroupsList: FC<Props> = (props: Props) => {
    const cache = useAppCache();

    const [groups, setGroups] = useState<ProductExtras[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<ProductExtras>();

    const [isLoading, setIsLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const toggleCreateModal = () => setShowCreateModal(prevState => !prevState);
    const toggleUpdateModal = () => setShowUpdateModal(prevState => !prevState);
    const toggleDetailsModal = () => setShowDetailsModal(prevState => !prevState);

    useEffect(() => {
        void async function fetchData() {
            if (props.productId) {
                await Api.product.getExtrasAsync(props.shopId, props.productId)
                    .then((data) => {
                        const extras = data as ProductExtras[];
                        // NOTE: This will exclude the Attribute Groups.
                        setGroups(extras.filter(o => o.minimum >= 0));
                    });
            } else {
                await Api.product.admin.getExtrasAsync(props.shopId)
                    .then((data) => {
                        const extras = Object.keys(data).map(k => data[k] as ProductExtras[]).flat()
                        // NOTE: This will exclude the Attribute Groups.
                        setGroups(extras.filter(o => o.minimum >= 0));
                    });
            }

            setIsLoading(false);
        }();
    }, [props.productId, props.shopId]);

    const createGroup = async (group: ProductExtras) => {
        group.name = "Empty";
        return await Api.product.admin.createOptionGroupAsync(props.shopId, props.productId!, group)
            .then((data) => {
                MessagingManager.toast({
                    message: 'Created successfully!',
                    type: "success"
                });

                toggleCreateModal();
                setGroups(prevState => [...prevState, data as ProductExtras]);
                return true;
            })
            .catch(() => false);
    }

    const updateGroup = async (group: ProductExtras) => {
        return await Api.product.admin.updateOptionGroupAsync(props.shopId, group.productId, group.id, group)
            .then((data) => {
                MessagingManager.toast({
                    message: 'Updated successfully!',
                    type: "success"
                });

                toggleUpdateModal();
                setSelectedGroup(undefined);
                setGroups(prevState => prevState.map(g => g.id === group.id ? data as ProductExtras : g))
                return true;
            })
            .catch(() => false);
    }

    function onItemClicked(group: ProductExtras) {
        setSelectedGroup(group);
        toggleDetailsModal();
    }

    const renderDetails = () => {
        const attributes = selectedGroup?.customAttributes || {};
        const attachmentFields = optionGroupFields.filter(f => f.type === "attachments");

        return (
            <VerticalGridLayout spacing={4}>
                <HorizontalGridLayout spacing={3}>
                    {optionGroupFields.filter(f => f.type !== "attachments").map(f => {
                        let value = attributes[f.name] || "-";
                        if (f.type === "lookup" && f.values) {
                            const lookup = cache.lookups[f.values];
                            value = lookup?.find(l => l.id === attributes[f.name])?.name;
                        }

                        return (
                            <GridItem
                                key={f.name} xs={12}
                                sm={f.settings?.multiline ? 12 : 6}
                                md={f.settings?.multiline ? 12 : 4}>
                                <VerticalGridLayout spacing={1}>
                                    <Typography color={"textSecondary"} variant={"body2"}>
                                        {f.label}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {value}
                                    </Typography>
                                </VerticalGridLayout>
                            </GridItem>
                        )
                    })}
                </HorizontalGridLayout>

                {
                    Boolean(attachmentFields?.length) &&
                    <VerticalGridLayout spacing={4}>
                        {attachmentFields.map(f => {
                            const media = attributes[f.name] as Media[];

                            return (<>
                                <Typography variant={"body1"}>
                                    <strong>{f.label}</strong>
                                </Typography>
                                <Box pt={2}>
                                    {media?.length ?
                                        <HorizontalGridLayout spacing={2} alignItems={"center"}>
                                            {media.map((m, i) =>
                                                <GridItem key={i} xs={12} sm={6} md={4}>
                                                    <Link href={m.url} target={"_blank"}>
                                                        <Paper variant={"outlined"}>
                                                            <Box p={2}>
                                                                <Typography variant={"body2"}>
                                                                    {m.name}
                                                                </Typography>
                                                            </Box>
                                                        </Paper>
                                                    </Link>
                                                </GridItem>
                                            )}
                                        </HorizontalGridLayout>
                                        :
                                        "No items yet!"
                                    }
                                </Box>
                            </>);
                        })}
                    </VerticalGridLayout>
                }

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon/>}
                    onClick={() => {
                        toggleDetailsModal();
                        toggleUpdateModal();
                    }}>Edit</Button>
            </VerticalGridLayout>
        )
    }

    if (isLoading) {
        return (<WaveLoading/>);
    } else {
        return (<>
            <VerticalGridLayout spacing={2}>
                <HorizontalGridLayout>
                    {
                        !Boolean(props.productId) &&
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant={"h6"}>
                                <strong>Deals</strong>
                            </Typography>
                        </Grid>
                    }

                    {
                        Boolean(props.productId) &&
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon/>}
                            onClick={toggleCreateModal}>
                            New Deal
                        </Button>
                    }
                </HorizontalGridLayout>

                <CustomFieldsListControl
                    data={groups}
                    fields={optionGroupFields}
                    onItemClicked={g => onItemClicked(g as ProductExtras)}/>
            </VerticalGridLayout>

            <Modal
                title={`New Deal`}
                disableBackdropClick
                maxWidth={"lg"}
                open={showCreateModal}
                toggleModal={toggleCreateModal}>
                <UpsertCustomFieldsControl
                    fields={optionGroupFields}
                    actionText="Save"
                    onSubmit={async (g) => await createGroup(g as ProductExtras)}/>
            </Modal>

            <Modal
                title={`Edit Deal`}
                disableBackdropClick
                maxWidth={"lg"}
                open={showUpdateModal}
                toggleModal={toggleUpdateModal}>
                <UpsertCustomFieldsControl
                    model={selectedGroup}
                    fields={optionGroupFields}
                    actionText="Save"
                    onSubmit={async (g) => await updateGroup(g as ProductExtras)}/>
            </Modal>

            <Modal
                title={`Deal Details`}
                maxWidth={"lg"}
                disableBackdropClick
                open={showDetailsModal}
                toggleModal={toggleDetailsModal}>
                <Box py={2}>
                    {renderDetails}
                </Box>
            </Modal>
        </>);
    }
}

export default OptionGroupsList;