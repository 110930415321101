import React, {FC} from 'react'
import Api from 'util/api';
import AuthManager from 'util/AuthManager';
import {Field, Form, Formik} from "formik";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Button} from "@material-ui/core";
import * as Yup from "yup";
import MessagingManager from "util/MessagingManager";
import PasswordInput from "components/controls/common/PasswordInput";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";
import Messages from "util/locale/Messages";

const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
};

const ResetPasswordControl: FC = () => {
    const {t} = useTranslation();

    const validationSchema = Yup.object({
        newPassword: Yup.string().required(t(Messages.passwordRequired)),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword')], t(Messages.passwordsMustMatch))
    });

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, {resetForm}) => {
                await Api.auth.resetPasswordAsync(AuthManager.user.username, values.oldPassword, values.newPassword)
                    .then(() => {
                        MessagingManager.toast({
                            message: t(Messages.passwordResetSuccessfully),
                            type: "success"
                        });

                        resetForm({});
                    });
            }}>

            <Form>
                <VerticalGridLayout
                    wrap={"nowrap"}
                    spacing={2}>

                    <Field
                        name="oldPassword"
                        label={t(Strings.currentPassword)}
                        component={PasswordInput}/>

                    <Field
                        name="newPassword"
                        label={t(Strings.newPassword)}
                        component={PasswordInput}/>

                    <Field
                        name="confirmPassword"
                        label={t(Strings.confirmPassword)}
                        component={PasswordInput}/>

                    <Button
                        type="submit"
                        size={"large"}
                        variant="contained"
                        color="primary">
                        {t(Strings.reset)}
                    </Button>

                </VerticalGridLayout>
            </Form>

        </Formik>

    );
}

export default ResetPasswordControl;