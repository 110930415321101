import Config from "models/Config";

const config: Config = {
    api: {
        url: 'https://api.lp.express/v1/',
        apiKey: 'snipe-838q7PqvNI9mLM249g5hFaElJAfteopMaeRNWP66'
    },
    identity: {
        clientId: "2grrscbb275a8au9dq1fuktivr",
        //TODO: Move the Client Secret to the server
        clientSecret: "162vl14j10jtne6pei92onmr7im3jlmbs5splrf1u1r7hr0idgsl"
    }
};

export default config;