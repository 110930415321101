import ProductList from 'admin/components/product/ProductList';
import Modal from 'components/controls/Modal';
import Category, {categoryFields} from 'models/Category';
import React, {FC, useEffect, useState} from 'react';
import Api from 'util/api';
import update from 'immutability-helper';
import Product from 'models/Product';
import {GridItem, HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Box, Button, Container, Grid, Link, Paper} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Panel from "components/controls/common/Panel";
import CancelIcon from "@material-ui/icons/Cancel";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {Typography} from "components/controls/helpers/Typography";
import MessagingManager from "util/MessagingManager";
import {useHistory} from "react-router-dom";
import {
    categoryIndexPath,
    editCategoryPath
} from "admin/routers/PathBuilder";
import TabsControl, {TabPanel} from "components/controls/common/TabsControl";
import {useAppCache} from "contexts/AppCacheProvider";
import Media from "models/Media";

interface Props {
    categoryId: string;
    shopId: string;
}

interface State {
    category?: Category,
    isDeleteModalShown: boolean
}

// TODO: Store/load the data from cache.
const DetailsPage: FC<Props> = (props) => {
    const cache = useAppCache();
    const history = useHistory();

    const [state, setState] = useState<State>({
        isDeleteModalShown: false
    });

    useEffect(() => {
        void async function fetchData() {
            if (!state.category || state.category.id !== props.categoryId) {
                const data = await Api.category.admin.getShopCategoryAsync(props.shopId, props.categoryId) as Category[];

                if (data && data.length) {
                    setState(s => ({...s, category: data[0]}));
                }
            }
        }();
    }, [props.shopId, props.categoryId, state.category]);

    function toggleDeleteModal() {
        setState(s => ({...s, isDeleteModalShown: !s.isDeleteModalShown}));
    }

    async function deletedCategory() {
        await Api.category.admin.deleteCategoryAsync(state.category!.id, props.shopId)
            .then(() => {
                MessagingManager.toast({
                    message: `Deleted Successfully!`,
                    type: "success"
                })
                history.push(categoryIndexPath(props.shopId));
            });
    }

    function onEditButtonClicked() {
        history.push(editCategoryPath(props.categoryId, props.shopId));
    }

    async function onAddProductAsync(product: Product): Promise<boolean> {
        product.categoryId = state.category?.id ?? "";
        return await Api.product.admin.updateProductAsync(props.shopId!, product)
            .then(() => {
                setState(s => ({
                    ...s,
                    category: update(state.category, {
                        products: {$push: [product]}
                    })
                }));
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    async function onRemoveProductAsync(product: Product): Promise<boolean> {
        product.categoryId = state.category?.id ?? "";
        return await Api.product.admin.removeProductFromCategoryAsync(props.shopId!, product.id, product.categoryId)
            .then(() => {
                setState(s => ({
                    ...s,
                    category: update(state.category, {
                        products: products => products.filter(c => c.id !== product.id)
                    })
                }));
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    const category = state.category;
    const attributes = category?.customAttributes || {};
    const attachmentFields = categoryFields.filter(f => f.type === "attachments");

    return (<>
        <HtmlMetaDecorator title={"Contact Details"}/>

        <Container>
            <VerticalGridLayout spacing={4}>
                <HorizontalGridLayout>
                    <Grid item style={{flexGrow: 1}}>
                        <Typography variant={"h6"}>
                            <strong>Contact Details</strong>
                        </Typography>
                    </Grid>

                    <HorizontalGridLayout spacing={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon/>}
                            onClick={onEditButtonClicked}>Edit</Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon/>}
                            onClick={toggleDeleteModal}>Delete</Button>
                    </HorizontalGridLayout>
                </HorizontalGridLayout>

                <Panel>
                    <TabsControl>
                        <TabPanel title={"Profile"}>
                            <Box pt={3}>
                                <HorizontalGridLayout spacing={3}>
                                    {categoryFields.filter(f => f.type !== "attachments").map(f => {
                                        let value = attributes[f.name] || "-";
                                        if (f.type === "lookup" && f.values) {
                                            const lookup = cache.lookups[f.values];
                                            value = lookup?.find(l => l.id === attributes[f.name])?.name;
                                        }

                                        return (
                                            <GridItem
                                                key={f.name} xs={12}
                                                sm={f.settings?.multiline ? 12 : 6}
                                                md={f.settings?.multiline ? 12 : 4}>
                                                <VerticalGridLayout spacing={1}>
                                                    <Typography color={"textSecondary"} variant={"body2"}>
                                                        {f.label}
                                                    </Typography>
                                                    <Typography variant={"body1"}>
                                                        {value}
                                                    </Typography>
                                                </VerticalGridLayout>
                                            </GridItem>
                                        )
                                    })}
                                </HorizontalGridLayout>
                            </Box>
                        </TabPanel>

                        {
                            Boolean(attachmentFields?.length) &&
                            <TabPanel title={"Attachments"}>
                                <Box pt={3}>
                                    <VerticalGridLayout spacing={4}>
                                        {attachmentFields.map(f => {
                                            const media = attributes[f.name] as Media[];

                                            return (<>
                                                <Typography variant={"body1"}>
                                                    <strong>{f.label}</strong>
                                                </Typography>
                                                <Box pt={2}>
                                                    {media?.length ?
                                                        <HorizontalGridLayout spacing={2} alignItems={"center"}>
                                                            {media.map((m, i) =>
                                                                <GridItem key={i} xs={12} sm={6} md={4}>
                                                                    <Link href={m.url} target={"_blank"}>
                                                                        <Paper variant={"outlined"}>
                                                                            <Box p={2}>
                                                                                <Typography variant={"body2"}>
                                                                                    {m.name}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Paper>
                                                                    </Link>
                                                                </GridItem>
                                                            )}
                                                        </HorizontalGridLayout>
                                                        :
                                                        "No items yet!"
                                                    }
                                                </Box>
                                            </>);
                                        })}
                                    </VerticalGridLayout>
                                </Box>
                            </TabPanel>
                        }

                        <TabPanel title={"Accounts"}>
                            <Box pt={3}>
                                <ProductList
                                    title={""}
                                    canManage={true}
                                    products={category?.products ?? []}
                                    shopId={props.shopId}
                                    categoryId={category?.id}
                                    onAddAsync={onAddProductAsync}
                                    onRemoveAsync={onRemoveProductAsync}/>
                            </Box>
                        </TabPanel>
                    </TabsControl>
                </Panel>
            </VerticalGridLayout>
        </Container>

        <Modal
            title={`Are you sure you want to delete this contact?`}
            open={state.isDeleteModalShown}
            toggleModal={toggleDeleteModal}>

            <HorizontalGridLayout justify="flex-end" spacing={1}>
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<CancelIcon/>}
                    onClick={toggleDeleteModal}>Cancel</Button>

                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon/>}
                    onClick={deletedCategory}>Delete</Button>
            </HorizontalGridLayout>
        </Modal>
    </>);
}

export default DetailsPage;