import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";

interface Props {
    ratio?: number;
    width?: string | number;
    maxWidth?: string | number;
    height?: string | number;
    maxHeight?: string | number;
}

const useStyles = makeStyles<Theme, Props>(() => ({
    root: props => ({
        margin: "auto",
        width: props.width,
        height: props.height,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight
    }),
    container: props => ({
        position: "relative",
        width: "100%",
        paddingTop: `${100 / (props.ratio ?? 1)}%`
    }),
    content: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    }
}));

const RatioContainer: React.FC<Props> = (props) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            {props.ratio ?
                <div className={classes.container}>
                    <div className={classes.content}>
                        {props.children}
                    </div>
                </div>
                :
                <>{props.children}</>
            }
        </div>
    );
}

export default RatioContainer;