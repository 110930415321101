import Api from "./api";
import UploadInstructions from "./api/models/storage/UploadInstructions";
import UploadParameters, {UploadPurpose} from "./api/models/storage/UploadParameters";
import config from "./config";
import AuthManager from "util/AuthManager";

// TODO: Support multiple images. Return list of success and failure for each file.
class UploadHelper {
    async upload(purpose: UploadPurpose, file: File, shopId?: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            const params: UploadParameters = {
                purpose: purpose,
                shopId: shopId,
                userId: AuthManager.user.cognitoUsername,
                contentType: file.type,
                contentLength: file.size
            };

            await Api.getUploadInstructions(params)
                .then(async (data) => {
                    const instructions = data as UploadInstructions;
                    instructions.headers["Content-Type"] = file.type;
                    instructions.headers["x-api-key"] = config.api.apiKey;
                    instructions.headers["x-lit-access-token"] = config.api.apiKey;

                    await Api.uploadToUrl(instructions, file)
                        .then(() => resolve(instructions.downloadUrl))
                        .catch(() => reject("Cannot Upload!"));
                })
                .catch(errors => reject(errors));
        });
    }
}

export default UploadHelper;