import UserIdentity from "./UserIdentity";

class ChallengeNewPasswordRequest extends UserIdentity {

    code: string;
    session: string;
    challenge: string = "NEW_PASSWORD_REQUIRED";
    codeType: string = "NEW_PASSWORD";

    constructor(username: string, password: string) {
        super(username);
        this.code = password;
        this.session = localStorage.getItem("Session") ?? "";
    }

}

export default ChallengeNewPasswordRequest;
