import React, {useState} from 'react'
import Api from 'util/api';
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import appSettings from "appSettings";
import {Typography} from "components/controls/helpers/Typography";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import {Button} from "@material-ui/core";
import * as Yup from 'yup';
import AuthenticationResponse from "util/api/models/communication/AuthenticationResponse";
import PasswordInput from "components/controls/common/PasswordInput";
import AuthManager from "util/AuthManager";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";
import Messages from "util/locale/Messages";
import WaveLoading from "components/controls/common/WaveLoading";

interface Props {
    username: string;
    onSuccess: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        textAlign: "center",
        padding: theme.spacing(2, 3)
    },
    logo: {
        width: 200,
    }
}));

interface FormFields {
    password: string;
    confirmPassword: string;
}

const NewPasswordControl: React.FC<Props> = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object({
        password: Yup.string().required(t(Messages.passwordRequired)),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], t(Messages.passwordsMustMatch))
    });

    const initialFormFields: FormFields = {
        password: "",
        confirmPassword: ""
    };

    async function resetPasswordAsync(password: string) {
        setIsLoading(true);
        const result = (await Api.auth.challengeNewPassword(props.username, password)) as AuthenticationResponse;
        if (result && result.status === 0) {
            await AuthManager.authenticateAsync(props.username, password);
            props.onSuccess();
        }
        setIsLoading(false);
    }

    return (
        <VerticalGridLayout className={classes.main} spacing={3}>
            <img
                src={"/navbar-logo.png"}
                alt={`${appSettings.appName} logo`}
                className={classes.logo}/>

            <Typography variant="h5">{t(Strings.newPassword)}</Typography>
            <Typography variant="body1">{t(Messages.resetYourTempPassword)}</Typography>

            <Formik
                enableReinitialize={true}
                initialValues={initialFormFields}
                validationSchema={validationSchema}
                onSubmit={async fields => {
                    await resetPasswordAsync(fields.password);
                }}>

                <Form>
                    <VerticalGridLayout spacing={3}>
                        <Field
                            name="password"
                            label={t(Strings.password)}
                            component={PasswordInput}/>

                        <Field
                            name="confirmPassword"
                            label={t(Strings.confirmPassword)}
                            component={PasswordInput}/>

                        {isLoading ?
                            <WaveLoading/>
                            :
                            <Button
                                type="submit"
                                fullWidth
                                size={"large"}
                                variant="contained"
                                color="primary">
                                {t(Strings.reset)}
                            </Button>
                        }
                    </VerticalGridLayout>
                </Form>
            </Formik>
        </VerticalGridLayout>
    );
}

export default NewPasswordControl;