import CategoryList from 'admin/components/category/CategoryList';
import Category from 'models/Category';
import React, {FC, useEffect, useState} from 'react';
import Api from 'util/api';
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {Container} from "@material-ui/core";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";

type Props = {
    shopId: string;
}

const IndexPage: FC<Props> = (props) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        void async function fetchData() {
            await Api.category.admin.getShopCategoriesAsync(props.shopId)
                .then((data) => {
                    setCategories(data as Category[]);
                });
            setIsLoading(false);
        }();
    }, [props.shopId]);

    if (isLoading) {
        return (<WaveLoadingPage/>);
    } else {
        return (<>
            <HtmlMetaDecorator title={"Contacts"}/>

            <Container maxWidth={"xl"}>
                <CategoryList
                    title={"Contacts"}
                    shopId={props.shopId}
                    categories={categories}
                    onAddAsync={async () => {
                        return false;
                    }}
                    onRemoveAsync={async () => {
                        return false;
                    }}/>
            </Container>
        </>);
    }
}

export default IndexPage;