import React, {FC} from 'react';
import Api from 'util/api';
import MessagingManager from 'util/MessagingManager';
import Product, {productFields} from 'models/Product';
import {Box, Container} from "@material-ui/core";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {useHistory} from "react-router-dom";
import {productPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";
import Panel from "components/controls/common/Panel";
import UpsertCustomFieldsControl from "admin/components/UpsertCustomFieldsControl";

interface Props {
    shopId: string;
    categoryId?: string;
}

const CreatePage: FC<Props> = (props) => {
    const history = useHistory();

    async function createProduct(product: Partial<Product>) {
        return await Api.product.admin.createProductAsync(props.shopId, product)
            .then(async (data) => {
                const d = data as Product;

                if (props.categoryId) {
                    d.categoryId = props.categoryId;
                    await Api.product.admin.updateProductAsync(props.shopId, d);
                }

                MessagingManager.toast({
                    message: `Created successfully!`,
                    type: "success"
                });

                history.push(productPath(props.shopId, d.id));
                return true;
            })
            .catch(() => false);
    }

    return (<>
        <HtmlMetaDecorator title={"Create Account"}/>

        <Container>
            <Panel>
                <Box p={2}>
                    <VerticalGridLayout spacing={4}>
                        <Typography variant={"h6"}>
                            <strong>Create Account</strong>
                        </Typography>

                        <UpsertCustomFieldsControl
                            fields={productFields}
                            actionText="Create"
                            onSubmit={async p => await createProduct(p)}/>
                    </VerticalGridLayout>
                </Box>
            </Panel>
        </Container>
    </>);
}

export default CreatePage;