import React, {FC} from 'react';
import {Link} from "react-router-dom";
import {Box, Button, Container} from "@material-ui/core";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Typography} from "components/controls/helpers/Typography";
import {useTranslation} from "react-i18next";
import Messages from "util/locale/Messages";
import Strings from "util/locale/Strings";
import {useAppSettings} from "contexts/AppSettingsProvider";

const NotFoundPage: FC = () => {
    const appSettings = useAppSettings();
    const {t} = useTranslation();

    return (
        <Container>
            <div style={{padding: "20vh 0"}}>
                <VerticalGridLayout alignItems={"center"}>
                    <Typography
                        variant={"h1"}
                        style={{
                            fontSize: "10rem",
                            fontWeight: 600,
                            opacity: 0.3,
                            marginBottom: -60,
                            color: appSettings.colors.secondary
                        }}>
                        404
                    </Typography>
                    <Typography
                        style={{fontWeight: 500, maxWidth: 400, textAlign: "center"}}>
                        {t(Messages.pageNotFound)}
                    </Typography>
                    <Box mt={4}>
                        <Link to={"/"}>
                            <Button
                                size={"large"}
                                variant={"contained"}
                                color={"primary"}
                            >{t(Strings.backToHome)}</Button>
                        </Link>
                    </Box>
                </VerticalGridLayout>
            </div>
        </Container>
    );
}

export default NotFoundPage;