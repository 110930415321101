import UserIdentity from "./UserIdentity";

class RegistrationRequest extends UserIdentity {

    name: string;
    password: string;
    email: string;
    phone: string;

    constructor(username: string, name: string, phone: string, password: string) {
        super(username);
        this.email = username;
        this.name = name;
        this.phone = phone;
        this.password = password;
    }

}

export default RegistrationRequest;
