import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Typography} from "components/controls/helpers/Typography";
import Strings from "util/locale/Strings";
import Messages from "util/locale/Messages";
import {Box, Container} from "@material-ui/core";

const DataNotFound: FC = () => {
    const {t} = useTranslation();

    return (<>
        <HtmlMetaDecorator title={t(Strings.notFound)}/>

        <VerticalGridLayout
            justify={"center"}
            alignContent={"center"}
            style={{height: "100%"}}>
            <Container>
                <Box py={8}>
                    <Typography variant={"body1"}>
                        {t(Messages.dataNotFound)}
                    </Typography>
                </Box>
            </Container>
        </VerticalGridLayout>
    </>);
}

export default DataNotFound;