import React, {FC, useState} from 'react'
import Api from 'util/api';
import {Field, Form, Formik} from "formik";
import {VerticalGridLayout} from "../controls/helpers/GridLayout";
import PasswordInput from "../controls/common/PasswordInput";
import {Button} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "components/controls/helpers/Typography";
import CustomInputComponent from "components/controls/helpers/CustomInputComponent";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";
import Messages from "util/locale/Messages";
import WaveLoading from "components/controls/common/WaveLoading";

interface Props {
    onSuccess: () => void;
}

enum Step {
    USERNAME,
    PASSWORD
}

interface UsernameFormFields {
    username: string;
}

interface ConfirmFormFields {
    code: string;
    password: string;
    confirmPassword: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        textAlign: "center",
        padding: theme.spacing(2, 3)
    }
}));

const ForgotPasswordControl: FC<Props> = (props: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [username, setUsername] = useState<string>("");
    const [step, setStep] = useState<Step>(Step.USERNAME);
    const [isLoading, setIsLoading] = useState(false);

    const usernameValidationSchema = Yup.object({
        username: Yup.string().required(t(Messages.emailRequired))
    });

    const confirmValidationSchema = Yup.object({
        code: Yup.string().required(t(Messages.codeRequired)),
        password: Yup.string().required(t(Messages.passwordRequired)),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], t(Messages.passwordsMustMatch))
    });

    async function onUsernameFormSubmitted(username: string) {
        setUsername(username);
        setIsLoading(true);
        
        await Api.auth.forgotPasswordAsync(username)
            .then(() => {
                setStep(Step.PASSWORD);
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false));
    }

    async function onConfirmFormSubmitted(fields: ConfirmFormFields) {
        setIsLoading(true);

        await Api.auth.confirmForgotPasswordAsync(username, fields.code, fields.password)
            .then(() => {
                props.onSuccess()
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false));
    }

    if (step === Step.USERNAME) {
        return (
            <VerticalGridLayout
                className={classes.main}
                spacing={3}>

                <Typography variant="h5">{t(Strings.forgotPassword)}</Typography>
                <Typography variant="body1">{t(Messages.enterYourEmail)}</Typography>

                <Formik
                    initialValues={{username: ""}}
                    validationSchema={usernameValidationSchema}
                    onSubmit={async (fields: UsernameFormFields) => {
                        await onUsernameFormSubmitted(fields.username);
                    }}>

                    <Form>
                        <VerticalGridLayout spacing={3}>
                            <Field
                                name="username"
                                label={t(Strings.emailAddress)}
                                type={"email"}
                                component={CustomInputComponent}/>

                            {isLoading ?
                                <WaveLoading/>
                                :
                                <Button
                                    type="submit"
                                    fullWidth
                                    size={"large"}
                                    variant="contained"
                                    color="primary">
                                    {t(Strings.continue)}
                                </Button>
                            }
                        </VerticalGridLayout>
                    </Form>
                </Formik>
            </VerticalGridLayout>
        );
    } else {
        return (
            <VerticalGridLayout
                className={classes.main}
                spacing={3}>

                <Typography variant="h5">{t(Strings.resetPassword)}</Typography>
                <Typography variant="body1">{t(Messages.resetPasswordCodeSentTo)}</Typography>
                <Typography align={"center"} variant="body1">
                    <strong>{username}</strong>
                </Typography>

                <Formik
                    initialValues={{
                        code: "",
                        password: "",
                        confirmPassword: ""
                    }}
                    validationSchema={confirmValidationSchema}
                    onSubmit={async (fields: ConfirmFormFields) => {
                        await onConfirmFormSubmitted(fields);
                    }}>

                    <Form>
                        <VerticalGridLayout spacing={3}>
                            <Field
                                name="code"
                                label={t(Strings.code)}
                                component={PasswordInput}/>

                            <Field
                                name="password"
                                label={t(Strings.password)}
                                component={PasswordInput}/>

                            <Field
                                name="confirmPassword"
                                label={t(Strings.confirmPassword)}
                                component={PasswordInput}/>

                            {isLoading ?
                                <WaveLoading/>
                                :
                                <Button
                                    type="submit"
                                    fullWidth
                                    size={"large"}
                                    variant="contained"
                                    color="primary">
                                    {t(Strings.reset)}
                                </Button>
                            }
                        </VerticalGridLayout>
                    </Form>
                </Formik>
            </VerticalGridLayout>
        );
    }
};

export default ForgotPasswordControl;