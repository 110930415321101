import React, {FC} from 'react'
import './Layout.scss';
import AdminAppBar from "admin/components/layout/AdminAppBar";
import {useAppSettings} from "contexts/AppSettingsProvider";
import {darken} from "@material-ui/core/styles";

const AppLayout: FC = ({children}) => {
    const appSettings = useAppSettings();

    // TODO: Set this properly using style or some other way.
    document.body.style.backgroundColor = appSettings.colors.mode === "dark" ? "transparent" :
        darken(appSettings.colors.background ?? "#fff", .03);

    return (
        <div className={"Admin-App"}>
            <AdminAppBar/>
            <div className={"Admin-App body"}>
                {children}
            </div>
        </div>
    );
}

export default AppLayout;