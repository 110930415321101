import React from 'react';
import Shop from 'models/Shop';
import Api from 'util/api';
import {useHistory} from 'react-router-dom';
import MessagingManager from 'util/MessagingManager';
import {Container} from '@material-ui/core';
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import UpsertControl from "admin/components/shop/UpsertControl";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {shopPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";

interface Props {
    categoryId?: string;
}

const CreatePage: React.FC<Props> = (props) => {
    const history = useHistory();

    async function onFormSubmitted(shop: Partial<Shop>) {
        await createShop(shop);
    }

    async function createShop(shop: Partial<Shop>) {
        await Api.shop.admin.createShopAsync(shop)
            .then(async (data) => {
                const d = data as Shop;

                if (props.categoryId) {
                    d.categoryId = props.categoryId;
                    await Api.shop.admin.updateShopAsync(d);
                }

                MessagingManager.toast({
                    message: `'${shop.name}' was created successfully!`,
                    type: "success"
                });
                history.push(shopPath(d.id));
            });
    }

    return (<>
        <HtmlMetaDecorator
            title={"Create Shop"}/>

        <Container>
            <VerticalGridLayout spacing={2}>
                <Typography variant={"h6"}>
                    <strong>Create New Shop</strong>
                </Typography>

                <UpsertControl
                    actionText="Create"
                    onSubmit={shop => onFormSubmitted(shop)}/>

            </VerticalGridLayout>
        </Container>
    </>);

}

export default CreatePage;