import UserIdentity from "./UserIdentity";

class AuthenticationRequest extends UserIdentity {

    password: string;

    constructor(username: string, password: string) {
        super(username);
        this.password = password;
    }

}

export default AuthenticationRequest;
