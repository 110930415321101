interface Colors {
    primary: string;
    secondary: string;
    mode?: 'dark' | 'light';
    /**
     * Sets the background color of the entire page.
     * The value must be Hex code.
     */
    background?: string;
    /**
     * Sets the background color of some views in the pages; ex: product and category items.
     * The background color would be set based on the value as follows:
     * - 'undefined': the color would be the same as the 'background'
     * - 'string' (must be a Hex code): the same code provided
     * - 'number' (from 0.0 to ±1.0): positive value would use the 'background' and make it lighter, and negative value would darken it.
     */
    secondaryBackground?: number | string;
}

interface Shipping {
    addressFields: Record<string, boolean>;
}

interface AppSettings {
    version: string;
    authRequire?: boolean;
    defaultLocale?: string;
    localeEnabled?: boolean;
    isSingleShop: boolean;
    singleShopId?: string;
    appName: string;
    appDescription: string;
    defaultCurrency: string;
    priceDecimals: number;
    shipping: Shipping;
    isGoogleAnalyticEnabled?: boolean;
    googleAnalyticsCode?: string;
    colors: Colors;
}

export const version = "1.0.0";
export default AppSettings;