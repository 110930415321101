import ApiResponseOptions from "util/api/models/ApiResponseOptions";
import BaseApi from "util/api/BaseApi";
import ApiResponse from "util/api/models/ApiResponse";
import ClientSettings from "models/ClientSettings";

class SettingsApi extends BaseApi {
    private _admin?: AdminSettingsApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminSettingsApi();
        }
        return this._admin;
    }

    async getClientSettingsAsync(options?: ApiResponseOptions):
        Promise<ClientSettings | ApiResponse<ClientSettings> | undefined> {
        return this.get("settings/client/web", options);
    }
}

class AdminSettingsApi extends BaseApi {
}

export default SettingsApi;