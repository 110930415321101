import terminologies from "terminologies";
import AuthManager from "util/AuthManager";
import appSettings from "appSettings";

const t = terminologies;
// TODO: This is temporary, it should be provided properly by AppSettings or some helper.
const isMultiShop = () => AuthManager.user.isSuperAdmin ||
    (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

export const adminDashboardPath = () => "/";

const baseAppCategoryPath = () => `/${t.appCategory}`;

export const appCategoryIndexPath = () => baseAppCategoryPath();

export const appCategoryPath = (categoryId: string) => baseAppCategoryPath() + `/${categoryId}`;

export const createAppCategoryPath = (parentId?: string) =>
    baseAppCategoryPath() + `/create` + (parentId ? `?parentId=${parentId}` : '');

export const editAppCategoryPath = (categoryId: string) =>
    baseAppCategoryPath() + `/${categoryId}/edit`;

const baseCategoryPath = (shopId: string) =>
    isMultiShop() ? `/${t.shop}/${shopId}/${t.category}` : `/${t.category}`;

export const categoryIndexPath = (shopId: string) => baseCategoryPath(shopId);

export const categoryPath = (categoryId: string, shopId: string) =>
    baseCategoryPath(shopId) + `/${categoryId}`;

export const createCategoryPath = (shopId: string, parentId?: string) =>
    baseCategoryPath(shopId) + `/create` + (parentId ? `?parentId=${parentId}` : '');

export const editCategoryPath = (categoryId: string, shopId: string) =>
    baseCategoryPath(shopId) + `/${categoryId}/edit`;

// NOTE: This is the base path for Shop related pages;
//  the ShopId should only be added for Multi-Shop users.
const baseShopPath = (shopId?: string) =>
    `/${t.shop}` + (shopId && isMultiShop() ? `/${shopId}` : '');

export const shopIndexPath = () => baseShopPath();

export const shopPath = (shopId?: string) => baseShopPath(shopId);

export const createShopPath = (categoryId?: string) =>
    baseShopPath() + `/create` + (categoryId ? `?${t.appCategoryId}=${categoryId}` : '');

export const editShopPath = (shopId?: string) => baseShopPath(shopId) + `/edit`;

const baseShopAdminPath = (shopId: string) =>
    isMultiShop() ? `/${t.shop}/${shopId}/${t.user}` : `/${t.user}`;

export const shopAdminIndexPath = (shopId: string) => baseShopAdminPath(shopId);

export const createShopAdminPath = (shopId: string) => baseShopAdminPath(shopId) + "/create";

const baseProductPath = (shopId: string) =>
    isMultiShop() ? `/${t.shop}/${shopId}/${t.product}` : `/${t.product}`;

export const productIndexPath = (shopId: string) => baseProductPath(shopId);

export const createProductPath = (shopId: string, categoryId?: string) =>
    baseProductPath(shopId) + "/create" + (categoryId ? `?${t.categoryId}=${categoryId}` : '');

export const productPath = (shopId: string, productId: string) =>
    baseProductPath(shopId) + `/${productId}`;

export const editProductPath = (shopId: string, productId: string) =>
    baseProductPath(shopId) + `/${productId}/edit`;

const baseProductExtraPath = (shopId: string) =>
    isMultiShop() ? `/${t.shop}/${shopId}/${t.product}/${t.productExtra}` : `/${t.product}/${t.productExtra}`;

export const productExtraIndexPath = (shopId: string) => baseProductExtraPath(shopId);

const baseOrderPath = (shopId: string) =>
    isMultiShop() ? `/${t.shop}/${shopId}/${t.order}` : `/${t.order}`;

export const orderIndexPath = (shopId: string) => baseOrderPath(shopId);

export const orderPath = (shopId: string, orderId: string) =>
    baseOrderPath(shopId) + `/${orderId}`;

export const baseLookupPath = () => `/${t.lookup}`;

export const lookupIndexPath = () => baseLookupPath();