const Messages = {
    failedAddToCart: "failedAddToCart",
    productOutOfStock: "productOutOfStock",
    addedToCartSuccessfully: "addedToCartSuccessfully",
    updatedInCartSuccessfully: "updatedInCartSuccessfully",
    dataNotFound: "dataNotFound",
    enterYourEmail: "enterYourEmail",
    emailRequired: "emailRequired",
    fullNameRequired: "fullNameRequired",
    codeRequired: "codeRequired",
    passwordRequired: "passwordRequired",
    passwordsMustMatch: "passwordsMustMatch",
    resetPasswordCodeSentTo: "resetPasswordCodeSentTo",
    enterNewAccountDetails: "enterNewAccountDetails",
    alreadyHaveAccount: "alreadyHaveAccount",
    dontHaveAccount: "dontHaveAccount",
    emailWithOtpSentTo: "emailWithOtpSentTo",
    resetYourTempPassword: "resetYourTempPassword",
    passwordResetSuccessfully: "passwordResetSuccessfully",
    noSearchResultFound: "noSearchResultFound",
    searchTermLengthValidation: "searchTermLengthValidation",
    noProductsAddedTo: "noProductsAddedTo",
    noShopsAddedTo: "noShopsAddedTo",
    noFavouritesAdded: "noFavouritesAdded",
    pageNotFound: "pageNotFound",
    cartIsEmpty: "cartIsEmpty",
    clearCartConfirmation: "clearCartConfirmation",
    unableToInitiatePayment: "unableToInitiatePayment",
    cartItemsHaveBeenModified: "cartItemsHaveBeenModified",
    selectPaymentMethod: "selectPaymentMethod",
    unableToCheckout: "unableToCheckout",
    noItemsToCheckout: "noItemsToCheckout",
    invoiceAcknowledgement: "invoiceAcknowledgement",
    cannotFindYourOrder: "cannotFindYourOrder",
    weReceivedYourOrder: "weReceivedYourOrder",
    paymentFailed: "paymentFailed",
    unableToCompletePayment: "unableToCompletePayment",
    deleteConfirmation: "deleteConfirmation",
    unableToDelete: "unableToDelete",
    deletedSuccessfully: "deletedSuccessfully",
    unableToFindLink: "unableToFindLink",
    loginToSubscribeProduct: "loginToSubscribeProduct",
    maxProductOptionSelected: "maxProductOptionSelected",
    chooseOneProductOptionOptional: "chooseOneProductOptionOptional",
    chooseNumberProductOption: "chooseNumberProductOption",
    chooseAtLeastProductOption: "chooseAtLeastProductOption",
    chooseFromListProductOption: "chooseFromListProductOption",
    chooseUpToProductOption: "chooseUpToProductOption",
    allRightsReserved: "allRightsReserved",
    welcomeTo: "welcomeTo",
    noDetailsAddedYet: "noDetailsAddedYet",
}

export default Messages;