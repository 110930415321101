import React, {FC} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import IndexPage from 'admin/pages/category/IndexPage';
import EditPage from 'admin/pages/category/EditPage';
import DetailsPage from 'admin/pages/category/DetailsPage';
import CreatePage from 'admin/pages/category/CreatePage';
import terminologies from "terminologies";
import AuthManager from "util/AuthManager";
import {useAppSettings} from "contexts/AppSettingsProvider";

const CategoryRouter: FC = () => {
    const t = terminologies;
    const appSettings = useAppSettings();

    const isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    const getShopId = (params: any) => isMultiShop ?
        params[t.shopId] : appSettings.isSingleShop ?
            appSettings.singleShopId : AuthManager.user.shopId;

    return (
        <Switch>
            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.category}/create`}
                   render={({match, location}) =>
                       <CreatePage shopId={getShopId(match.params)}/>
                   }/>

            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.category}/:${t.categoryId}/edit`}
                   render={({match}) =>
                       <EditPage
                           type={"Shop"}
                           shopId={getShopId(match.params)}
                           categoryId={match.params[t.categoryId]}/>
                   }/>

            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.category}/:${t.categoryId}`}
                   render={({match}) =>
                       <DetailsPage
                           shopId={getShopId(match.params)}
                           categoryId={match.params[t.categoryId]}/>
                   }/>

            <Route exact path={`/(${t.shop})?/:${t.shopId}?/${t.category}`}
                   render={({match}) =>
                       <IndexPage shopId={getShopId(match.params)}/>
                   }/>

            <Redirect to="/404"/>
        </Switch>
    );
}

export default CategoryRouter;