import {Helmet} from "react-helmet";
import React, {PropsWithChildren} from "react";
import appSettings from "../appSettings";

interface Props {
    title: string;
    description?: string;
    // TODO: Set default image if empty
    imageUrl?: string;
    // TODO: Set the URL and other SEO tags
    // url: string;
}

const HtmlMetaDecorator = (props: PropsWithChildren<Props>) => {
    let title = appSettings.appName + (props.title ? ` - ${props.title}` : "");
    if (title.length > 55) {
        title = title.slice(0, 52) + "...";
    }

    let description = props.description ? props.description : appSettings.appDescription;
    if (description.length > 200) {
        description = description.slice(0, 197) + "...";
    }

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta property="og:site_name" content={appSettings.appName}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            {props.imageUrl && <meta property="og:image" content={props.imageUrl}/>}
            {/*<meta property="og:url" content={url}/>*/}
            {props.children}
        </Helmet>
    );
}

export default HtmlMetaDecorator;