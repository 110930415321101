import UserIdentity from "./UserIdentity";

class ConfirmRegistrationRequest extends UserIdentity {

    code: string;

    constructor(username: string, code: string) {
        super(username);
        this.code = code;
    }

}

export default ConfirmRegistrationRequest;
