import MUIDataTable, {MUIDataTableColumnDef} from 'mui-datatables';
import React, {FC} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {createTheme, Grid, MuiThemeProvider} from "@material-ui/core";
import Order, {getItemsTotalPrice} from "models/Order";
import {orderPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";

interface Props extends RouteComponentProps<any> {
    title: string,
    orders: Order[],
    shopId: string;
}

interface TableDetails {
    id: string;
    user: string;
    status: string;
    items: number;
    price: string;
    date: Date;
}

const tableColumns: MUIDataTableColumnDef[] = [
    {
        name: "id",
        label: "Order#",
        options: {
            filter: false
        }
    },
    {
        name: "user",
        label: "User"
    },
    {
        name: "status",
        label: "Status"
    },
    {
        name: "items",
        label: "Items",
        options: {
            filter: false,
        }
    },
    {
        name: "price",
        label: "Price",
        options: {
            filter: false,
        }
    },
    {
        name: "date",
        label: "Last Update",
        options: {
            filter: false,
            customBodyRender: (value) => {
                return `${value}`.toFriendlyDateTime();
            }
        }
    },
];

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: "bold"
            }
        }
    }
});

// TODO: Wrap long texts.
const OrdersList: FC<Props> = (props) => {
    function onTableRowClicked(dataIndex: number) {
        const order = props.orders[dataIndex];
        props.history.push(orderPath(props.shopId, order.id), order);
    }

    const data = props.orders.map(o => {
        const details: TableDetails = {
            id: o.id,
            user: o.user ? `${o.user.phone} - ${o.user.email}` : ``,
            status: o.status,
            items: o.items!.length,
            price: `${getItemsTotalPrice(o.items || []).padZero()} ${o.items![0].currency}`,
            date: o.modifiedAt?.toDate() || new Date(0)
        };
        return details;
    })

    return (<>
        <VerticalGridLayout spacing={2}>
            <Grid item style={{flexGrow: 1}}>
                <Typography variant={"h6"}>
                    <strong>{props.title}</strong>
                </Typography>
            </Grid>

            <MuiThemeProvider theme={theme}>
                <MUIDataTable
                    title={""}
                    data={data}
                    columns={tableColumns}
                    options={{
                        selectableRows: "none",
                        print: "false",
                        download: "false",
                        sortOrder: {
                            name: "date",
                            direction: "desc"
                        },
                        onRowClick: (rowData, rowMeta) => onTableRowClicked(rowMeta.dataIndex)
                    }}
                />
            </MuiThemeProvider>
        </VerticalGridLayout>
    </>);
}

export default withRouter(OrdersList);