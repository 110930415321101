import React from "react";
import {
    Typography as MuiTypography,
    TypographyProps
} from "@material-ui/core";

interface Props extends TypographyProps {
    component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';
}

export const Typography: React.FC<Props> = (props: Props) =>
    (
        <MuiTypography {...props}>
            {props.children}
        </MuiTypography>
    );