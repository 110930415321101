import Modal from 'components/controls/Modal';
import MUIDataTable, {MUIDataTableColumnDef} from 'mui-datatables';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import {Button, Chip, createTheme, Grid, MuiThemeProvider} from "@material-ui/core";
import Shop from "models/Shop";
import ShopPicker from "components/shop/ShopPicker";
import {createShopPath, shopPath} from "admin/routers/PathBuilder";
import appSettings from "appSettings";
import {HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Typography} from "components/controls/helpers/Typography";

interface Props extends RouteComponentProps<any> {
    title: string,
    shops: Shop[],
    categoryId?: string
    canManage: boolean;
    onAddAsync: (shop: Shop) => Promise<boolean>;
    onRemoveAsync: (shop: Shop) => Promise<boolean>;
}

interface State {
    isShopPickerModalShown: boolean
}

const tableColumns: MUIDataTableColumnDef[] = [
    {
        name: "name",
        label: "Name",
        options: {
            filter: false
        }
    },
    {
        name: "isHidden",
        label: "Visibility",
        options: {
            customBodyRender: (value: boolean) => {
                if (value) {
                    return (<Chip color={"secondary"} label={"Hidden"}/>);
                }
            }
        }
    },
    {
        name: "modifiedAt",
        label: "Last Update",
        options: {
            display: "false",
            filter: false,
            customBodyRender: (value: string) => {
                return value.toFriendlyDateTime();
            }
        }
    }
];

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: "bold"
            }
        }
    }
});

// TODO: Wrap long texts.
class ShopsList extends React.Component<Props> {

    readonly state: State = {
        isShopPickerModalShown: false
    }

    onTableRowClicked(dataIndex: number) {
        const shop = this.props.shops[dataIndex];
        this.props.history.push(shopPath(shop.id));
    }

    onCreateButtonClicked() {
        this.props.history.push(createShopPath(this.props.categoryId));
    }

    onManageShopsButtonClicked() {
        this.toggleManageModal()
    }

    toggleManageModal() {
        this.setState({isShopPickerModalShown: !this.state.isShopPickerModalShown});
    }

    render() {
        console.log(this.props.shops)
        return (<>
            <VerticalGridLayout spacing={2}>

                <HorizontalGridLayout>

                    <Grid item style={{flexGrow: 1}}>
                        <Typography variant={"h6"}>
                            <strong>{this.props.title}</strong>
                        </Typography>
                    </Grid>

                    <HorizontalGridLayout spacing={1}>
                        {
                            this.props.canManage &&
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EditIcon/>}
                                onClick={this.onManageShopsButtonClicked.bind(this)}>Manage</Button>
                        }

                        {
                            !appSettings.isSingleShop &&
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon/>}
                                onClick={this.onCreateButtonClicked.bind(this)}>New Shop</Button>
                        }
                    </HorizontalGridLayout>

                </HorizontalGridLayout>

                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        title={""}
                        data={this.props.shops}
                        columns={tableColumns}
                        options={{
                            selectableRows: "none",
                            print: "false",
                            download: "false",
                            onRowClick: (rowData, rowMeta) => this.onTableRowClicked(rowMeta.dataIndex)
                        }}
                    />
                </MuiThemeProvider>
            </VerticalGridLayout>

            <Modal
                title={`Manage Shops`}
                open={this.state.isShopPickerModalShown}
                toggleModal={this.toggleManageModal.bind(this)}>

                <ShopPicker
                    currentShops={this.props.shops}
                    categoryId={this.props.categoryId ?? ""}
                    onAddAsync={this.props.onAddAsync}
                    onRemoveAsync={this.props.onRemoveAsync}/>

            </Modal>
        </>);
    }
}

export default withRouter(ShopsList);