import React from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Divider
} from "@material-ui/core";

interface Props {
    title?: string;
    subtitle?: string;
}

class Panel extends React.Component<Props> {

    render() {
        const hasHeader = this.props.title || this.props.subtitle;

        return (
            <Card variant="outlined">

                {hasHeader && <CardHeader title={this.props.title} subheader={this.props.subtitle}/>}
                {hasHeader && <Divider/>}

                <CardContent>
                    {this.props.children}
                </CardContent>

            </Card>
        );
    }

}

export default Panel;