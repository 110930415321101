import ForgotPasswordControl from 'components/account/ForgotPasswordControl';
import React, {FC} from 'react';
import {Box, Container, Paper} from "@material-ui/core";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {useHistory} from "react-router-dom";
import {loginPath} from "router/PathBuilder";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";

const ForgotPasswordPage: FC = () => {
    const history = useHistory();
    const {t} = useTranslation();

    return (<>
        <HtmlMetaDecorator
            title={t(Strings.forgotPassword)}/>

        <div className={"login-page-main"}>
            <div className={"login-page-main bg1"}>
                <div className={"login-page-main bg2"}>
                    <div className={"login-page-content"}>
                        <Container maxWidth="xs">
                            <Paper variant={"elevation"} elevation={5} style={{borderRadius: 12}}>
                                <Box py={5} my={2}>
                                    <ForgotPasswordControl
                                        onSuccess={() => history.push(loginPath())}/>
                                </Box>
                            </Paper>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default ForgotPasswordPage;