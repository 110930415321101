import Model from "./Model";
import ProductExtras from "models/ProductExtras";

interface OrderItem extends Model {
    shopId: string;
    productId: string;
    quantity: number;
    unitPrice: number;
    currency: string;
    imageUrls: string[];
    comment?: string;
    sku: string;
    size: string;
    unit: string;
    extras: ProductExtras[];

    // TODO: This is added to be used by the UI to show errors on checkout. Remove it and user VM for the UI.
    status?: string;
}

export function getUnitPriceWithExtras(item: OrderItem) {
    let unitPrice = item.unitPrice;
    item.extras?.forEach(i => i.options?.forEach(o => unitPrice += o.price))
    return unitPrice;
}

export function getTotalPriceWithExtras(item: OrderItem) {
    let unitPrice = getUnitPriceWithExtras(item);
    return unitPrice * item.quantity;
}

export default OrderItem;