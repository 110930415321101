import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";
import AuthManager from "util/AuthManager";

interface AuthProps {
    isAuthenticated: boolean;
    updateAuth: () => void;
}

const defaultProps: AuthProps = {
    isAuthenticated: AuthManager.user.isAuthenticated,
    updateAuth: () => {
    }
}

export const AuthenticationContext = createContext<AuthProps>(defaultProps);

export const useAuthentication = () => useContext(AuthenticationContext);

const AuthenticationProvider: FC = (props) => {
    const [auth, setAuth] = useState<AuthProps>(defaultProps);
    const [isLoading, setIsLoading] = useState(true);

    defaultProps.updateAuth = () => {
        setAuth(ps => ({...ps, isAuthenticated: AuthManager.user.isAuthenticated}))
    };

    useEffect(() => {
        void async function fetchData() {
            if (isLoading) {
                setIsLoading(false)
            }
        }();
    });

    if (isLoading) {
        return (<WaveLoadingPage/>)
    } else {
        return (
            <AuthenticationContext.Provider value={auth}>
                {props.children}
            </AuthenticationContext.Provider>
        );
    }
}

export default AuthenticationProvider;