import ApiClient from "util/api/client";
import AuthenticationRequest from "./models/communication/AuthenticationRequest";
import RefreshAuthRequest from "./models/communication/RefreshAuthRequest";
import ResetPasswordRequest from "./models/communication/ResetPasswordRequest";
import ConfirmForgetPasswordRequest from "./models/communication/ConfirmForgetPasswordRequest";
import UserIdentity from "./models/communication/UserIdentity";
import ConfirmRegistrationRequest from "./models/communication/ConfirmRegistrationRequest";
import RegistrationRequest from "./models/communication/RegistrationRequest";
import ChallengeNewPasswordRequest from "util/api/models/communication/ChallengeNewPasswordRequest";
import ApiResponseOptions from "util/api/models/ApiResponseOptions";
import ApiResponse from "util/api/models/ApiResponse";
import AuthenticationResponse from "util/api/models/communication/AuthenticationResponse";
import BaseApi from "util/api/BaseApi";

class AuthenticationApi extends BaseApi {

    // TODO: Specify the return types

    async authenticateAsync(username: string, password: string) {
        const request = new AuthenticationRequest(username, password);
        const response = await ApiClient.post(`authentication`, request);
        return response.data;
    }

    async refreshTokenAsync(username: string, refreshToken: string, deviceKey: string) {
        const request = new RefreshAuthRequest(username, refreshToken, deviceKey);
        const response = await ApiClient.post('authentication/refresh', request);
        return response.data;
    }

    async registerAsync(username: string, name: string, password: string, options?: ApiResponseOptions):
        Promise<any | ApiResponse<any> | undefined> {
        const request = new RegistrationRequest(username, name, "", password);
        return this.post(`registration`, request, options);
    }

    async confirmRegistrationAsync(username: string, code: string) {
        const request = new ConfirmRegistrationRequest(username, code);
        const response = await ApiClient.post(`registration/confirm`, request);
        return response.data;
    }

    async forgotPasswordAsync(username: string, options?: ApiResponseOptions):
        Promise<any | ApiResponse<any> | undefined> {
        const request = new UserIdentity(username);
        return this.post(`user/forgotpassword`, request, options);
    }

    async confirmForgotPasswordAsync(username: string, code: string, password: string, options?: ApiResponseOptions):
        Promise<any | ApiResponse<any> | undefined> {
        const request = new ConfirmForgetPasswordRequest(username, code, password);
        return this.post(`user/confirmforgotpassword`, request, options);
    }

    async resetPasswordAsync(username: string, oldPassword: string, newPassword: string, options?: ApiResponseOptions):
        Promise<AuthenticationResponse | ApiResponse<AuthenticationResponse> | undefined> {
        const request = new ResetPasswordRequest(username, oldPassword, newPassword);
        return this.post(`user/resetpassword`, request, options);
    }

    async challengeNewPassword(username: string, password: string, options?: ApiResponseOptions):
        Promise<AuthenticationResponse | ApiResponse<AuthenticationResponse> | undefined> {
        const body = new ChallengeNewPasswordRequest(username, password);
        return this.post<AuthenticationResponse>(`authentication/challenge`, body, options);
    }

}

export default AuthenticationApi;