import AppSettings, {version} from "models/AppSettings";

const appSettings: AppSettings = {
    version: version,
    authRequire: true,
    defaultLocale: 'en',
    localeEnabled: false,
    isSingleShop: true,
    singleShopId: "f126c41156b34b279248956a6c6edec7",
    colors: {
        primary: "#e4193e",
        secondary: "#19ace4",
    },
    appName: "Snipe CRM",
    appDescription: "Snipe CRM",
    defaultCurrency: "BHD",
    priceDecimals: 3,
    shipping: {
        addressFields: {
            "Apartment Number": false,
            "Floor": false,
            "Building Name": false,
            "House/Building": true,
            "Road": true,
            "Block": true,
            "City": true,
            "Additional Instructions": false,
            "Phone Number": true,
            "Email": true
        }
    }
}

export default appSettings;