import React, {FC, useEffect, useState} from 'react';
import Modal from 'components/controls/Modal';
import Product, {productFields} from 'models/Product';
import Api from 'util/api';
import {Box, Button, Container, Grid, Link, Paper} from "@material-ui/core";
import Panel from "components/controls/common/Panel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import 'App.scss';
import {VerticalGridLayout, HorizontalGridLayout, GridItem} from "components/controls/helpers/GridLayout";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import MessagingManager from "util/MessagingManager";
import ProductExtras from "models/ProductExtras";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";
import DataNotFound from "components/controls/common/DataNotFound";
import {useHistory} from "react-router-dom";
import {editProductPath, shopPath} from "admin/routers/PathBuilder";
import TabsControl, {TabPanel} from "components/controls/common/TabsControl";
import {Typography} from "components/controls/helpers/Typography";
import {useAppCache} from "contexts/AppCacheProvider";
import Media from "models/Media";
import OptionGroupsList from "admin/components/product/options/OptionGroupsList";

interface Props {
    productId: string;
    shopId: string;
}

interface State {
    product?: Product,
    extras?: ProductExtras[],
    isDeleteModalShown: boolean;
    isLoading: boolean;
}

// TODO: Store/load the data from cache.
const DetailsPage: FC<Props> = (props) => {
    const cache = useAppCache();
    const history = useHistory();

    const [state, setState] = useState<State>({
        isDeleteModalShown: false,
        isLoading: true
    });

    useEffect(() => {
        void async function fetchData() {
            if (!state.product || state.product.id !== props.productId) {
                await Api.product.admin.getProductAsync(props.shopId, props.productId)
                    .then((data) => {
                        const d = data as Product[];
                        if (d.length) {
                            setState(s => ({...s, product: d[0]}));
                        }
                    });

                setState(s => ({...s, isLoading: false}));
            }
        }();
    }, [props.shopId, props.productId, state.product]);

    function toggleDeleteModal() {
        setState(s => ({...s, isDeleteModalShown: !s.isDeleteModalShown}));
    }

    async function deletedProduct() {
        await Api.product.admin.deleteProductAsync(props.shopId, props.productId)
            .then(() => {
                MessagingManager.toast({
                    message: `'${state.product?.name}' was deleted`,
                    type: "success"
                })
                // TODO: Redirect to Products tab.
                history.push(shopPath(props.shopId));
            });
    }

    function onEditButtonClicked() {
        history.push(editProductPath(props.shopId, props.productId));
    }

    const product = state.product;
    const attributes = product?.customAttributes || {};

    if (state.isLoading) {
        return (<WaveLoadingPage/>);
    } else if (!product) {
        return (<DataNotFound/>);
    } else {
        const attachmentFields = productFields.filter(f => f.type === "attachments");

        return (<>
            <HtmlMetaDecorator
                title={product.name ?? ""}/>

            <Container>
                <VerticalGridLayout spacing={4}>
                    <HorizontalGridLayout>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant={"h6"}>
                                <strong>Account Details</strong>
                            </Typography>
                        </Grid>

                        <HorizontalGridLayout spacing={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon/>}
                                onClick={onEditButtonClicked}>Edit</Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<DeleteIcon/>}
                                onClick={toggleDeleteModal}>Delete</Button>
                        </HorizontalGridLayout>
                    </HorizontalGridLayout>

                    <Panel>
                        <TabsControl>
                            <TabPanel title={"Details"}>
                                <Box pt={3}>
                                    <HorizontalGridLayout spacing={3}>
                                        {productFields.filter(f => f.type !== "attachments").map(f => {
                                            let value = attributes[f.name] || "-";
                                            if (f.type === "lookup" && f.values) {
                                                const lookup = cache.lookups[f.values];
                                                value = lookup?.find(l => l.id === attributes[f.name])?.name;
                                            }

                                            return (
                                                <GridItem
                                                    key={f.name} xs={12}
                                                    sm={f.settings?.multiline ? 12 : 6}
                                                    md={f.settings?.multiline ? 12 : 4}>
                                                    <VerticalGridLayout spacing={1}>
                                                        <Typography color={"textSecondary"} variant={"body2"}>
                                                            {f.label}
                                                        </Typography>
                                                        <Typography variant={"body1"}>
                                                            {value}
                                                        </Typography>
                                                    </VerticalGridLayout>
                                                </GridItem>
                                            )
                                        })}
                                    </HorizontalGridLayout>
                                </Box>
                            </TabPanel>

                            {
                                Boolean(attachmentFields?.length) &&
                                <TabPanel title={"Attachments"}>
                                    <Box pt={3}>
                                        <VerticalGridLayout spacing={4}>
                                            {attachmentFields.map(f => {
                                                const media = attributes[f.name] as Media[];

                                                return (<>
                                                    <Typography variant={"body1"}>
                                                        <strong>{f.label}</strong>
                                                    </Typography>
                                                    <Box pt={2}>
                                                        {media?.length ?
                                                            <HorizontalGridLayout spacing={2} alignItems={"center"}>
                                                                {media.map((m, i) =>
                                                                    <GridItem key={i} xs={12} sm={6} md={4}>
                                                                        <Link href={m.url} target={"_blank"}>
                                                                            <Paper variant={"outlined"}>
                                                                                <Box p={2}>
                                                                                    <Typography variant={"body2"}>
                                                                                        {m.name}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Paper>
                                                                        </Link>
                                                                    </GridItem>
                                                                )}
                                                            </HorizontalGridLayout>
                                                            :
                                                            "No items yet!"
                                                        }
                                                    </Box>
                                                </>);
                                            })}
                                        </VerticalGridLayout>
                                    </Box>
                                </TabPanel>
                            }

                            <TabPanel title={"Deals"}>
                                <Box pt={3}>
                                    <OptionGroupsList
                                        productId={product.id}
                                        shopId={product.shopId}/>
                                </Box>
                            </TabPanel>
                        </TabsControl>
                    </Panel>
                </VerticalGridLayout>
            </Container>

            <Modal
                title={`Are you sure you want to delete this account?`}
                open={state.isDeleteModalShown}
                toggleModal={toggleDeleteModal}>
                <HorizontalGridLayout justify="flex-end" spacing={1}>
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<CancelIcon/>}
                        onClick={toggleDeleteModal}>Cancel</Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon/>}
                        onClick={deletedProduct}>Delete</Button>
                </HorizontalGridLayout>
            </Modal>
        </>);
    }
}

export default DetailsPage;