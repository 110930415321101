import {AxiosResponse} from "axios";
import ApiResponse from "./models/ApiResponse";
import MessagingManager from "util/MessagingManager";
import ApiResponseOptions from "./models/ApiResponseOptions";
import ApiClient from "util/api/client";

class BaseApi {

    async get<T>(path: string, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.get<ApiResponse<T>>(path);
        }, options);
    }

    async post<T>(path: string, body?: any, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.post<ApiResponse<T>>(path, body);
        }, options);
    }

    async put<T>(path: string, body?: any, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.put<ApiResponse<T>>(path, body);
        }, options);
    }

    async patch<T>(path: string, body?: any, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.patch<ApiResponse<T>>(path, body);
        }, options);
    }

    async delete<T>(path: string, body?: any, options?: ApiResponseOptions) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.delete<ApiResponse<T>>(path, {data: body});
        }, options);
    }

    handleResponse<T>(callback: (() => Promise<AxiosResponse<ApiResponse<T>>>), options = new ApiResponseOptions()) {
        return new Promise<T | ApiResponse<T> | undefined>(async (resolve, reject) => {
            await callback()
                .then(response => {
                    const data = response.data;
                    if (options.returnResponse) {
                        return resolve(data);
                    } else if (data.success) {
                        return resolve(data.output);
                    } else if (data.code === -32748) {
                        MessagingManager.toast({
                            type: "info",
                            message: "Data are outdated. Please refresh the page."
                        });
                    } else {
                        if (data.errors) {
                            if (options.returnErrors) {
                                return reject(data.errors);
                            }
                            MessagingManager.toast({errors: data.errors});
                        } else {
                            MessagingManager.toast({message: data.message, type: "error"});
                        }

                        return reject("API_ERROR");
                    }
                })
                .catch((error) => {
                    //NOTE: This handles ApiClient Request/Response errors
                    MessagingManager.toast({message: "Internal Error!", type: "error"});
                    console.log("ApiClient Error", error);
                    return reject("API_ERROR");
                });
        });
    }

}

export default BaseApi;