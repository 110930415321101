import User from "models/User";
import Model from "models/Model";
import OrderItem, {getTotalPriceWithExtras} from "models/OrderItem";
import Address from "models/Address";

export const orderStatuses = ["Pending", "Processing", "Delivering",
    "Completed", "Cancelled"] as const;
export type OrderStatus = typeof orderStatuses[number];

interface Order extends Model {
    shopId: string;
    transactionId: string;
    anonymousCartId?: string;
    user: User;
    shippingAddress: Partial<Address>;
    saveShippingAddress: boolean;
    paymentProvider: string;
    paymentMethod: string;
    shippingCost: number;
    status: OrderStatus;
    comment?: string;
    items?: OrderItem[];
}

export function getItemsTotalPrice(items: OrderItem[]) {
    let total = 0;
    items?.forEach(i => total += getTotalPriceWithExtras(i));
    return total;
}

export default Order;