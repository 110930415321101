import React, {ReactElement} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Divider} from "@material-ui/core";

interface TabPanelProps {
    title: string;
    disabled?: boolean;
    children?: React.ReactNode;
}

export const TabPanel: React.FC<TabPanelProps> = () => {
    return null;
}

const TabsControl: React.FC = (props) => {

    const [currentTab, setCurrentTab] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    function render() {
        let i = 0;
        let tabs: ReactElement[] = [];
        let panels: ReactElement[] = [];

        React.Children.forEach(props.children, child => {
            if (child && (child as ReactElement).type === TabPanel) {
                const index = i++;
                const p = (child as ReactElement<TabPanelProps>).props;

                tabs.push(<Tab key={index} label={p.title} disabled={p.disabled}/>);
                panels.push(
                    <div key={index} hidden={currentTab !== index}>
                        {currentTab === index && p.children}
                    </div>
                );
            }
        });

        return (<>
            <Tabs
                value={currentTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs}
            </Tabs>

            <Divider/>

            {panels}
        </>);
    }

    return (<>
        {render()}
    </>);
}

export default TabsControl;