import AppCache from "util/AppCache";
import ApiResponseOptions from "./models/ApiResponseOptions";
import ApiResponse from "./models/ApiResponse";
import BaseApi from "./BaseApi";
import OrderItem from "models/OrderItem";
import Transaction from "models/Transaction";
import Order from "models/Order";
import AuthManager from "util/AuthManager";

class CartApi extends BaseApi {

    private _admin?: AdminCartApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminCartApi();
        }
        return this._admin;
    }

    private getCartUrl(shopId?: string, itemId?: string): string {
        const anonymousCartId = AppCache.getAnonymousCartId();
        let url = "cart";
        if (shopId) url += `/${shopId}`;
        if (itemId) url += `/${itemId}`;
        if (anonymousCartId) url += `?anonymousCartId=${anonymousCartId}`;
        return url;
    }

    async getCarts(options?: ApiResponseOptions): Promise<Order[] | ApiResponse<Order[]> | undefined> {
        return this.get<Order[]>(this.getCartUrl(), options);
    }

    async getCart(shopId: string, options?: ApiResponseOptions):
        Promise<OrderItem[] | ApiResponse<OrderItem[]> | undefined> {
        return this.get<OrderItem[]>(this.getCartUrl(shopId), options);
    }

    async upsertItemToCart(shopId: string, item: Omit<OrderItem, "id">, options?: ApiResponseOptions):
        Promise<OrderItem | ApiResponse<OrderItem> | undefined> {
        return this.put(this.getCartUrl(shopId), item, options);
    }

    async removeItemFromCart(shopId: string, itemId: string, options?: ApiResponseOptions):
        Promise<OrderItem | ApiResponse<OrderItem> | undefined> {
        return this.delete(this.getCartUrl(shopId, itemId), null, options);
    }

    async clearCart(shopId: string, options?: ApiResponseOptions):
        Promise<OrderItem[] | ApiResponse<OrderItem[]> | undefined> {
        return this.delete(this.getCartUrl(shopId), null, options);
    }

    async checkoutCart(shopId: string, order: Partial<Order>, options?: ApiResponseOptions):
        Promise<Transaction | ApiResponse<Transaction> | undefined> {
        return this.post(this.getCartUrl(shopId), order, options);
    }

    async getOrdersAsync(options?: ApiResponseOptions):
        Promise<Order[] | ApiResponse<Order[]> | undefined> {
        return this.get('order', options);
    }

    async getOrderAsync() {
        return [];
    }

}

class AdminCartApi extends BaseApi {
    async getOrderAsync(orderId: string, shopId: string, options?: ApiResponseOptions):
        Promise<Order[] | ApiResponse<Order[]> | undefined> {
        const path = `order/shop/${shopId}?lastId=${orderId}`;
        return this.get(path, options);
    }

    async getOrdersAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Order[] | ApiResponse<Order[]> | undefined> {
        const path = `order/shop/${shopId}`;
        return this.get(path, options);
    }

    async updateOrderStatusAsync(order: Order, options?: ApiResponseOptions):
        Promise<Order | ApiResponse<Order> | undefined> {
        console.log(order)
        const body = {
            orderId: order.id,
            shopId: order.shopId,
            userId: AuthManager.user.cognitoSub,
            status: order.status,
            modifiedAt: order.modifiedAt
        };
        return this.patch(`order/${order.id}`, body, options);
    }
}

export default CartApi;