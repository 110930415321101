import React, {FC} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import CreatePage from 'admin/pages/user/CreatePage';
import terminologies from "terminologies";
import AuthManager from "util/AuthManager";
import IndexPage from "admin/pages/user/IndexPage";
import {useAppSettings} from "contexts/AppSettingsProvider";

const UserRouter: FC = () => {
    const t = terminologies;
    const appSettings = useAppSettings();

    const isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    const getShopId = (params: any) => isMultiShop ?
        params[t.shopId] : appSettings.isSingleShop ?
            appSettings.singleShopId : AuthManager.user.shopId;

    return (
        <Switch>
            {!AuthManager.user.isAppAdmin && <Redirect to={"/404"}/>}

            <Route exact path={`/(${t.shop})?/:${t.shopId}?/${t.user}/create`}
                   render={({match}) => <CreatePage shopId={getShopId(match.params)}/>}/>

            <Route exact path={`/(${t.shop})?/:${t.shopId}?/${t.user}`}
                   render={({match}) => <IndexPage shopId={getShopId(match.params)}/>}/>

            <Redirect to={"/404"}/>
        </Switch>
    );
}

export default UserRouter;