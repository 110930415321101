import UserIdentity from "./UserIdentity";

class ConfirmForgetPasswordRequest extends UserIdentity {

    code: string;
    password: string;

    constructor(username: string, code: string, password: string) {
        super(username);
        this.code = code;
        this.password = password;
    }

}

export default ConfirmForgetPasswordRequest;
