import React, {useEffect, useState} from 'react';
import Api from 'util/api';
import {Container} from "@material-ui/core";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import Shop from "models/Shop";
import ShopsList from "admin/components/shop/ShopsList";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";

const IndexPage: React.FC = () => {
    const [shops, setShop] = useState<Shop[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        void async function fetchData() {
            await Api.shop.getShopsAsync()
                .then(data => setShop(data as Shop[]));
            setIsLoading(false);
        }();
    }, []);

    if (isLoading) {
        return (<WaveLoadingPage/>);
    } else {
        return (<>
            <HtmlMetaDecorator
                title={"Shops"}/>

            <Container>
                <ShopsList
                    title={"All Shops"}
                    canManage={false}
                    shops={shops}
                    onAddAsync={async () => {
                        return false;
                    }}
                    onRemoveAsync={async () => {
                        return false;
                    }}/>
            </Container>
        </>);
    }
}

export default IndexPage;