import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Shop from 'models/Shop';
import Api from 'util/api';
import MessagingManager from 'util/MessagingManager';
import {Container} from "@material-ui/core";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import UpsertControl from "admin/components/shop/UpsertControl";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import DataNotFound from "components/controls/common/DataNotFound";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";
import {shopPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";

interface Props {
    shopId: string;
}

const EditPage: React.FC<Props> = (props) => {
    const history = useHistory();

    const [shop, setShop] = useState<Shop>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        void async function fetchData() {
            await Api.shop.getShopAsync(props.shopId)
                .then((data) => {
                    const d = data as Shop[];
                    if (d.length) {
                        setShop(d[0]);
                    }
                });

            setIsLoading(false);
        }();
    }, [props.shopId]);

    async function saveShop(shop: Partial<Shop>) {
        await Api.shop.admin.updateShopAsync(shop)
            .then(() => {
                MessagingManager.toast({
                    message: `'${shop.name}' was updated successfully!`,
                    type: "success"
                });

                history.push(shopPath(shop.id!));
            });
    }

    if (isLoading) {
        return (<WaveLoadingPage/>);
    } else if (!shop) {
        return (<DataNotFound/>);
    } else {
        return (<>
            <HtmlMetaDecorator
                title={`(Edit) ${shop.name}`}/>

            <Container>
                <VerticalGridLayout spacing={2}>
                    <Typography variant={"h6"}>
                        <strong>Edit Shop</strong>
                    </Typography>

                    <UpsertControl
                        shop={shop}
                        actionText="Save"
                        onSubmit={shop => saveShop(shop)}/>

                </VerticalGridLayout>
            </Container>
        </>);
    }
}

export default EditPage;