import React from 'react';
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {Box, Container} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import terminologies from "terminologies";
import AuthManager from "util/AuthManager";
import appSettings from "appSettings";
import {Typography} from "components/controls/helpers/Typography";

interface Props extends RouteComponentProps<any> {
}

class DashboardPage extends React.Component<Props> {
    isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    shopId = this.isMultiShop ?
        this.props.match.params[terminologies.shopId] : appSettings.isSingleShop ?
            appSettings.singleShopId : AuthManager.user.shopId;

    render() {
        return (<>
            <HtmlMetaDecorator
                title={"Dashboard"}/>

            <Container maxWidth={"xl"}>
                <VerticalGridLayout spacing={2}>
                    <Box px={3} pb={1}>
                        <Typography variant={"h6"}>
                            <strong>Dashboard</strong>
                        </Typography>
                    </Box>
                </VerticalGridLayout>
            </Container>
        </>);
    }
}

export default DashboardPage;