import Model from "./Model";
import {OptionsDisplayType} from "admin/models/validationSchemas/product/options/optionTypes";
import CustomField from "models/CustomField";

export const optionGroupFields: CustomField[] = [
    {
        name: "name",
        label: "Name",
        settings: {
            indexed: true,
            filterable: false,
            searchable: true
        }
    },
    {
        name: "value",
        label: "Value",
        settings: {
            indexed: true,
            filterable: false,
            searchable: false
        }
    },
    {
        name: "stage",
        label: "Stage",
        type: "lookup",
        values: "dealStage",
        settings: {
            indexed: true,
            filterable: true,
            searchable: false
        }
    },
    {
        name: "closeDate",
        label: "Close Date",
        type: "date",
        settings: {
            indexed: true,
            filterable: true,
            searchable: false
        }
    },
    {
        name: "salesOwner",
        label: "Sales Owner",
        settings: {
            indexed: true,
            filterable: true,
            searchable: true
        }
    },
    {
        name: "notes",
        label: "Notes",
        settings: {
            multiline: true
        }
    },
    {
        name: "attachments",
        label: "Attachments",
        type: "attachments",
        settings: {
            indexable: false
        }
    }
];

interface ProductOptionGroup extends Model {
    productId: string;
    minimum: number;
    maximum?: number;
    description?: string;
    imageUrl?: string;
    displayType: OptionsDisplayType;
}

export default ProductOptionGroup;