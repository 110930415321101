import React, {FC} from 'react';
import {Switch, Redirect} from "react-router-dom";
import DashboardPage from 'admin/pages/DashboardPage';
import DefaultRoute from './routes/DefaultRoute';
import CategoryRouter from './CategoryRouter';
import ProductRouter from './ProductRouter';
import OrderRouter from "./OrderRouter";
import ShopRouter from "admin/routers/ShopRouter";
import UserRouter from "admin/routers/UserRouter";
import terminologies from "terminologies";
import {useAppSettings} from "contexts/AppSettingsProvider";
import AppCategoryRouter from "admin/routers/AppCategoryRouter";
import AuthManager from "util/AuthManager";
import IndexPage from "admin/pages/lookup/IndexPage";
import NotFoundPage from "pages/NotFoundPage";
import ProfilePage from "pages/ProfilePage";

// NOTE: <BrowserRouter> shouldn't be added here, because this Router will be called by the
//  main App router, which has BrowserRouter on its own.

const SingleShopRoutes: FC = () => {
    const t = terminologies;

    return (
        <Switch>
            <DefaultRoute path={`/${t.category}`} component={CategoryRouter}/>
            <DefaultRoute path={`/${t.product}`} component={ProductRouter}/>
            <DefaultRoute path={`/${t.user}`} component={UserRouter}/>
            <DefaultRoute path={`/${t.order}`} component={OrderRouter}/>
            <DefaultRoute path={`/${t.shop}`} component={ShopRouter}/>

            {AuthManager.user.isAppAdmin &&
            <DefaultRoute path={`/${t.lookup}`} component={IndexPage}/>}

            <DefaultRoute exact path={`/${t.profile}`} component={ProfilePage}/>
            <DefaultRoute exact path={"/"} component={DashboardPage}/>
            <DefaultRoute exact path="/404" component={NotFoundPage}/>

            <Redirect to="/404"/>
        </Switch>
    );
}

const MultiShopRoutes: FC = () => {
    const t = terminologies;

    return (
        <Switch>
            <DefaultRoute path={`/${t.shop}/:${t.shopId}/${t.category}`}
                          component={CategoryRouter}/>

            <DefaultRoute path={`/${t.shop}/:${t.shopId}/${t.product}`}
                          component={ProductRouter}/>

            <DefaultRoute path={`/${t.shop}/:${t.shopId}/${t.user}`}
                          component={UserRouter}/>

            <DefaultRoute path={`/${t.shop}/:${t.shopId}/${t.order}`}
                          component={OrderRouter}/>

            {/* NOTE: The Shop paths must be after all the other shop related paths. */}
            <DefaultRoute path={`/${t.shop}`} component={ShopRouter}/>
            <DefaultRoute path={`/${t.appCategory}`} component={AppCategoryRouter}/>

            {AuthManager.user.isAppAdmin &&
            <DefaultRoute path={`/${t.lookup}`} component={IndexPage}/>}

            <DefaultRoute exact path={`/${t.profile}`} component={ProfilePage}/>
            <DefaultRoute exact path={"/"} component={DashboardPage}/>
            <DefaultRoute exact path="/404" component={NotFoundPage}/>

            <Redirect to="/404"/>
        </Switch>
    );
}

const Router: FC = () => {
    const appSettings = useAppSettings();

    const isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    if (isMultiShop) {
        return (<MultiShopRoutes/>);
    } else if (AuthManager.user.isAdmin) {
        return (<SingleShopRoutes/>);
    } else {
        return (<Redirect to={"/"}/>);
    }
}

export default Router;