import AuthenticationApi from "./AuthenticationApi";
import ApiClient from "util/api/client";
import CartApi from "./CartApi";
import CategoryApi from "./CategoryApi";
import ProductApi from "./ProductApi";
import UserApi from "./UserApi";
import BaseApi from "./BaseApi";
import ApiResponseOptions from "./models/ApiResponseOptions";
import UploadParameters from "./models/storage/UploadParameters";
import UploadInstructions from "./models/storage/UploadInstructions";
import ApiResponse from "./models/ApiResponse";
import HomePageResponse from "./models/communication/HomePageResponse";
import ShopApi from "util/api/ShopApi";
import TransactionApi from "util/api/TransactionApi";
import SettingsApi from "util/api/SettingsApi";
import LookupApi from "util/api/LookupApi";

class Api extends BaseApi {

    private static _instance: Api;

    private _authApi?: AuthenticationApi;
    private _userApi?: UserApi;
    private _shopApi?: ShopApi;
    private _productApi?: ProductApi;
    private _categoryApi?: CategoryApi;
    private _cartApi?: CartApi;
    private _transactionApi?: TransactionApi;
    private _settingsApi?: SettingsApi;
    private _lookupApi?: LookupApi;

    public static get instance() {
        return Api._instance || (Api._instance = new this());
    }

    async getShopsAsync() {
        const response = await ApiClient.get('shop');
        return response.data.output;
    }

    async getHomePageDataAsync(options?: ApiResponseOptions):
        Promise<HomePageResponse | ApiResponse<HomePageResponse> | undefined> {
        return this.get<HomePageResponse>('home', options);
    }

    async getStatusAsync<T>(options?: ApiResponseOptions) {
        return this.get<T>('status', options);
    }

    async getLookupsAsync() {
        const response = await ApiClient.get('lookups');
        return response.data.output;
    }

    // TODO: Move to separate client
    async getFile(url: string): Promise<any> {
        return new Promise<any>(async (resolve) => {
            await ApiClient.get("", {baseURL: url, headers: []})
                .then(response => {
                    return resolve(response.data ?? undefined);
                })
                .catch((error) => {
                    console.log(error);
                    return resolve(undefined);
                });
        });
    }

    async getUploadInstructions(params: UploadParameters, options?: ApiResponseOptions):
        Promise<UploadInstructions | ApiResponse<UploadInstructions> | undefined> {
        let path = `upload/${params.purpose}`;
        if (params.shopId) {
            path += `?shopid=${params.shopId}`;
        }
        return this.get<UploadInstructions>(path, options);
    }

    async uploadToUrl(instructions: UploadInstructions, file: File): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await ApiClient.put<any>(instructions.uploadUrl,
                    file, {headers: instructions.headers});

                if (response?.status === 200) {
                    return resolve(true);
                } else {
                    return reject(false);
                }
            } catch (error) {
                return reject(false);
            }
        });
    }

    get auth() {
        if (!this._authApi) {
            this._authApi = new AuthenticationApi();
        }
        return this._authApi;
    }

    get user() {
        if (!this._userApi) {
            this._userApi = new UserApi();
        }
        return this._userApi;
    }

    get shop() {
        if (!this._shopApi) {
            this._shopApi = new ShopApi();
        }
        return this._shopApi;
    }

    get product() {
        if (!this._productApi) {
            this._productApi = new ProductApi();
        }
        return this._productApi;
    }

    get category() {
        if (!this._categoryApi) {
            this._categoryApi = new CategoryApi();
        }
        return this._categoryApi;
    }

    get cart() {
        if (!this._cartApi) {
            this._cartApi = new CartApi();
        }
        return this._cartApi;
    }

    get transaction() {
        if (!this._transactionApi) {
            this._transactionApi = new TransactionApi();
        }
        return this._transactionApi;
    }

    get settings() {
        if (!this._settingsApi) {
            this._settingsApi = new SettingsApi();
        }
        return this._settingsApi;
    }

    get lookup() {
        if (!this._lookupApi) {
            this._lookupApi = new LookupApi();
        }
        return this._lookupApi;
    }
}

const instance = Api.instance;
export default instance;