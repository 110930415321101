import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import MUIDataTable, {MUIDataTableColumnDef} from 'mui-datatables';
import {HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import AddIcon from "@material-ui/icons/Add";
import {Button, Chip, createTheme, Grid, MuiThemeProvider} from "@material-ui/core";
import User from "models/User";
import Api from "util/api/Api";
import {createShopAdminPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";

interface Props {
    shopId: string;
}

interface State {
    users: User[];
    isLoading: boolean;
}

const tableColumns: MUIDataTableColumnDef[] = [
    {
        name: "name",
        label: "Name",
        options: {
            filter: false
        }
    },
    {
        name: "email",
        label: "Email Address",
        options: {
            filter: false
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            customBodyRender: (value) => {
                return value === "CONFIRMED" ?
                    <Chip color={"primary"} label={"Confirmed"}/>
                    :
                    <Chip label={"Pending"}/>
            }
        }
    },
    {
        name: "createdDate",
        label: "Created At",
        options: {
            filter: false,
            customBodyRender: (value) => {
                return `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`;
            }
        }
    }
];

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: "bold"
            }
        }
    }
});

const UsersList: FC<Props> = (props) => {

    const history = useHistory();
    const [state, setState] = useState<State>({
        users: [],
        isLoading: true
    });

    useEffect(() => {
        void async function fetchData() {
            const data = await Api.user.admin.getShopAdminUsersAsync(props.shopId) as User[];
            if (data) {
                setState(prevState => ({
                    ...prevState,
                    users: data,
                    isLoading: false
                }));
            }
        }();
    }, [props.shopId]);

    function onCreateButtonClicked() {
        history.push(createShopAdminPath(props.shopId));
    }

    return (<>
        <VerticalGridLayout spacing={2}>

            <HorizontalGridLayout spacing={1}>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant={"h6"}>
                        <strong>Users</strong>
                    </Typography>
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={onCreateButtonClicked}>
                    New User
                </Button>
            </HorizontalGridLayout>

            <div style={{display: 'table', tableLayout: 'fixed', width: '100%'}}>
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        title={""}
                        data={state.users}
                        columns={tableColumns}
                        options={{
                            selectableRows: "none",
                            print: "false",
                            download: "false",
                            responsive: "standard"
                        }}
                    />
                </MuiThemeProvider>
            </div>
        </VerticalGridLayout>
    </>);
}

export default UsersList;