import React from "react";
import {Field, Form, Formik} from "formik";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import CustomInputComponent from "components/controls/helpers/CustomInputComponent";
import {Button} from "@material-ui/core";
import User from "models/User";
import {FieldProps} from "formik/dist/Field";
import * as Yup from "yup";

interface Props {
    user?: Partial<User>;
    actionText: string;
    onSubmit: (user: Partial<User>) => Promise<void>;
}

const validationSchema = Yup.object().shape<Partial<User>>({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
});

const initialValues: Partial<User> = {
    name: '',
    username: '',
    email: ''
};

const UpsertControl: React.FC<Props> = (props) => {

    const user = props.user;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={user ?? initialValues}
            validationSchema={validationSchema}
            onSubmit={async values => {
                // TODO: Add the Username field once it's supported on the client.
                values.username = values.email;
                await props.onSubmit(values);
            }}>

            <Form>
                <VerticalGridLayout
                    wrap={"nowrap"}
                    spacing={2}>

                    <VerticalGridLayout spacing={3}>
                        <Field
                            name="name"
                            label="Full Name"
                            component={(props: FieldProps) => {
                                return (
                                    <CustomInputComponent
                                        {...props}
                                        required/>
                                );
                            }}/>

                        <Field
                            name="email"
                            label="Email Address"
                            component={(props: FieldProps) => {
                                return (
                                    <CustomInputComponent
                                        {...props}
                                        required/>
                                );
                            }}/>
                    </VerticalGridLayout>

                    <Button
                        type="submit"
                        size={"large"}
                        variant="contained"
                        color="primary">{props.actionText}</Button>

                </VerticalGridLayout>
            </Form>

        </Formik>
    );
}

export default UpsertControl;