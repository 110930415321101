import React, {useState} from 'react'
import {Link} from "react-router-dom";
import AuthManager from 'util/AuthManager';
import NewPasswordControl from "components/account/NewPasswordControl";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "components/controls/helpers/Typography";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Field, Form, Formik} from "formik";
import CustomInputComponent from "components/controls/helpers/CustomInputComponent";
import {Box, Button} from "@material-ui/core";
import Copyright from "components/Copyright";
import appSettings from "appSettings";
import MessagingManager from "util/MessagingManager";
import PasswordInput from "components/controls/common/PasswordInput";
import {forgotPasswordPath} from "router/PathBuilder";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";
import {useAuthentication} from "contexts/AuthenticationProvider";
import WaveLoading from "components/controls/common/WaveLoading";

interface Props {
    onSuccess: () => void;
}

interface State {
    step: "LOGIN" | "VERIFICATION" | "NEW_PASSWORD";
}

interface FormFields {
    username: string;
    password: string;
    checked: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        textAlign: "center",
        padding: theme.spacing(2, 3)
    },
    logo: {
        width: 200,
    }
}));

const LoginControl: React.FC<Props> = (props) => {
    const auth = useAuthentication();
    const classes = useStyles();
    const {t} = useTranslation();

    const [state, setState] = useState<State>({step: "LOGIN"});
    const [isLoading, setIsLoading] = useState(false);
    const [credentials, setCredentials] = useState({
        username: "",
        password: ""
    });

    const initialValues: FormFields = {
        username: "",
        password: "",
        checked: ["RememberMe"]
    };

    async function login(username: string, password: string) {
        setIsLoading(true);

        setCredentials({
            username: username,
            password: password
        });

        const result = await AuthManager.authenticateAsync(username, password);

        switch (result) {
            case "Success":
                auth.updateAuth();
                props.onSuccess();
                break;
            case "Incomplete":
                setState({step: "VERIFICATION"});
                break;
            case "NEW_PASSWORD_REQUIRED":
                setState({step: "NEW_PASSWORD"});
                break;
            default:
                if (result.errors && result.errors.length) {
                    MessagingManager.toast({errors: result.errors, type: "error"})
                } else {
                    MessagingManager.toast({message: result.message, type: "error"})
                }
                break;
        }

        setIsLoading(false);
    }

    if (state.step === "LOGIN") {
        return (
            <VerticalGridLayout className={classes.main} spacing={3}>
                <img
                    src={"/navbar-logo.png"}
                    alt={`${appSettings.appName} logo`}
                    className={classes.logo}/>

                <Typography variant="h5">{t(Strings.login)}</Typography>

                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={async fields => {
                        await login(fields.username, fields.password);
                    }}>

                    <Form>
                        <VerticalGridLayout spacing={3}>

                            <Field
                                name="username"
                                label={t(Strings.emailAddress)}
                                required
                                autoFocus
                                // type={"email"}
                                component={CustomInputComponent}/>

                            <Field
                                name="password"
                                label={t(Strings.password)}
                                type="password"
                                required
                                component={PasswordInput}/>

                            {/* TODO: Enable this option. Need to manage the Local & Session storage. */}
                            {/*<HorizontalGridLayout spacing={1}>*/}
                            {/*    <Field type="checkbox" name="checked" value="RememberMe"/>*/}
                            {/*    <Typography>Remember me</Typography>*/}
                            {/*</HorizontalGridLayout>*/}

                            {isLoading ?
                                <WaveLoading/>
                                :
                                <Button
                                    type="submit"
                                    fullWidth
                                    size={"large"}
                                    variant="contained"
                                    color="primary">
                                    {t(Strings.login)}
                                </Button>
                            }
                        </VerticalGridLayout>
                    </Form>
                </Formik>

                <Box mt={2}>
                    <Link to={forgotPasswordPath()}>
                        <Typography variant="body2">{t(Strings.forgotPassword)}</Typography>
                    </Link>
                </Box>

                <Box mt={3}>
                    <Copyright/>
                </Box>

            </VerticalGridLayout>
        );
    } else if (state.step === "NEW_PASSWORD") {
        return (
            <NewPasswordControl
                username={credentials.username}
                onSuccess={props.onSuccess}/>
        );
    } else {
        return (<></>);
    }
};

export default LoginControl;