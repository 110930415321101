import Model from "./Model";
import Product from "./Product";
import Shop from "models/Shop";
import CustomField from "models/CustomField";

export const categoryFields: CustomField[] = [
    {
        name: "firstName",
        label: "First Name",
        settings: {
            indexed: true,
            filterable: false,
            searchable: true
        }
    },
    {
        name: "lastName",
        label: "Last Name",
        settings: {
            indexed: true,
            filterable: false,
            searchable: true
        }
    },
    {
        name: "jobTitle",
        label: "Job Title",
        settings: {
            indexed: true,
            searchable: true
        }
    },
    {
        name: "email",
        label: "Email Address",
        settings: {
            indexed: true,
            filterable: true,
            searchable: true
        }
    },
    {
        name: "workNumber",
        label: "Work Number"
    },
    {
        name: "mobileNumber",
        label: "Mobile Number",
        settings: {
            indexed: true,
            filterable: true,
            searchable: true
        }
    },
    {
        name: "salesOwner",
        label: "Sales Owner",
        settings: {
            indexed: true,
            filterable: true,
            searchable: true
        }
    },
    {
        name: "notes",
        label: "Notes",
        settings: {
            multiline: true
        }
    },
    {
        name: "attachments",
        label: "Attachments",
        type: "attachments",
        settings: {
            indexable: false
        }
    }
];

interface Category extends Model {
    description?: string;
    shortDescription: string;
    imageUrl?: string;
    shopId?: string;
    parentId: string;
    products: Product[];
    shops: Shop[];
    categories: Category[];
}

export default Category;