import React from 'react'
import MUIDataTable, {MUIDataTableOptions, MUIDataTableColumnDef} from 'mui-datatables';
import {createTheme, IconButton, MuiThemeProvider} from '@material-ui/core';
import Api from 'util/api';
import update from 'immutability-helper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import Shop from "models/Shop";

interface Props {
    currentShops: Shop[],
    categoryId: string;
    onAddAsync: (shop: Shop) => Promise<boolean>;
    onRemoveAsync: (shop: Shop) => Promise<boolean>;
}

interface State {
    items?: ListItem[];
}

interface ListItem {
    shop: Shop;
    name: string;
    isAdded: boolean;
}

const theme = createTheme({
    overrides: {
        MuiTableHead: {
            root: {
                display: "none"
            }
        },
        MuiTableRow: {
            root: {
                padding: "10px"
            }
        },
        MuiTableCell: {
            body: {
                padding: "10px"
            }
        },
    }
});

class ShopPicker extends React.Component<Props, State> {

    readonly state: State = {};

    private readonly columns: MUIDataTableColumnDef[] = [
        {
            name: "name"
        },
        {
            name: "isAdded",
            label: "Action",
            options: {
                customBodyRender: (isAdded: boolean, tableMeta) => {
                    return (<>
                        {isAdded ?
                            <IconButton
                                size={"small"}
                                style={{float: "right"}}
                                onClick={async () => await this.onRemoveButtonClickedAsync(tableMeta.rowIndex)}>
                                <ClearIcon/>
                            </IconButton>
                            :
                            <IconButton
                                color={"primary"}
                                size={"small"}
                                style={{float: "right"}}
                                onClick={async () => await this.onAddButtonClickedAsync(tableMeta.rowIndex)}>
                                <AddCircleIcon/>
                            </IconButton>
                        }
                    </>);
                }
            }
        }
    ];

    private readonly options: MUIDataTableOptions = {
        elevation: 0,
        selectableRows: "none",
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        pagination: false,
        responsive: 'standard'
    };

    async componentDidMount() {
        await Api.shop.admin.getShopsAsync()
            .then((data) => {
                const items = (data as Shop[]).map<ListItem>(s => ({
                    shop: s,
                    name: s.name,
                    isAdded: this.props.currentShops.some(s1 => s1.id === s.id)
                }));

                this.setState({items: items});
            });
    }

    async onAddButtonClickedAsync(rowIndex: number): Promise<void> {
        const item = this.state.items![rowIndex];

        if (await this.props.onAddAsync(item.shop)) {
            this.updateItemState(rowIndex);
        }
    }

    async onRemoveButtonClickedAsync(rowIndex: number): Promise<void> {
        const item = this.state.items![rowIndex];
        if (await this.props.onRemoveAsync(item.shop)) {
            this.updateItemState(rowIndex);
        }
    }

    private updateItemState(rowIndex: number) {
        const newState = {
            items: update(this.state.items, {
                [rowIndex]: {isAdded: {$set: !this.state.items![rowIndex].isAdded}}
            })
        };

        this.setState(newState);
    }

    render() {
        const items = this.state.items ?? [];

        return (<>
            {items.length > 0 ?
                <MuiThemeProvider theme={theme}>

                    <MUIDataTable
                        title=""
                        data={items}
                        columns={this.columns}
                        options={this.options}/>

                </MuiThemeProvider>
                :
                <p style={{margin: 0, fontSize: 12, textAlign: "center"}}>No shops available yet!</p>
            }
        </>);
    }

}

export default ShopPicker;