import BaseApi from "./BaseApi";
import ApiResponseOptions from "./models/ApiResponseOptions";
import Category from "models/Category";
import ApiResponse from "./models/ApiResponse";
import Product from "models/Product";

class CategoryApi extends BaseApi {

    private _admin?: AdminCategoryApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminCategoryApi();
        }
        return this._admin;
    }

    async getCategoryProductsAsync(shopId: string, categoryId: string, options?: ApiResponseOptions):
        Promise<Product[] | ApiResponse<Product[]> | undefined> {
        const path = `product/${shopId}/${categoryId}`;
        return this.get<Product[]>(path, options);
    }

    async getAppCategoriesAsync(options?: ApiResponseOptions):
        Promise<Category[] | ApiResponse<Category[]> | undefined> {
        return this.get<Category[]>('category', options);
    }

    async getAppCategoryAsync(categoryId: string, options?: ApiResponseOptions):
        Promise<Category[] | ApiResponse<Category[]> | undefined> {
        return this.get<Category[]>(`category?categoryid=${categoryId}`, options);
    }

    async getShopCategoriesAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Category[] | ApiResponse<Category[]> | undefined> {
        return this.get<Category[]>(`category/${shopId}`, options);
    }

    async getShopCategoryAsync(shopId: string, categoryId: string, options?: ApiResponseOptions):
        Promise<Category[] | ApiResponse<Category[]> | undefined> {
        return this.get<Category[]>(`category/${shopId}?categoryid=${categoryId}`, options);
    }

}

class AdminCategoryApi extends BaseApi {
    async getAppCategoryAsync(categoryId: string, options?: ApiResponseOptions):
        Promise<Category[] | ApiResponse<Category[]> | undefined> {
        return this.get<Category[]>(`category?categoryid=${categoryId}&showHidden=true`, options);
    }

    async getShopCategoriesAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Category[] | ApiResponse<Category[]> | undefined> {
        return this.get<Category[]>(`category/${shopId}?showHidden=true`, options);
    }

    async getShopCategoryAsync(shopId: string, categoryId: string, options?: ApiResponseOptions):
        Promise<Category[] | ApiResponse<Category[]> | undefined> {
        return this.get<Category[]>(`category/${shopId}?categoryid=${categoryId}&showHidden=true`, options);
    }

    /**
     * If 'Category.ShopId' is set, then a Shop Category will be created;
     * otherwise, an App Category will be created.
     */
    async createCategoryAsync(category: Partial<Category>, options?: ApiResponseOptions):
        Promise<Category | ApiResponse<Category> | undefined> {
        return this.post<Category>("category", category, options);
    }

    async updateCategoryAsync(category: Partial<Category>, options?: ApiResponseOptions):
        Promise<Category | ApiResponse<Category> | undefined> {
        return this.put<Category>("category", category, options);
    }

    async deleteCategoryAsync(categoryId: string, shopId?: string, options?: ApiResponseOptions):
        Promise<Category | ApiResponse<Category> | undefined> {
        const body = {
            id: categoryId,
            shopId: shopId
        };
        return this.delete<Category>("category", body, options);
    }

    async removeSubcategoryAsync(categoryId: string, parentId: string, shopId?: string, options?: ApiResponseOptions):
        Promise<Category | ApiResponse<Category> | undefined> {
        const body = {
            id: categoryId,
            parentId: parentId,
            shopId: shopId
        };
        return this.delete<Category>("category", body, options);
    }

}

export default CategoryApi;