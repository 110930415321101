import UserIdentity from "./UserIdentity";

class ResetPasswordRequest extends UserIdentity {

    oldPassword: string;
    newPassword: string;

    constructor(username: string, oldPassword: string, newPassword: string) {
        super(username);
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
    }

}

export default ResetPasswordRequest;
