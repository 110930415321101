import React, {createRef} from "react";
import {Field, FieldArray, Form, Formik} from "formik";
import {HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import Panel from "components/controls/common/Panel";
import CustomInputComponent from "components/controls/helpers/CustomInputComponent";
import {Button, Grid, IconButton} from "@material-ui/core";
import Shop, {ShippingMode} from "models/Shop";
import CreateShopSchema from "admin/models/validationSchemas/shop/CreateSchema";
import FileUploadControl from "components/controls/upload/FileUploadControl";
import AuthManager from "util/AuthManager";
import {Typography} from "components/controls/helpers/Typography";
import {CloseOutlined} from "@material-ui/icons";

interface Props {
    shop?: Partial<Shop>;
    actionText: string;
    onSubmit: ((shop: Partial<Shop>) => void);
}

interface VM {
    name: string;
    description: string;
    shortDescription: string;
    shippingMode: ShippingMode;
    shippingCost: number;
    logoUrl: string;
    modifiedAt?: string;
    imageUrls: string[];
    settings?: {
        senderEmailName: string;
        senderEmailAddress: string;
        notificationEmailAddresses: string[];
    }
}

// TODO: Allow the SuperAdmin and AppAdmin to manage the IsHidden.
// TODO: Scroll to the top on validation error or show a message.
const UpsertControl: React.FC<Props> = (props) => {
    const logoUploadRef = createRef<FileUploadControl>();
    const imagesUploadRef = createRef<FileUploadControl>();

    const shop = props.shop;

    let initialValues: VM;
    if (shop) {
        initialValues = JSON.parse(JSON.stringify(shop));
        if (!initialValues.settings) {
            initialValues.settings = {
                senderEmailName: '',
                senderEmailAddress: '',
                notificationEmailAddresses: []
            }
        }
    } else {
        initialValues = {
            name: '',
            description: '',
            shortDescription: '',
            shippingMode: 0,
            shippingCost: 0,
            logoUrl: '',
            imageUrls: [],
            settings: {
                senderEmailName: '',
                senderEmailAddress: '',
                notificationEmailAddresses: []
            }
        };
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={shop ?? initialValues}
            validationSchema={CreateShopSchema}
            onSubmit={async values => {
                values.imageUrls = await imagesUploadRef.current?.getUrls() ?? [];
                const logo = await logoUploadRef.current?.getUrls();
                values.logoUrl = logo ? logo[0] : "";
                if (values.settings?.notificationEmailAddresses) {
                    values.settings.notificationEmailAddresses = values.settings.notificationEmailAddresses
                        .filter((email, index, list) => Boolean(email?.trim()) && list.indexOf(email) === index);
                }
                props.onSubmit(values);
            }}>
            {({values}) => (
                <Form>
                    <VerticalGridLayout wrap={"nowrap"} spacing={2}>
                        <Panel>
                            <VerticalGridLayout spacing={3}>
                                <Field
                                    name="name"
                                    label="Shop Name"
                                    component={CustomInputComponent}/>

                                <Field
                                    name="shortDescription"
                                    label="Short Description"
                                    component={CustomInputComponent}/>

                                <Field
                                    multiline
                                    name="description"
                                    label="Description"
                                    component={CustomInputComponent}/>
                            </VerticalGridLayout>
                        </Panel>

                        <Panel title={"Shipping Settings"}>
                            <VerticalGridLayout spacing={3}>
                                {
                                    AuthManager.user.isAppAdmin &&
                                    <Field
                                        name="shippingMode"
                                        label="Shipping Mode"
                                        component={CustomInputComponent}/>
                                }

                                <Field
                                    name="shippingCost"
                                    label="Shipping Cost"
                                    component={CustomInputComponent}/>
                            </VerticalGridLayout>
                        </Panel>

                        <Panel title={"Notification Settings"}>
                            <VerticalGridLayout spacing={3}>
                                <Field
                                    name="settings.senderEmailName"
                                    label="Sender Email Name"
                                    component={CustomInputComponent}/>

                                <Field
                                    name="settings.senderEmailAddress"
                                    label="Sender Email Address"
                                    component={CustomInputComponent}/>

                                <FieldArray name="settings.notificationEmailAddresses">
                                    {({insert, remove, push}) => (
                                        <VerticalGridLayout spacing={3}>
                                            <Typography variant={"body1"}>
                                                <strong>Notification Email Addresses:</strong>
                                            </Typography>
                                            {values.settings?.notificationEmailAddresses.map((address, index) => (
                                                <HorizontalGridLayout
                                                    key={index}
                                                    spacing={1}
                                                    alignItems={"center"}
                                                    wrap={"nowrap"}>
                                                    <Grid style={{flexGrow: 1}}>
                                                        {/* TODO: Validate the Emails Address. */}
                                                        <Field
                                                            name={`settings.notificationEmailAddresses.${index}`}
                                                            label={"Email Address"}
                                                            component={CustomInputComponent}/>
                                                    </Grid>
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => remove(index)}>
                                                        <CloseOutlined/>
                                                    </IconButton>
                                                </HorizontalGridLayout>
                                            ))}
                                            <Button
                                                variant={"outlined"}
                                                color={"primary"}
                                                size={"large"}
                                                onClick={() => push('')}>
                                                Add Email Address
                                            </Button>
                                        </VerticalGridLayout>
                                    )}
                                </FieldArray>
                            </VerticalGridLayout>
                        </Panel>

                        {
                            shop?.id &&
                            <Panel title={"Shop Logo"}>
                                <FileUploadControl
                                    purpose={"shop-logo"}
                                    shopId={shop?.id}
                                    limit={1}
                                    initialState={initialValues.logoUrl || undefined}
                                    ref={logoUploadRef}/>
                            </Panel>
                        }

                        {
                            shop?.id &&
                            <Panel title={"Shop Images"}>
                                <FileUploadControl
                                    purpose={"shop-image"}
                                    shopId={shop?.id}
                                    initialState={initialValues.imageUrls}
                                    ref={imagesUploadRef}/>
                            </Panel>
                        }

                        <Button
                            type="submit"
                            size={"large"}
                            variant="contained"
                            color="primary">
                            {props.actionText}
                        </Button>
                    </VerticalGridLayout>
                </Form>
            )}
        </Formik>
    );
}

export default UpsertControl;