import React from 'react';
import Api from 'util/api';
import {Box, Card, Container, Grid} from "@material-ui/core";
import OrdersList from "admin/components/order/OrdersList";
import Order, {OrderStatus} from "models/Order";
import {HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Typography} from "components/controls/helpers/Typography";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";

interface Props {
    shopId: string;
}

interface State {
    orders: Order[]
    isLoading: boolean
}

class IndexPage extends React.Component<Props, State> {

    readonly state: State = {
        orders: [],
        isLoading: true
    }

    async componentDidMount() {
        this.setState({
            orders: await Api.cart.admin.getOrdersAsync(this.props.shopId) as Order[],
            isLoading: false
        });
    }

    render() {
        const ordersByStatus: Record<OrderStatus, Order[]> = this.state.orders
            .reduce((r: Record<any, Order[]>, order: Order) => {
                r[order.status || "Pending"] = [...r[order.status || "Pending"] || [], order];
                return r;
            }, {});

        if (this.state.isLoading) {
            return (<WaveLoadingPage/>);
        } else {
            return (
                <Container maxWidth={"xl"}>
                    <VerticalGridLayout spacing={4}>
                        <div>
                            <Typography variant={"h6"}>
                                <strong>Orders Stats</strong>
                            </Typography>

                            <HorizontalGridLayout spacing={2}>
                                <Grid item xs={6} md={3}>
                                    <Card>
                                        <Box p={1}>
                                            <VerticalGridLayout alignItems={"center"}>
                                                <Typography variant={"h6"}>Pending</Typography>
                                                <Typography variant={"h5"}>
                                                    <strong>{ordersByStatus["Pending"]?.length || 0}</strong>
                                                </Typography>
                                            </VerticalGridLayout>
                                        </Box>
                                    </Card>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <Card>
                                        <Box p={1}>
                                            <VerticalGridLayout alignItems={"center"}>
                                                <Typography variant={"h6"}>Processing</Typography>
                                                <Typography variant={"h5"}>
                                                    <strong>{ordersByStatus["Processing"]?.length || 0}</strong>
                                                </Typography>
                                            </VerticalGridLayout>
                                        </Box>
                                    </Card>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <Card>
                                        <Box p={1}>
                                            <VerticalGridLayout alignItems={"center"}>
                                                <Typography variant={"h6"}>Delivering</Typography>
                                                <Typography variant={"h5"}>
                                                    <strong>{ordersByStatus["Delivering"]?.length || 0}</strong>
                                                </Typography>
                                            </VerticalGridLayout>
                                        </Box>
                                    </Card>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <Card>
                                        <Box p={1}>
                                            <VerticalGridLayout alignItems={"center"}>
                                                <Typography variant={"h6"}>Completed</Typography>
                                                <Typography variant={"h5"}>
                                                    <strong>{ordersByStatus["Completed"]?.length || 0}</strong>
                                                </Typography>
                                            </VerticalGridLayout>
                                        </Box>
                                    </Card>
                                </Grid>
                            </HorizontalGridLayout>
                        </div>

                        <OrdersList
                            title={"Orders"}
                            shopId={this.props.shopId}
                            orders={this.state.orders}/>
                    </VerticalGridLayout>
                </Container>
            );
        }
    }
}

export default IndexPage;