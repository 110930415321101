import React, {FC, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import {GridItem, HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Button, IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useAppCache} from "contexts/AppCacheProvider";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import {Delete} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "components/controls/Modal";
import Lookup from "models/Lookup";
import Api from "util/api";
import MessagingManager from "util/MessagingManager";
import UpsertControl from "admin/components/lookup/UpsertControl";

interface Props {
    id: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        details: {
            cursor: "pointer",
        },
        expand: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        }
    }),
);

const LookupListItem: FC<Props> = (props: Props) => {
    const cache = useAppCache();
    const classes = useStyles();

    const items = cache.lookups[props.id];
    const [expanded, setExpanded] = React.useState(!items?.length);

    const [selectedItem, setSelectedItem] = useState<Lookup>();

    const [showUpsertModal, setShowUpsertModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const toggleUpsertModal = () => setShowUpsertModal(prevState => !prevState);
    const toggleDeleteModal = () => setShowDeleteModal(prevState => !prevState);

    const toggleContent = () => {
        setExpanded(!expanded);
    };

    const deleteItem = async () => {
        toggleDeleteModal();

        await Api.lookup.admin.deleteLookupAsync(props.id, selectedItem!.id)
            .then(() => {
                MessagingManager.toast({
                    message: `'${selectedItem?.name}' was deleted`,
                    type: "success"
                })

                cache.removeLookupItem(props.id, selectedItem!.id);
            });

        setSelectedItem(undefined);
    }

    return (<>
        <Paper>
            <Box p={1}>
                <VerticalGridLayout>
                    <Box p={1} className={classes.details} onClick={toggleContent}>
                        <HorizontalGridLayout alignItems={"center"} spacing={1}>
                            <GridItem style={{flexGrow: 1}}>
                                <Typography variant={"body1"}>
                                    <strong>{props.id}</strong>
                                </Typography>
                            </GridItem>
                            <IconButton
                                size={"small"}
                                onClick={toggleContent}>
                                <ExpandMoreIcon
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                    })}/>
                            </IconButton>
                        </HorizontalGridLayout>
                    </Box>

                    <Collapse in={expanded} timeout="auto" mountOnEnter>
                        <Box px={1} py={2}>
                            <VerticalGridLayout spacing={2}>
                                <div style={{display: 'table', tableLayout: 'fixed', width: '100%'}}>
                                    <TableContainer>
                                        <Table size={"small"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell>Modified At</TableCell>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {items.map((item) =>
                                                    <TableRow key={item.id}>
                                                        <TableCell>
                                                            <Typography variant={"body2"}>
                                                                {item.name}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell>
                                                            <Typography variant={"body2"}>
                                                                {item.description}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell>
                                                            <Typography variant={"body2"}>
                                                                {item.modifiedAt?.toFriendlyDateTime()}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell>
                                                            <HorizontalGridLayout
                                                                justify={"flex-end"}
                                                                wrap={"nowrap"}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setSelectedItem(item);
                                                                        toggleUpsertModal();
                                                                    }}>
                                                                    <EditIcon/>
                                                                </IconButton>

                                                                <IconButton
                                                                    onClick={() => {
                                                                        setSelectedItem(item);
                                                                        toggleDeleteModal();
                                                                    }}>
                                                                    <Delete/>
                                                                </IconButton>
                                                            </HorizontalGridLayout>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <Button
                                    variant={items.length ? "outlined" : "contained"}
                                    color="primary"
                                    fullWidth
                                    startIcon={<AddIcon/>}
                                    onClick={toggleUpsertModal}>
                                    Add Item
                                </Button>
                            </VerticalGridLayout>
                        </Box>
                    </Collapse>
                </VerticalGridLayout>
            </Box>
        </Paper>

        <Modal
            title={"Lookup Item"}
            open={showUpsertModal}
            toggleModal={toggleUpsertModal}>
            <UpsertControl
                id={props.id}
                itemId={selectedItem?.id}
                onSuccess={() => toggleUpsertModal()}/>
        </Modal>

        <Modal
            title={`Are you sure you want to delete '${selectedItem?.name}'?`}
            open={showDeleteModal}
            toggleModal={toggleDeleteModal}>
            <HorizontalGridLayout justify="flex-end" spacing={1}>
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<CancelIcon/>}
                    onClick={toggleDeleteModal}>Cancel</Button>

                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon/>}
                    onClick={deleteItem}>Delete</Button>
            </HorizontalGridLayout>
        </Modal>
    </>);
}

export default LookupListItem;