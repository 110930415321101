import React, {FC, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import Api from 'util/api';
import AuthManager from 'util/AuthManager';
import User from "models/User";
import Loading from "components/layout/Loading";
import TabsControl, {TabPanel} from "components/controls/common/TabsControl";
import {Box, Container, Paper} from "@material-ui/core";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import ResetPasswordControl from "components/account/ResetPasswordControl";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Typography} from "components/controls/helpers/Typography";
import {loginPath} from "router/PathBuilder";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";

interface State {
    user?: User;
    isLoading: boolean;
}

const ProfilePage: FC = () => {
    const {t} = useTranslation();

    const [state, setState] = useState<State>({
        isLoading: true
    });

    useEffect(() => {
        void async function fetchData() {
            const data = await Api.user.getProfileAsync() as User;
            setState(prevState => ({
                ...prevState,
                user: data,
                isLoading: false
            }));
        }();
    }, []);

    if (!AuthManager.user.isAuthenticated) {
        return (<Redirect to={loginPath()}/>)
    } else if (state.isLoading) {
        return (<Loading/>);
    } else if (!state.user) {
        return (<Redirect to={"/404"}/>);
    } else {
        return (<>
            <HtmlMetaDecorator
                title={t(Strings.profile)}/>

            <Container>
                <VerticalGridLayout spacing={2}>
                    <Typography variant={"h6"}>
                        <strong>Profile</strong>
                    </Typography>
                    <Paper>
                        <Box p={2}>
                            <TabsControl>
                                <TabPanel title={t(Strings.generalDetails)}>
                                    <Box pt={3}>
                                        <VerticalGridLayout spacing={2}>

                                            <Typography variant={"h6"}>
                                                <strong>{t(Strings.personalDetails)}</strong>
                                            </Typography>

                                            <Typography variant={"body1"}>
                                                <strong>{t(Strings.fullName)}: </strong>
                                                {state.user.name}
                                            </Typography>

                                            <Typography variant={"body1"}>
                                                <strong>{t(Strings.emailAddress)}: </strong>
                                                {state.user.email}
                                            </Typography>

                                            {
                                                state.user.phone &&
                                                <Typography variant={"body1"}>
                                                    <strong>{t(Strings.phoneNumber)}: </strong>
                                                    {state.user.phone}
                                                </Typography>
                                            }

                                        </VerticalGridLayout>
                                    </Box>
                                </TabPanel>

                                <TabPanel title={t(Strings.resetPassword)}>
                                    <Box pt={3}>
                                        <ResetPasswordControl/>
                                    </Box>
                                </TabPanel>
                            </TabsControl>
                        </Box>
                    </Paper>
                </VerticalGridLayout>
            </Container>
        </>);
    }
};

export default ProfilePage;