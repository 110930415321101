import UserRole from "models/enums/UserRole";
import JwtObject from "util/JwtObject";
import AuthTokenManager from "./tokens/AuthTokenManager";

class UserContext {

    isAuthenticated: boolean = false;
    isAdmin: boolean = false;
    isAppAdmin: boolean = false;
    isSuperAdmin: boolean = false;
    userRole: UserRole = UserRole.USER;
    cognitoSub: string = "";
    cognitoUsername: string = "";
    username: string = "";
    email: string = "";
    name: string = "";
    phone: string = "";
    claims: Record<string, string> = {};
    shopId: string = "";

    constructor(token?: AuthTokenManager) {

        this.isAuthenticated = Boolean(token && token.idToken && token.accessToken && token.refreshToken);

        if (this.isAuthenticated) {
            const jwt = new JwtObject(token!.idToken);

            if (jwt.payload) {
                const payload = jwt.payload;

                this.cognitoSub = payload["sub"];
                this.cognitoUsername = payload["cognito:username"];
                this.username = this.email = payload["email"];
                this.name = payload["name"];
                this.phone = payload["phone_number"];

                if (payload["cognito:groups"]) {
                    const groups: string[] = payload["cognito:groups"];
                    if (groups.length) {
                        const firstGroup = groups[0];

                        if (firstGroup === "admin") {
                            this.userRole = UserRole.SUPER_ADMIN;
                        } else if (firstGroup.startsWith("app")) {
                            this.userRole = UserRole.APP_ADMIN;
                        } else if (firstGroup.startsWith("shop")) {
                            this.userRole = UserRole.SHOP_ADMIN;
                            this.shopId = firstGroup.split("/")[1];
                        } else {
                            this.userRole = UserRole.USER;
                        }
                    }

                    this.isSuperAdmin = this.userRole === UserRole.SUPER_ADMIN;
                    this.isAppAdmin = this.isSuperAdmin || this.userRole === UserRole.APP_ADMIN;
                    this.isAdmin = this.userRole in [UserRole.SUPER_ADMIN, UserRole.APP_ADMIN, UserRole.SHOP_ADMIN];
                }

                if (payload["custom:claims"]) {
                    const claims = payload["custom:claims"].split('&');
                    this.claims = Object.assign({}, ...claims.map(function (claimString: string) {
                        const claim = claimString.split('=');
                        return {[claim[0]]: claim[1]};
                    }));
                }
            }
        }

    }

    hasValidClaim(claimId: string) {
        if (this.claims && this.claims[claimId] && this.claims[claimId].length === 6) {
            const claim = this.claims[claimId];
            // TODO: If you're still here by 2100, please fix this!
            const dateFormatter = `20${claim.substring(0, 2)}/${claim.substring(2, 4)}/${claim.substring(4, 6)}`;
            const date = new Date(dateFormatter);
            return date < new Date();
        }
        return false;
    }

}

export default UserContext;