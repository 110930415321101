import React from "react";
import Typography from "@material-ui/core/Typography";
import appSettings from "appSettings";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {`© ${new Date().getFullYear()} ${appSettings.appName}. All rights reserved.`}
        </Typography>
    );
}

export default Copyright;