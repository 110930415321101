import React, {FC} from "react";
import AppBar from "components/controls/common/AppBar";
import AuthManager from "util/AuthManager";
import {Divider, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Link} from "react-router-dom";
import {
    Dashboard,
    ExitToApp,
    Person,
    Store,
    PermContactCalendar,
    Business,
    PeopleAlt, MonetizationOn
} from "@material-ui/icons";
import ListIcon from "@material-ui/icons/List";
import {
    shopPath,
    adminDashboardPath,
    categoryIndexPath,
    productIndexPath, lookupIndexPath, shopAdminIndexPath, productExtraIndexPath
} from "admin/routers/PathBuilder";
import {profilePath} from "router/PathBuilder";

const AdminAppBar: FC = () => {
    const drawerList = (<>
        <List>
            <ListItem button component={Link} to={adminDashboardPath()}>
                <ListItemIcon><Dashboard/></ListItemIcon>
                <ListItemText primary={"Dashboard"}/>
            </ListItem>

            {
                AuthManager.user.isSuperAdmin &&
                <ListItem button component={Link} to={shopPath(AuthManager.user.shopId)}>
                    <ListItemIcon><Store/></ListItemIcon>
                    <ListItemText primary={"Account"}/>
                </ListItem>
            }

            <ListItem button component={Link} to={categoryIndexPath(AuthManager.user.shopId)}>
                <ListItemIcon><PermContactCalendar/></ListItemIcon>
                <ListItemText primary={"Contacts"}/>
            </ListItem>

            <ListItem button component={Link} to={productIndexPath(AuthManager.user.shopId)}>
                <ListItemIcon><Business/></ListItemIcon>
                <ListItemText primary={"Accounts"}/>
            </ListItem>

            <ListItem button component={Link} to={productExtraIndexPath(AuthManager.user.shopId)}>
                <ListItemIcon><MonetizationOn/></ListItemIcon>
                <ListItemText primary={"Deals"}/>
            </ListItem>

            {
                AuthManager.user.isAppAdmin &&
                <ListItem button component={Link} to={lookupIndexPath()}>
                    <ListItemIcon><ListIcon/></ListItemIcon>
                    <ListItemText primary={"Lookups"}/>
                </ListItem>
            }

            {
                AuthManager.user.isAppAdmin &&
                <ListItem button component={Link} to={shopAdminIndexPath(AuthManager.user.shopId)}>
                    <ListItemIcon><PeopleAlt/></ListItemIcon>
                    <ListItemText primary={"Users"}/>
                </ListItem>
            }
        </List>

        <Divider/>

        <List>
            <ListItem button component={Link} to={profilePath()}>
                <ListItemIcon><Person/></ListItemIcon>
                <ListItemText primary="Profile"/>
            </ListItem>

            <ListItem
                button
                onClick={() => {
                    AuthManager.clear();
                    window.location.reload();
                }}>
                <ListItemIcon><ExitToApp/></ListItemIcon>
                <ListItemText primary="Logout"/>
            </ListItem>
        </List>
    </>);

    return (
        <AppBar
            color={"inherit"}
            drawerList={drawerList}/>
    )
}

export default AdminAppBar;