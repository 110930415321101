import config from "util/config";
import CryptoProvider from "./CryptoProvider";

export default class SecurityHelper {

    static getUserSignature(username) {
        if (!this.signature || (this.username && this.username !== username)) {
            this.username = username;
            this.signature = CryptoProvider.getHashedBase64(username + config.identity.clientId);
        }
        return this.signature;
    }

};