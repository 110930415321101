import React, {CSSProperties, FC} from "react";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import './WaveLoading.scss';
import {Box} from "@material-ui/core";

interface Props {
    size?: "small" | "medium";
}

const WaveLoading: FC<Props> = (props: Props) => {
    const size = props.size === "small" ? 8 : 20;
    const style: CSSProperties = {
        width: size,
        height: size,
        margin: 8
    };

    return (
        <div className="wave-loading">
            <div className="item" style={style}/>
            <div className="item" style={style}/>
            <div className="item" style={style}/>
            <div className="item" style={style}/>
        </div>
    );
}

export const WaveLoadingPage: FC = () => {
    return (
        <VerticalGridLayout
            justify={"center"}
            alignContent={"center"}
            style={{height: "100%"}}>
            <Box py={8}>
                <WaveLoading/>
            </Box>
        </VerticalGridLayout>
    );
}

export default WaveLoading;