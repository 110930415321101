import React from 'react'
import {CircularProgress, Container} from "@material-ui/core";
import {VerticalGridLayout} from "../controls/helpers/GridLayout";

class Loading extends React.Component {
    render() {
        return (
            <Container>
                <VerticalGridLayout justify={"center"} alignContent={"center"}>
                    <div style={{padding: "30vh 0"}}>
                        <CircularProgress color="secondary"/>
                    </div>
                </VerticalGridLayout>
            </Container>
        );
    }
}

export default Loading;