import React, {ReactNode, useState} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    Avatar,
    Box,
    Button,
    Divider,
    Hidden,
    ListItemIcon,
    ListItemText, SwipeableDrawer,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import appSettings from "appSettings";
import {
    ArrowBack,
    Dashboard, ExitToApp,
    Person,
} from "@material-ui/icons";
import AuthManager from "util/AuthManager";
import {Typography} from "components/controls/helpers/Typography";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";
import {loginPath, profilePath} from "router/PathBuilder";
import {adminDashboardPath} from "admin/routers/PathBuilder";

interface Props {
    drawerList: ReactNode;
    toolbarMenu?: ReactNode;
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            boxShadow: "none"
        },
        grow: {
            flexGrow: 1
        },
        menuIcon: {
            marginRight: theme.spacing(2),
        },
        accountMenu: {
            minWidth: '240px',
            padding: 0
        },
        avatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            backgroundColor: theme.palette.secondary.main,
            fontSize: 16
        },
        drawerList: {
            width: 300,
        },
        searchView: {
            padding: "0 16px"
        }
    }),
);

export default function AppBar(props: Props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const [isSearchShown, setIsSearchShown] = useState(false);

    const isAccountMenuOpen = Boolean(anchorEl);
    const toggleAccountMenu = (open: boolean) => (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(open ? event.currentTarget : null);
    };

    const toggleDrawer = (open: boolean) => () => {
        setIsDrawerOpen(open);
    };

    // const toggleSearchView = () => {
    //     setIsSearchShown(!isSearchShown);
    // };

    const accountMenuView = (
        <Menu
            id={'appbar-account-menu'}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isAccountMenuOpen}
            onClose={toggleAccountMenu(false)}>

            <div className={classes.accountMenu}>
                {AuthManager.user.isAuthenticated ?
                    <>
                        <Box py={1} px={2}>
                            <Typography variant={"body2"}>
                                <strong>{t(Strings.welcome)}</strong>
                            </Typography>
                            <Typography variant={"body2"}>
                                {AuthManager.user.name}
                            </Typography>
                        </Box>

                        <Box pb={1}>
                            <Divider/>
                        </Box>

                        {
                            AuthManager.user.isAdmin &&
                            <MenuItem
                                component={Link}
                                to={adminDashboardPath()}
                                onClick={toggleAccountMenu(false)}>
                                <ListItemIcon>
                                    <Dashboard/>
                                </ListItemIcon>
                                <ListItemText primary={t(Strings.dashboard)}/>
                            </MenuItem>
                        }

                        <MenuItem
                            component={Link}
                            to={profilePath()}
                            onClick={toggleAccountMenu(false)}>
                            <ListItemIcon><Person/></ListItemIcon>
                            <ListItemText primary={t(Strings.profile)}/>
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                AuthManager.clear();
                                window.location.reload();
                            }}>
                            <ListItemIcon><ExitToApp/></ListItemIcon>
                            <ListItemText primary={t(Strings.logout)}/>
                        </MenuItem>
                    </>
                    :
                    <Box py={1} px={2}>
                        <VerticalGridLayout spacing={2}>
                            <Button
                                component={Link}
                                to={loginPath()}
                                fullWidth
                                variant="contained"
                                size={"large"}
                                color="primary"
                                onClick={toggleAccountMenu(false)}>
                                {t(Strings.login)}
                            </Button>
                        </VerticalGridLayout>
                    </Box>
                }
            </div>
        </Menu>
    );

    const drawerView = (
        <div
            className={classes.drawerList}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>

            <Box px={2} pt={1}>
                <IconButton
                    edge="start"
                    className={classes.menuIcon}
                    color="inherit"
                    onClick={toggleDrawer(true)}>
                    <ArrowBack/>
                </IconButton>

                <Link to={"/"} className={classes.grow}>
                    <img
                        alt={`${appSettings.appName} logo`} src={"/navbar-logo.png"}
                        style={{maxWidth: 150, maxHeight: 40, objectFit: "contain"}}/>
                </Link>
            </Box>

            {props.drawerList}
        </div>
    );

    return (<>
        <MuiAppBar
            className={classes.root}
            color={props.color ?? "transparent"}
            position="static">

            <Toolbar>
                <IconButton
                    edge="start"
                    className={classes.menuIcon}
                    color="inherit"
                    onClick={toggleDrawer(true)}>
                    <MenuIcon/>
                </IconButton>

                <Link to={"/"} className={classes.grow}>
                    <img
                        alt={`${appSettings.appName} logo`} src={"/navbar-logo.png"}
                        style={{maxWidth: 150, maxHeight: 40, objectFit: "contain"}}/>
                </Link>

                {
                    Boolean(props.toolbarMenu) &&
                    <Hidden smDown>
                        {props.toolbarMenu}
                    </Hidden>
                }

                {/*<IconButton*/}
                {/*    color="inherit"*/}
                {/*    onClick={toggleSearchView}>*/}
                {/*    <SearchIcon/>*/}
                {/*</IconButton>*/}

                <Hidden smDown>
                    <IconButton
                        edge="end"
                        onClick={toggleAccountMenu(true)}
                        color="inherit">
                        {AuthManager.user.isAuthenticated ?
                            <Avatar className={classes.avatar}>
                                {AuthManager.user.name?.charAt(0)}
                            </Avatar>
                            :
                            <AccountCircle/>
                        }
                    </IconButton>
                </Hidden>

            </Toolbar>

            {/*<Collapse in={isSearchShown} timeout="auto" unmountOnExit>*/}
            {/*    <CardContent className={classes.searchView}>*/}
            {/*        <form action={searchProductPath("")}>*/}
            {/*            <HorizontalGridLayout*/}
            {/*                spacing={2}*/}
            {/*                wrap={"nowrap"}*/}
            {/*                alignItems={"center"}>*/}
            {/*                <Grid item className={classes.grow}>*/}
            {/*                    <TextField*/}
            {/*                        label={t(Strings.search)}*/}
            {/*                        placeholder={t(Strings.searchFor)}*/}
            {/*                        name={"term"}*/}
            {/*                        autoFocus={isSearchShown}*/}
            {/*                        variant="outlined"*/}
            {/*                        size={"small"}*/}
            {/*                        fullWidth/>*/}
            {/*                </Grid>*/}

            {/*                <Button*/}
            {/*                    type={"submit"}*/}
            {/*                    color={"primary"}*/}
            {/*                    variant={"contained"}>*/}
            {/*                    {t(Strings.search)}*/}
            {/*                </Button>*/}
            {/*            </HorizontalGridLayout>*/}
            {/*        </form>*/}
            {/*    </CardContent>*/}
            {/*</Collapse>*/}

        </MuiAppBar>

        {accountMenuView}

        <SwipeableDrawer
            anchor={'left'}
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}>
            {drawerView}
        </SwipeableDrawer>
    </>);
}