import React, {FC} from 'react';
import Category, {categoryFields} from 'models/Category';
import Api from 'util/api';
import {useHistory} from 'react-router-dom';
import MessagingManager from 'util/MessagingManager';
import {Box, Container, Paper} from '@material-ui/core';
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {categoryPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";
import UpsertCustomFieldsControl from "admin/components/UpsertCustomFieldsControl";

type Props = {
    shopId: string;
}

const CreatePage: FC<Props> = (props) => {
    const history = useHistory();

    async function createCategory(category: Partial<Category>) {
        category.shopId = props.shopId;

        return await Api.category.admin.createCategoryAsync(category)
            .then((data) => {
                const d = data as Category;
                MessagingManager.toast({
                    message: `Created successfully!`,
                    type: "success"
                });

                history.push(categoryPath(d.id, props.shopId));
                return true;
            })
            .catch(() => false);
    }

    return (<>
        <HtmlMetaDecorator title={"Create Contact"}/>

        <Container>
            <Paper>
                <Box p={2}>
                    <VerticalGridLayout spacing={4}>
                        <Typography variant={"h6"}>
                            <strong>New Contact</strong>
                        </Typography>

                        <UpsertCustomFieldsControl
                            fields={categoryFields}
                            actionText="Create"
                            onSubmit={async c => await createCategory(c)}/>
                    </VerticalGridLayout>
                </Box>
            </Paper>
        </Container>
    </>);
}

export default CreatePage;