const TokenKey = {
    ID_TOKEN: "IdToken",
    ACCESS_TOKEN: "AccessToken",
    REFRESH_TOKEN: "RefreshToken",
    TOKEN_EXPIRES_AT: "TokenExpiresAt",
    DEVICE_KEY: "DeviceKey"
};

export default class AuthTokenManager {

    constructor(token, saveToken = true) {
        if (token) {
            this.idToken = token.idToken;
            this.accessToken = token.accessToken;
            this.refreshToken = token.refreshToken;
            this.expiresAt = Date.now() + (token.expiresIn * 1000);

            if (token.device) {
                this.deviceKey = token.device.key;
            }

            if (saveToken) {
                this.saveToken();
            }
        } else {
            this.idToken = localStorage.getItem(TokenKey.ID_TOKEN);
            this.accessToken = localStorage.getItem(TokenKey.ACCESS_TOKEN);
            this.refreshToken = localStorage.getItem(TokenKey.REFRESH_TOKEN);
            this.expiresAt = localStorage.getItem(TokenKey.TOKEN_EXPIRES_AT);
            this.deviceKey = localStorage.getItem(TokenKey.DEVICE_KEY);
        }
    }

    get isExpired() {
        if (this.idToken && this.accessToken && this.refreshToken && this.expiresAt) {
            return this.expiresAt < Date.now();
        }
        return false;
    }

    saveToken() {
        localStorage.setItem(TokenKey.ID_TOKEN, this.idToken);
        localStorage.setItem(TokenKey.ACCESS_TOKEN, this.accessToken);
        localStorage.setItem(TokenKey.REFRESH_TOKEN, this.refreshToken);
        localStorage.setItem(TokenKey.TOKEN_EXPIRES_AT, this.expiresAt);

        if (this.device) {
            localStorage.setItem(TokenKey.DEVICE_KEY, this.deviceKey);
        }
    }

    clearToken() {
        for (const key in TokenKey) {
            localStorage.removeItem(TokenKey[key]);
        }
    }

};