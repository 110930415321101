import React, {FC} from 'react';
import User from 'models/User';
import Api from 'util/api';
import {useHistory} from 'react-router-dom';
import MessagingManager from 'util/MessagingManager';
import {Container} from '@material-ui/core';
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import UpsertControl from "admin/components/user/UpsertControl";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import Panel from "components/controls/common/Panel";
import {shopAdminIndexPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";

interface Props {
    shopId: string;
}

const CreatePage: FC<Props> = (props) => {
    const history = useHistory();

    async function createUser(user: Partial<User>) {
        user.shopId = props.shopId;

        await Api.user.admin.createShopAdminUsersAsync(user)
            .then(() => {
                MessagingManager.toast({
                    message: `'${user.name}' was created successfully!`,
                    type: "success"
                });
                history.push(shopAdminIndexPath(props.shopId));
            });
    }

    return (<>
        <HtmlMetaDecorator
            title={"Create User"}/>

        <Container>
            <VerticalGridLayout spacing={2}>
                <Typography variant={"h6"}>
                    <strong>Create New User</strong>
                </Typography>

                <Panel>
                    <UpsertControl
                        actionText="Create"
                        onSubmit={user => createUser(user)}/>
                </Panel>
            </VerticalGridLayout>
        </Container>
    </>);
}

export default CreatePage;