import terminologies from "terminologies";

const t = terminologies;

export const loginPath = () => `/${t.account}/${t.login}`;
export const forgotPasswordPath = () => `/${t.account}/${t.forgotPassword}`;
export const profilePath = () => `/${t.profile}`;

export const parseQuery = (query: string) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params: any, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};