import React, {FC, useEffect, useState} from 'react';
import Api from 'util/api';
import ApiResponseOptions from 'util/api/models/ApiResponseOptions';
import {useAppSettings} from "contexts/AppSettingsProvider";
import {Typography} from "components/controls/helpers/Typography";

const StatusPage: FC = () => {
    const appSettings = useAppSettings();
    const [data, setData] = useState();

    useEffect(() => {
        void async function fetchData() {
            await Api.getStatusAsync<any>(new ApiResponseOptions({returnResponse: true}))
                .then((data) => {
                    setData(data);
                }).catch(() => {
                });
        }();
    }, []);

    return (
        <div>
            <Typography variant={"h6"}>
                <strong>Status</strong>
            </Typography>
            <p>{JSON.stringify(data)}</p>
            <p>{JSON.stringify(appSettings)}</p>
            <p>
                <strong>Version: </strong>
                {appSettings.version}
            </p>
        </div>
    );
}

export default StatusPage;