import React from 'react'
import MUIDataTable, {MUIDataTableOptions, MUIDataTableColumnDef} from 'mui-datatables';
import {createTheme, IconButton, MuiThemeProvider} from '@material-ui/core';
import Product, {productFields} from 'models/Product';
import Api from 'util/api';
import update from 'immutability-helper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
    currentProducts: Product[],
    categoryId: string;
    shopId: string;
    onAddAsync: (product: Product) => Promise<boolean>;
    onRemoveAsync: (product: Product) => Promise<boolean>;
}

interface State {
    items?: ListItem[];
}

interface ListItem {
    product: Product;
    isAdded: boolean;
}

const theme = createTheme({
    overrides: {
        MuiTableHead: {
            root: {
                display: "none"
            }
        },
        MuiTableRow: {
            root: {
                padding: "10px"
            }
        },
        MuiTableCell: {
            body: {
                padding: "10px"
            }
        },
    }
});

class ProductPicker extends React.Component<Props, State> {

    readonly state: State = {};

    private readonly options: MUIDataTableOptions = {
        elevation: 0,
        selectableRows: "none",
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        pagination: false,
        responsive: 'standard'
    };

    async componentDidMount() {
        await Api.product.admin.getProductsAsync(this.props.shopId)
            .then((data) => {
                const items = (data as Product[]).map<ListItem>(p => ({
                    product: p,
                    name: p.name,
                    isAdded: this.props.currentProducts.some(p1 => p1.id === p.id)
                }));

                this.setState({items: items});
            });
    }

    async onAddButtonClickedAsync(rowIndex: number): Promise<void> {
        const item = this.state.items![rowIndex];

        if (await this.props.onAddAsync(item.product)) {
            this.updateItemState(rowIndex);
        }
    }

    async onRemoveButtonClickedAsync(rowIndex: number): Promise<void> {
        const item = this.state.items![rowIndex];
        if (await this.props.onRemoveAsync(item.product)) {
            this.updateItemState(rowIndex);
        }
    }

    private updateItemState(rowIndex: number) {
        const newState = {
            items: update(this.state.items, {
                [rowIndex]: {isAdded: {$set: !this.state.items![rowIndex].isAdded}}
            })
        };

        this.setState(newState);
    }

    render() {
        const items = this.state.items ?? [];

        const tableColumns: MUIDataTableColumnDef[] = productFields
            .map(f => ({
                name: f.name,
                label: f.label,
                options: {
                    display: f.settings?.indexed,
                    filter: f.settings?.filterable,
                    searchable: f.settings?.searchable,
                    customBodyRender: (value, tableMeta) => {
                        if (this.state.items) {
                            const attributes = this.state.items[tableMeta.rowIndex]?.product?.customAttributes;
                            return attributes ? attributes[f.name] : "";
                        }
                    }
                }
            }));

        tableColumns.push({
            name: "isAdded",
            label: "Action",
            options: {
                customBodyRender: (isAdded: boolean, tableMeta) => {
                    return (<>
                        {isAdded ?
                            <IconButton
                                size={"small"}
                                style={{float: "right"}}
                                onClick={async () => await this.onRemoveButtonClickedAsync(tableMeta.rowIndex)}>
                                <ClearIcon/>
                            </IconButton>
                            :
                            <IconButton
                                color={"primary"}
                                size={"small"}
                                style={{float: "right"}}
                                onClick={async () => await this.onAddButtonClickedAsync(tableMeta.rowIndex)}>
                                <AddCircleIcon/>
                            </IconButton>
                        }
                    </>);
                }
            }
        });

        return (<>
            {items.length > 0 ?
                <MuiThemeProvider theme={theme}>

                    <MUIDataTable
                        title=""
                        data={items}
                        columns={tableColumns}
                        options={this.options}/>

                </MuiThemeProvider>
                :
                <p style={{margin: 0, fontSize: 12, textAlign: "center"}}>No products available yet!</p>
            }
        </>);
    }

}

export default ProductPicker;