import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid} from "@material-ui/core";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {useAppCache} from "contexts/AppCacheProvider";
import {HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import {Typography} from "components/controls/helpers/Typography";
import AddIcon from "@material-ui/icons/Add";
import LookupListItem from "admin/components/lookup/LookupListItem";
import Modal from "components/controls/Modal";
import {Field, Form, Formik} from "formik";
import CustomInputComponent from "components/controls/helpers/CustomInputComponent";
import * as Yup from "yup";

const IndexPage: React.FC = () => {
    const cache = useAppCache();
    const lookups = Object.keys(cache.lookups);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const toggleCreateModal = () => setShowCreateModal(prevState => !prevState);

    useEffect(() => {
    }, [cache.lookups])

    return (<>
        <HtmlMetaDecorator title={"Lookups"}/>

        <Container>
            <VerticalGridLayout spacing={2}>
                <HorizontalGridLayout>
                    <Grid item style={{flexGrow: 1}}>
                        <Typography variant={"h6"}>
                            <strong>Lookups</strong>
                        </Typography>
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon/>}
                        onClick={toggleCreateModal}>
                        New Lookup
                    </Button>
                </HorizontalGridLayout>

                {lookups.length ?
                    lookups.map((key) => (
                        <LookupListItem key={key} id={key}/>
                    ))
                    :
                    <Box pt={3}>
                        <Typography align={"center"} variant={"body1"}>
                            No lookup added yet!
                        </Typography>
                    </Box>
                }
            </VerticalGridLayout>
        </Container>

        <Modal
            title={`Create new lookup`}
            open={showCreateModal}
            toggleModal={toggleCreateModal}>
            <Formik
                enableReinitialize={true}
                initialValues={{id: ""}}
                validationSchema={Yup.object().shape({id: Yup.string().required('Key is required')})}
                onSubmit={(values, {setErrors}) => {
                    if (lookups.find(k => k === values.id)) {
                        setErrors({id: "This Key already exists!"});
                        return;
                    }
                    cache.addLookup(values.id.trim());
                    toggleCreateModal();
                }}>
                <Form>
                    <VerticalGridLayout wrap={"nowrap"} spacing={2}>
                        <Field
                            name="id"
                            label="Lookup Key"
                            component={CustomInputComponent}/>

                        <Button
                            fullWidth
                            type="submit"
                            size={"large"}
                            variant="contained"
                            color="primary">Create</Button>
                    </VerticalGridLayout>
                </Form>
            </Formik>
        </Modal>
    </>);
}

export default IndexPage;