import React, {createContext, FC, useContext, useEffect, useState} from "react";
import Shop from "models/Shop";
import Api from "util/api/Api";
import Product from "models/Product";
import AuthManager from "util/AuthManager";
import {useAppSettings} from "contexts/AppSettingsProvider";
import Lookup from "models/Lookup";
import {useAuthentication} from "contexts/AuthenticationProvider";
import {default as defaultAppSettings} from "appSettings";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";

interface AppCacheData {
    shops: Shop[];
    lookups: Record<string, Lookup[]>
    addLookup: (id: string) => void;
    addLookupItem: (id: string, item: Lookup) => void;
    updateLookupItem: (id: string, item: Lookup) => void;
    removeLookupItem: (id: string, itemId: string) => void;
    subscribedProducts: Product[];
    subscribeProduct: (product: Product) => void;
    unsubscribeProduct: (product: Product) => void;
    locale: string;
    setLocale: (locale: string) => void;
}

const defaultCache: AppCacheData = {
    shops: [],
    lookups: {},
    subscribedProducts: [],
    addLookup: () => {
    },
    addLookupItem: () => {
    },
    updateLookupItem: () => {
    },
    removeLookupItem: () => {
    },
    subscribeProduct: () => {
    },
    unsubscribeProduct: () => {
    },
    locale: 'en',
    setLocale: () => {
    }
}

export const AppCacheContext = createContext<AppCacheData>(defaultCache);

export const useAppCache = () => useContext<AppCacheData>(AppCacheContext);

// TODO: Store in local storage. Use eTag to invalidate/update the cache.
const AppCacheProvider: FC = (props) => {
    const auth = useAuthentication();
    const appSettings = useAppSettings();

    defaultCache.locale = localStorage.getItem("i18nextLng") ||
        appSettings.defaultLocale || 'en';

    defaultCache.subscribeProduct = (product: Product) => {
        setCache(ps => ({
            ...ps,
            subscribedProducts: [...ps.subscribedProducts, product]
        }))
    }

    defaultCache.addLookup = (id: string) => {
        setCache(ps => {
            const newLookups = ps.lookups;
            newLookups[id] = [];
            return ({...ps, lookups: newLookups})
        })
    }

    defaultCache.addLookupItem = (id: string, item: Lookup) => {
        setCache(ps => {
            const newLookups = ps.lookups;
            newLookups[id] = [...newLookups[id], item];
            return ({...ps, lookups: newLookups})
        })
    }

    defaultCache.updateLookupItem = (id: string, item: Lookup) => {
        setCache(ps => {
            const newLookups = ps.lookups;
            newLookups[id] = newLookups[id].map(l => l.id === item.id ? item : l);
            return ({...ps, lookups: newLookups})
        })
    }

    defaultCache.removeLookupItem = (id: string, itemId: string) => {
        setCache(ps => {
            const newLookups = ps.lookups;
            newLookups[id] = newLookups[id].filter(l => l.id !== itemId);
            return ({...ps, lookups: newLookups})
        })
    }

    defaultCache.unsubscribeProduct = (product: Product) => {
        setCache(ps => ({
            ...ps,
            subscribedProducts: ps.subscribedProducts.filter(p => p.id !== product.id)
        }))
    }

    defaultCache.setLocale = (locale: string) => {
        setCache(ps => ({...ps, locale: locale}));
    }

    const [cache, setCache] = useState<AppCacheData>(defaultCache);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        void async function fetchData() {
            if ((!defaultAppSettings.authRequire || auth.isAuthenticated) && !isLoaded) {
                setIsLoading(true);
                setIsLoaded(true);

                const data = await Api.shop.getShopsAsync() as Shop[];
                setCache(prevState => ({...prevState, shops: data || []}));

                const lookups = await Api.lookup.getLookupsAsync() as Record<string, any>;
                setCache(prevState => ({...prevState, lookups: lookups.lookups || {}}));

                if (auth.isAuthenticated && !AuthManager.user.isAdmin) {
                    const data = await Api.product.getSubscriptionsAsync() as Product[];
                    setCache(prevState => ({...prevState, subscribedProducts: data || []}));
                }

                setIsLoading(false)
            }
        }();
    }, [isLoaded, auth.isAuthenticated]);

    if (isLoading) {
        return (<WaveLoadingPage/>)
    } else {
        return (
            <AppCacheContext.Provider value={cache}>
                {props.children}
            </AppCacheContext.Provider>
        );
    }
}

export default AppCacheProvider;