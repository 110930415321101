import React, {CSSProperties, ReactElement} from "react";
import {Grid} from "@material-ui/core";
import {
    GridContentAlignment,
    GridDirection,
    GridItemsAlignment,
    GridJustification, GridSize,
    GridSpacing, GridWrap
} from "@material-ui/core/Grid/Grid";

interface Props {
    alignContent?: GridContentAlignment;
    alignItems?: GridItemsAlignment;
    container?: boolean;
    direction?: GridDirection;
    item?: boolean;
    justify?: GridJustification;
    spacing?: GridSpacing;
    wrap?: GridWrap;
    zeroMinWidth?: boolean;

    className?: string;
    style?: CSSProperties;

    lg?: boolean | GridSize;
    md?: boolean | GridSize;
    sm?: boolean | GridSize;
    xl?: boolean | GridSize;
    xs?: boolean | GridSize;
}

const GridLayout: React.FC<Props> = (props) => (
    <Grid {...props}>
        {renderChildren(props.children as ReactElement)}
    </Grid>
);

const GridItem: React.FC<Props> = (props) => (
    <Grid item {...props}>
        {props.children}
    </Grid>
);

// TODO: Handle Reverse automatically.
const VerticalGridLayout: React.FC<Props> = (props) => (
    <Grid
        {...props}
        container
        direction={props.direction ?? "column"}
        justify={props.justify ?? "flex-start"}
        alignItems={props.alignItems ?? "stretch"}
        spacing={props.spacing}>

        {renderChildren(props.children as ReactElement)}

    </Grid>
);

const HorizontalGridLayout: React.FC<Props> = (props) => (
    <Grid
        {...props}
        container
        direction={props.direction ?? "row"}
        justify={props.justify ?? "flex-start"}
        alignItems={props.alignItems ?? "stretch"}
        spacing={props.spacing}>

        {renderChildren(props.children as ReactElement)}

    </Grid>
);

function renderChildren(children?: ReactElement) {
    return React.Children.map(children, (child) => {
        if (!child || child.type === Grid || child.type === GridItem) {
            return (child);
        } else {
            return (
                <Grid item>
                    {child}
                </Grid>
            );
        }
    })
}

export {
    GridLayout,
    GridItem,
    VerticalGridLayout,
    HorizontalGridLayout,
};