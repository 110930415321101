const Strings = {
    home: "home",
    shops: "shops",
    profile: "profile",
    login: "login",
    logout: "logout",
    order: "order",
    orders: "orders",
    favourites: "favourites",
    dashboard: "dashboard",
    register: "register",
    registerNow: "registerNow",
    search: "search",
    searchFor: "searchFor",
    searchingFor: "searchingFor",
    about: "about",
    comment: "comment",
    additionalComment: "additionalComment",
    cart: "cart",
    addToCart: "addToCart",
    save: "save",
    popularCategories: "popularCategories",
    premiumShops: "premiumShops",
    notFound: "notFound",
    forgotPassword: "forgotPassword",
    emailAddress: "emailAddress",
    continue: "continue",
    resetPassword: "resetPassword",
    reset: "reset",
    code: "code",
    fullName: "fullName",
    password: "password",
    confirmPassword: "confirmPassword",
    emailConfirmation: "emailConfirmation",
    newPassword: "newPassword",
    currentPassword: "currentPassword",
    generalDetails: "generalDetails",
    phoneNumber: "phoneNumber",
    personalDetails: "personalDetails",
    browseByCategory: "browseByCategory",
    backToHome: "backToHome",
    count: "count",
    changeShoppingCart: "changeShoppingCart",
    clearCart: "clearCart",
    cartSummary: "cartSummary",
    total: "total",
    proceedToCheckout: "proceedToCheckout",
    cancel: "cancel",
    payment: "payment",
    purchaseAt: "purchaseAt",
    shippingDetails: "shippingDetails",
    isRequired: "isRequired",
    returnToCart: "returnToCart",
    proceedToPayment: "proceedToPayment",
    placeOrder: "placeOrder",
    paymentMethod: "paymentMethod",
    cashOnDeliveryPayment: "cashOnDeliveryPayment",
    cardOnDeliveryPayment: "cardOnDeliveryPayment",
    cardOnlinePayment: "cardOnlinePayment",
    checkout: "checkout",
    orderSummary: "orderSummary",
    subtotal: "subtotal",
    shipping: "shipping",
    qty: "qty",
    product: "product",
    price: "price",
    continueShopping: "continueShopping",
    orderNumber: "orderNumber",
    paymentDetails: "paymentDetails",
    transactionToken: "transactionToken",
    edit: "edit",
    delete: "delete",
    redirect: "redirect",
    inStock: "inStock",
    outOfStock: "outOfStock",
    moreInfo: "moreInfo",
    welcome: "welcome",
}

export default Strings;