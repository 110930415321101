import React, {FC, Suspense, useLayoutEffect} from 'react';
import 'App.scss';
import Router from "router/Router"
import {createTheme, darken, lighten, Theme, ThemeProvider} from '@material-ui/core/styles';
import HtmlMetaDecorator from "./components/HtmlMetaDecorator";
import Notifier from "./util/Notifier";
import './i18n';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {StylesProvider, jssPreset} from '@material-ui/core/styles';
import Loading from "components/layout/Loading";
import AppSettingsProvider, {useAppSettings} from "contexts/AppSettingsProvider";
import AppCacheProvider, {useAppCache} from "contexts/AppCacheProvider";
import i18n from "i18next";
import {CssBaseline} from "@material-ui/core";
import AuthenticationProvider from "contexts/AuthenticationProvider";

// TODO: Check all the other options available,like setup, attach, generateId, etc.
//  Styles could be defined in the JSS.create and attached to any tag based on id.
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const AppWrapper: FC = () => {
    const appSettings = useAppSettings();
    const cache = useAppCache();

    const bg = appSettings.colors.background ?? "#fff";
    let paperBg = appSettings.colors.secondaryBackground ?? appSettings.colors.background ?? "#fff";
    if (typeof paperBg === "number") {
        paperBg = paperBg >= 0 ? lighten(bg, paperBg) : darken(bg, paperBg * -1);
    }

    const muiTheme: Theme = createTheme({
        overrides: {
            MuiFormLabel: {
                asterisk: {
                    color: "#bf0000"
                }
            }
        },
        palette: {
            type: appSettings.colors.mode,
            background: {
                default: bg,
                paper: paperBg
            },
            primary: {
                main: appSettings.colors.primary,
            },
            secondary: {
                main: appSettings.colors.secondary,
            }
        }
    });

    useLayoutEffect(() => {
        void async function fetchData() {
            await i18n.changeLanguage(cache.locale);
            document.body.setAttribute("dir", cache.locale === "ar" ? "rtl" : "ltr");
        }();
        // NOTE: Adding multiple dependencies here will cause re-rendering the
        //  App on every single change on the cache properties.
    }, [cache.locale]);

    const googleAnalyticsScript = "https://www.googletagmanager.com/gtag/js?id=" +
        appSettings.googleAnalyticsCode;

    const setGoogleAnalytics = () => {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        function gtag(key: string, value: any) {
            // @ts-ignore
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', appSettings.googleAnalyticsCode);
    }

    return (
        <ThemeProvider theme={{...muiTheme, direction: cache.locale === "ar" ? "rtl" : "ltr"}}>
            <StylesProvider jss={jss}>
                <CssBaseline/>
                <Suspense fallback={<Loading/>}>
                    <div className="App" style={{textAlign: "start"}}>

                        <HtmlMetaDecorator title={""}>
                            <meta name="theme-color" content={appSettings.colors.primary}/>

                            <link
                                rel="stylesheet"
                                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>

                            {appSettings.isGoogleAnalyticEnabled && <script async src={googleAnalyticsScript}/>}
                            {appSettings.isGoogleAnalyticEnabled && setGoogleAnalytics()}
                        </HtmlMetaDecorator>

                        <Router/>

                        <Notifier/>
                    </div>
                </Suspense>
            </StylesProvider>
        </ThemeProvider>
    );
}

const App: FC = () => {
    return (
        <AuthenticationProvider>
            <AppSettingsProvider>
                <AppCacheProvider>
                    <AppWrapper/>
                </AppCacheProvider>
            </AppSettingsProvider>
        </AuthenticationProvider>
    );
}

export default App;
