import React, {FC} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import IndexPage from 'admin/pages/product/IndexPage';
import ExtrasIndexPage from 'admin/pages/product/extra/IndexPage';
import DetailsPage from 'admin/pages/product/DetailsPage';
import CreatePage from 'admin/pages/product/CreatePage';
import EditPage from 'admin/pages/product/EditPage';
import AuthManager from "util/AuthManager";
import {parseQuery} from "router/PathBuilder";
import terminologies from "terminologies";
import {useAppSettings} from "contexts/AppSettingsProvider";

const ProductRouter: FC = () => {
    const t = terminologies;
    const appSettings = useAppSettings();

    const isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    const getShopId = (params: any) => isMultiShop ?
        params[t.shopId] : appSettings.isSingleShop ?
            appSettings.singleShopId : AuthManager.user.shopId;

    return (
        <Switch>
            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.product}/${t.productExtra}`}
                   render={({match, location}) =>
                       <ExtrasIndexPage shopId={getShopId(match.params)}/>
                   }/>

            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.product}/create`}
                   render={({match, location}) =>
                       <CreatePage
                           shopId={getShopId(match.params)}
                           categoryId={parseQuery(location.search)?.[t.categoryId]}/>
                   }/>

            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.product}/:${t.productId}/edit`}
                   render={({match}) =>
                       <EditPage shopId={getShopId(match.params)} productId={match.params[t.productId]}/>
                   }/>

            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.product}/:${t.productId}`}
                   render={({match}) =>
                       <DetailsPage shopId={getShopId(match.params)} productId={match.params[t.productId]}/>
                   }/>

            <Route exact path={`/(${t.shop})?/:${t.shopId}?/${t.product}`}
                   render={({match}) => <IndexPage shopId={getShopId(match.params)}/>}/>

            <Redirect to="/404"/>
        </Switch>
    );
}

export default ProductRouter;