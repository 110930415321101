import config from "util/config";
import SecurityHelper from "util/security/SecurityHelper";

class UserIdentity {

    username: string;
    clientId: string = config.identity.clientId;
    signature: string;

    constructor(username: string) {
        this.username = username;
        this.signature = SecurityHelper.getUserSignature(username)
    }

}

export default UserIdentity;