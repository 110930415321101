import React, {FC, FormEvent, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";
import {GridItem, HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import CustomInputComponent, {LookupFieldComponent} from "components/controls/helpers/CustomInputComponent";
import {Box, Button, CircularProgress, IconButton} from "@material-ui/core";
import Model from "models/Model";
import CustomField from "models/CustomField";
import {Typography} from "components/controls/helpers/Typography";
import {CloseOutlined} from "@material-ui/icons";
import Media from "models/Media";
import AddIcon from "@material-ui/icons/Add";
import appSettings from "appSettings";
import MessagingManager from "util/MessagingManager";
import UploadHelper from "util/UploadHelper";

interface Props {
    fields: CustomField[];
    actionText: string;
    onSubmit: (model: Partial<Model>) => Promise<boolean>;
    model?: Partial<Model>;
}

const UpsertCustomFieldsControl: FC<Props> = (props: Props) => {
    const model = props.model ?? {} as Model;

    const [isUploading, setIsUploading] = useState(false);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={model.customAttributes ?? {}}
            onSubmit={async values => {
                let uploadSuccess = true;
                setIsUploading(true);

                for (const attFields of props.fields.filter(f => f.type === "attachments")) {
                    if (values[attFields.name]) {
                        // NOTE: Just to make sure the attachment is not empty.
                        values[attFields.name] = values[attFields.name].filter((a: Media) => a.name || a.url || a.file);

                        for (const media of values[attFields.name] as Media[]) {
                            if (media.file) {
                                uploadSuccess = await new UploadHelper()
                                    .upload("attachment", media.file, appSettings.singleShopId)
                                    .then((url) => {
                                        media.url = url;
                                        media.file = undefined;
                                        return true;
                                    })
                                    // TODO: Show which file failed to upload and how the reason.
                                    .catch(() => false);
                            }
                        }
                    }
                }

                if (uploadSuccess) {
                    model.customAttributes = values;
                    if (!await props.onSubmit(model)) {
                        setIsUploading(false);
                    }
                } else {
                    MessagingManager.toast({
                        message: "Unable to upload some files!",
                        type: "error"
                    });
                    setIsUploading(false);
                }
            }}>
            {({values, setFieldValue}) => (
                <Form>
                    <VerticalGridLayout spacing={4}>
                        <HorizontalGridLayout spacing={2}>
                            {props.fields.filter(f => f.type !== "attachments").map(f =>
                                <GridItem key={f.name} xs={12} md={f.settings?.multiline ? 12 : 6}>
                                    {f.type === "lookup" ?
                                        <Field
                                            name={f.name}
                                            label={f.label}
                                            lookup={f.values}
                                            component={LookupFieldComponent}/>
                                        :
                                        <Field
                                            name={f.name}
                                            label={f.label}
                                            type={f.type}
                                            multiline={f.settings?.multiline}
                                            component={CustomInputComponent}/>
                                    }
                                </GridItem>
                            )}
                        </HorizontalGridLayout>

                        {props.fields.filter(f => f.type === "attachments").map(f => {
                            return (<>
                                <Box pb={2}>
                                    <Typography variant={"h6"}>
                                        <strong>{f.label}</strong>
                                    </Typography>
                                </Box>
                                <FieldArray name={f.name}>
                                    {({remove, push}) => (
                                        <VerticalGridLayout spacing={4}>
                                            {values[f.name]?.map((m: Media, i: number) => (
                                                <HorizontalGridLayout key={i} spacing={2} alignItems={"center"}>
                                                    <IconButton size={"small"} onClick={() => remove(i)}>
                                                        <CloseOutlined/>
                                                    </IconButton>
                                                    <GridItem style={{flexGrow: 1}}>
                                                        <Field
                                                            name={`${f.name}.${i}.name`}
                                                            label={"Name"}
                                                            component={CustomInputComponent}/>
                                                    </GridItem>
                                                    <GridItem style={{flexGrow: 1}}>
                                                        <input
                                                            type="file"
                                                            style={{width: "100%"}}
                                                            onChange={(event: FormEvent<HTMLInputElement>) => {
                                                                if (event.currentTarget.files?.length) {
                                                                    setFieldValue(`${f.name}.${i}.file`,
                                                                        event.currentTarget.files[0]);
                                                                }
                                                            }}/>
                                                    </GridItem>
                                                </HorizontalGridLayout>
                                            ))}

                                            <Button
                                                variant={"outlined"}
                                                color={"primary"}
                                                size={"large"}
                                                fullWidth
                                                onClick={() => push({})}
                                                startIcon={<AddIcon/>}>Add</Button>
                                        </VerticalGridLayout>
                                    )}
                                </FieldArray>
                            </>);
                        })}

                        <Button
                            type="submit"
                            size={"large"}
                            variant="contained"
                            disabled={isUploading}
                            color={isUploading ? "default" : "primary"}>
                            {isUploading ?
                                <CircularProgress color={"primary"} size={24}/>
                                : props.actionText}
                        </Button>
                    </VerticalGridLayout>
                </Form>
            )}
        </Formik>
    );
}

export default UpsertCustomFieldsControl;