import React, {useState} from "react";
import {FieldProps} from "formik";
import {
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import CustomInputComponent from "components/controls/helpers/CustomInputComponent";

interface Props extends FieldProps {
}

const PasswordInput: React.FC<Props> = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClick = () => {
        setShowPassword(prev => !prev);
    }

    return (
        <CustomInputComponent
            {...props}
            required
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClick}>
                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}

export default PasswordInput;