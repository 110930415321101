import React from 'react';
import { Route, RouteProps } from "react-router-dom";
import AppLayout from 'admin/components/layout';

const DefaultRoute: React.FC<RouteProps> = ({ ...props }) => {
    return (<>
        <AppLayout>
            <Route {...props} />
        </AppLayout>
    </>);
};

export default DefaultRoute;