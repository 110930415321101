import Category, {categoryFields} from 'models/Category';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from "@material-ui/core";
import {GridItem, HorizontalGridLayout, VerticalGridLayout} from "components/controls/helpers/GridLayout";
import AddIcon from "@material-ui/icons/Add";
import {categoryPath, createCategoryPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";
import CustomFieldsListControl from "admin/components/CustomFieldsListControl";

interface Props {
    title: string,
    categoryId?: string;
    shopId?: string;
    categories: Category[],
    onAddAsync: (category: Category) => Promise<boolean>;
    onRemoveAsync: (category: Category) => Promise<boolean>;
}

// TODO: Use Tabs to separate the category details, subcategories list, and products list.
const CategoryList: FC<Props> = (props) => {
    const history = useHistory();

    function onItemClicked(category: Category) {
        history.push(categoryPath(category.id, props.shopId!));
    }

    function onCreateButtonClicked() {
        history.push(createCategoryPath(props.shopId!, props.categoryId));
    }

    return (<>
        <VerticalGridLayout spacing={2}>
            <HorizontalGridLayout>
                <GridItem style={{flexGrow: 1}}>
                    <Typography variant={"h6"}>
                        <strong>{props.title}</strong>
                    </Typography>
                </GridItem>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={onCreateButtonClicked}>New Contact</Button>
            </HorizontalGridLayout>

            <CustomFieldsListControl
                data={props.categories}
                fields={categoryFields}
                onItemClicked={c => onItemClicked(c as Category)}/>
        </VerticalGridLayout>
    </>);
}

export default CategoryList;