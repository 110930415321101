import React from 'react';
import {Container} from "@material-ui/core";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import UsersList from "admin/components/user/UsersList";

interface Props {
    shopId: string;
}

const IndexPage: React.FC<Props> = (props) => {
    return (<>
        <HtmlMetaDecorator title={"Users"}/>

        <Container>
            <UsersList shopId={props.shopId}/>
        </Container>
    </>);
}

export default IndexPage;