import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";
import AppSettings from "models/AppSettings";
import {default as defaultAppSettings} from "appSettings";
import ClientSettings from "models/ClientSettings";
import Api from "util/api/Api";
import {useAuthentication} from "contexts/AuthenticationProvider";

const CLIENT_SETTINGS = "ClientSettings";

export const AppSettingsContext = createContext<AppSettings>(defaultAppSettings);

export const useAppSettings = () => useContext(AppSettingsContext);

const AppSettingsProvider: FC = (props) => {
    const auth = useAuthentication();

    const [clientSettings, setClientSettings] = useState<ClientSettings>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        void async function fetchData() {
            // TODO: Store the data in local storage and invalidate it after some time.
            if ((!defaultAppSettings.authRequire || auth.isAuthenticated) && !isLoaded) {
                setIsLoading(true);
                setIsLoaded(true);

                const settings = await Api.settings.getClientSettingsAsync() as ClientSettings;
                sessionStorage.setItem(CLIENT_SETTINGS, JSON.stringify(settings));

                setClientSettings(settings);
                setIsLoading(false)
            }
        }();
    }, [auth.isAuthenticated, isLoaded]);

    if (isLoading) {
        return (<WaveLoadingPage/>)
    } else {
        let appSettings: AppSettings =
            JSON.parse(clientSettings?.description || "{}");

        return (
            <AppSettingsContext.Provider value={{...defaultAppSettings, ...appSettings}}>
                {props.children}
            </AppSettingsContext.Provider>
        );
    }
}

export default AppSettingsProvider;