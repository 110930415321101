import Category, {categoryFields} from 'models/Category';
import React, {FC, useEffect, useState} from 'react';
import Api from 'util/api';
import MessagingManager from 'util/MessagingManager';
import {Box, Container, Paper} from "@material-ui/core";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import Loading from "components/layout/Loading";
import DataNotFound from "components/controls/common/DataNotFound";
import {useHistory} from "react-router-dom";
import {appCategoryPath, categoryPath} from "admin/routers/PathBuilder";
import {Typography} from "components/controls/helpers/Typography";
import UpsertCustomFieldsControl from "admin/components/UpsertCustomFieldsControl";

type Props = {
    type: "App";
    categoryId: string;
    shopId?: never;
} | {
    type: "Shop";
    categoryId: string;
    shopId: string;
}

interface State {
    category?: Category;
    isLoading: boolean;
}

const EditPage: FC<Props> = (props) => {
    const history = useHistory();

    const [state, setState] = useState<State>({
        isLoading: true
    });

    useEffect(() => {
        void async function fetchData() {
            if (!state.category || state.category.id !== props.categoryId) {
                const data = (props.type === "Shop" ?
                    await Api.category.admin.getShopCategoryAsync(props.shopId, props.categoryId) :
                    await Api.category.admin.getAppCategoryAsync(props.categoryId)) as Category[];

                if (data && data.length) {
                    setState(s => ({...s, category: data[0]}));
                }
                setState(s => ({...s, isLoading: false}));
            }
        }();
    }, [props.type, props.shopId, props.categoryId, state.category]);

    async function saveCategory(category: Partial<Category>) {
        if (props.type === "Shop") {
            category.shopId = props.shopId;
        }

        return await Api.category.admin.updateCategoryAsync(category)
            .then((data) => {
                MessagingManager.toast({
                    message: `Updated successfully!`,
                    type: "success"
                });

                history.push(props.type === "Shop" ?
                    categoryPath(props.categoryId, props.shopId) : appCategoryPath(props.categoryId));
                return true;
            })
            .catch(() => false);
    }

    const category = state.category;

    if (state.isLoading) {
        return (<Loading/>);
    } else if (!category) {
        return (<DataNotFound/>);
    } else {
        return (<>
            <HtmlMetaDecorator title={`Edit Contact`}/>

            <Container>
                <Paper>
                    <Box p={2}>
                        <VerticalGridLayout spacing={4}>
                            <Typography variant={"h6"}>
                                <strong>Edit Contact</strong>
                            </Typography>

                            {/* NOTE: It's important that the UpsertControl gets render after the product is actually loaded,
                                 because if the FileUploadControl was initialized with an empty initialState, then the date that
                                 get loaded later will not be set as initialState. */}
                            <UpsertCustomFieldsControl
                                model={state.category}
                                fields={categoryFields}
                                actionText="Save"
                                onSubmit={async c => await saveCategory(c)}/>
                        </VerticalGridLayout>
                    </Box>
                </Paper>
            </Container>
        </>);
    }
}

export default EditPage;