import Api from 'util/api';
import AuthManager from './AuthManager';
import Shop from "models/Shop";

// TODO: Set a timeout on the cached data or find a way to check if the data was updated.
class AppCache {

    private static isLoadingProductSubscriptions: boolean = false;

    static async get<T>(key: string, callback: (() => Promise<T | undefined>)): Promise<T | undefined> {
        // TODO: Properly manage the cache.
        // const value = sessionStorage.getItem(key);
        // if (value) {
        //     return JSON.parse(value);
        // }

        const data = await callback();
        if (data) {
            sessionStorage.setItem(key, JSON.stringify(data));
        }

        return data;
    }

    static async getShop(shopId: string): Promise<Shop | undefined> {
        return this.get(`Shop/${shopId}`, async () => {
            const data = await Api.shop.getShopAsync(shopId) as Shop[];
            if (data && data.length) {
                return data[0];
            }
            return undefined
        })
    }

    static getAnonymousCartId() {
        if (!AuthManager.user.isAuthenticated) {
            const key = "AnonymousCartId";

            let cache = localStorage.getItem(key);
            if (!cache) {
                cache = Date.now().toString(36) + Math.random().toString(36);
                console.log(cache);
                localStorage.setItem(key, cache);
            }

            return cache;
        }
    }

}

export default AppCache;