import React, {FC} from "react";
import {Field, Form, Formik} from "formik";
import {Button} from "@material-ui/core";
import {VerticalGridLayout} from "components/controls/helpers/GridLayout";
import CustomInputComponent from "components/controls/helpers/CustomInputComponent";
import * as Yup from "yup";
import Api from "util/api/Api";
import MessagingManager from "util/MessagingManager";
import {useAppCache} from "contexts/AppCacheProvider";
import Lookup from "models/Lookup";

interface Props {
    id: string;
    itemId?: string;
    onSuccess: () => void;
}

const validation = Yup.object().shape<Partial<Lookup>>({
    name: Yup.string().required('Name is required')
});

const UpsertControl: FC<Props> = (props: Props) => {
    const cache = useAppCache();

    const initialValues: Partial<Lookup> = cache.lookups[props.id].find(l => l.id === props.itemId) || {};

    async function onSubmit(values: Partial<Lookup>) {
        if (props.itemId) {
            await Api.lookup.admin.updateLookupAsync(props.id, values as Lookup)
                .then((data) => {
                    MessagingManager.toast({
                        message: `'${values.name}' was updated successfully!`,
                        type: "success"
                    });

                    cache.updateLookupItem(props.id, data as Lookup);
                    props.onSuccess();
                });
        } else {
            await Api.lookup.admin.createLookupAsync(props.id, values)
                .then((data) => {
                    MessagingManager.toast({
                        message: `'${values.name}' was created successfully!`,
                        type: "success"
                    });

                    cache.addLookupItem(props.id, data as Lookup);
                    props.onSuccess();
                });
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values) => {
                await onSubmit(values)
            }}>

            <Form>
                <VerticalGridLayout spacing={3}>
                    <Field
                        name="name"
                        label="Name"
                        component={CustomInputComponent}/>

                    <Field
                        name="description"
                        label="Description"
                        component={CustomInputComponent}/>

                    <Button
                        type="submit"
                        size={"large"}
                        variant="contained"
                        fullWidth
                        color="primary">
                        Save
                    </Button>
                </VerticalGridLayout>
            </Form>
        </Formik>
    );
}

export default UpsertControl;