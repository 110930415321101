import BaseApi from "./BaseApi";
import ApiResponseOptions from "./models/ApiResponseOptions";
import Product from "models/Product";
import ApiResponse from "./models/ApiResponse";
import ProductExtras from "models/ProductExtras";
import ProductOptionGroup from "models/ProductOptionGroup";
import ProductOption from "models/ProductOption";

class ProductApi extends BaseApi {

    private _admin?: AdminProductApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminProductApi();
        }
        return this._admin;
    }

    async getProductAsync(shopId: string, productId: string, options?: ApiResponseOptions):
        Promise<Product[] | ApiResponse<Product[]> | undefined> {
        const path = `product/${shopId}?productid=${productId}`;
        return this.get(path, options);
    }

    async getProductsAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Product[] | ApiResponse<Product[]> | undefined> {
        return this.get(`product/${shopId}`, options);
    }

    async getSearchProductAsync(term: string, options?: ApiResponseOptions):
        Promise<Product[] | ApiResponse<Product[]> | undefined> {
        const path = `search/product/${term}`;
        return this.get(path, options);
    }

    async getSubscriptionsAsync(options?: ApiResponseOptions):
        Promise<Product[] | ApiResponse<Product[]> | undefined> {
        return this.get('subscription/product', options);
    }

    async subscribeAsync(product: Product, options?: ApiResponseOptions):
        Promise<Product | ApiResponse<Product> | undefined> {
        return this.put(`subscription/product/${product.id}`, product, options);
    }

    async unsubscribeAsync(productId: string, options?: ApiResponseOptions):
        Promise<any | ApiResponse<any> | undefined> {
        return this.delete(`subscription/product/${productId}`, null, options);
    }

    async getExtrasAsync(shopId: string, productId: string, options?: ApiResponseOptions):
        Promise<ProductExtras[] | ApiResponse<ProductExtras[]> | undefined> {
        const path = `shop/${shopId}/product/${productId}/group`;
        return this.get(path, options);
    }

}

class AdminProductApi extends BaseApi {

    async getProductAsync(shopId: string, productId: string, options?: ApiResponseOptions):
        Promise<Product[] | ApiResponse<Product[]> | undefined> {
        const path = `product/${shopId}?productid=${productId}&showHidden=true`;
        return this.get(path, options);
    }

    async getProductsAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Product[] | ApiResponse<Product[]> | undefined> {
        return this.get(`product/${shopId}?showHidden=true`, options);
    }

    async createProductAsync(shopId: string, product: Partial<Product>, options?: ApiResponseOptions):
        Promise<Product | ApiResponse<Product> | undefined> {
        product.shopId = shopId;
        return this.post<Product>("product", product, options);
    }

    async updateProductAsync(shopId: string, product: Partial<Product>, options?: ApiResponseOptions):
        Promise<Product | ApiResponse<Product> | undefined> {
        product.shopId = shopId;
        return this.put<Product>("product", product, options);
    }

    async deleteProductAsync(shopId: string, productId: string, options?: ApiResponseOptions):
        Promise<Product | ApiResponse<Product> | undefined> {
        const body = {
            id: productId,
            shopId: shopId
        };
        return this.delete<Product>("product", body, options);
    }

    async removeProductFromCategoryAsync(shopId: string, productId: string, categoryId: string, options?: ApiResponseOptions):
        Promise<Product | ApiResponse<Product> | undefined> {
        const body = {
            id: productId,
            categoryId: categoryId,
            shopId: shopId
        };
        return this.delete<Product>("product", body, options);
    }

    async getExtrasAsync(shopId: string, options?: ApiResponseOptions):
        Promise<any | ApiResponse<any> | undefined> {
        const path = `shop/${shopId}/productGroup`;
        return this.get<any>(path, options);
    }

    async createOptionGroupAsync(shopId: string, productId: string, group: Partial<ProductOptionGroup>, options?: ApiResponseOptions):
        Promise<ProductOptionGroup | ApiResponse<ProductOptionGroup> | undefined> {
        const path = `shop/${shopId}/product/${productId}/group`;
        return this.post<ProductOptionGroup>(path, group, options);
    }

    async updateOptionGroupAsync(shopId: string, productId: string, groupId: string, group: Partial<ProductOptionGroup>,
                                 options?: ApiResponseOptions):
        Promise<ProductOptionGroup | ApiResponse<ProductOptionGroup> | undefined> {
        const path = `shop/${shopId}/product/${productId}/group/${groupId}`;
        return this.put<ProductOptionGroup>(path, group, options);
    }

    async deleteOptionGroupAsync(shopId: string, productId: string, groupId: string, options?: ApiResponseOptions):
        Promise<ProductOptionGroup | ApiResponse<ProductOptionGroup> | undefined> {
        const path = `shop/${shopId}/product/${productId}/group/${groupId}`;
        return this.delete<ProductOptionGroup>(path, null, options);
    }

    async createOptionAsync(shopId: string, productId: string, groupId: string, option: Partial<ProductOption>,
                            options?: ApiResponseOptions):
        Promise<ProductOption | ApiResponse<ProductOption> | undefined> {
        const path = `shop/${shopId}/product/${productId}/group/${groupId}/option`;
        return this.post<ProductOption>(path, option, options);
    }

    async updateOptionAsync(shopId: string, productId: string, groupId: string, optionId: string,
                            option: Partial<ProductOption>, options?: ApiResponseOptions):
        Promise<ProductOption | ApiResponse<ProductOption> | undefined> {
        const path = `shop/${shopId}/product/${productId}/group/${groupId}/option/${optionId}`;
        return this.put<ProductOption>(path, option, options);
    }

    async deleteOptionAsync(shopId: string, productId: string, groupId: string, optionId: string, options?: ApiResponseOptions):
        Promise<ProductOption | ApiResponse<ProductOption> | undefined> {
        const path = `shop/${shopId}/product/${productId}/group/${groupId}/option/${optionId}`;
        return this.delete<ProductOption>(path, null, options);
    }

}

export default ProductApi;