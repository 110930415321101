import React, {FC} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import AuthManager from "util/AuthManager"
import "./LoginPage.scss";
import {Box, Container, Paper} from "@material-ui/core";
import LoginControl from "components/account/LoginControl";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {adminDashboardPath} from "admin/routers/PathBuilder";
import {useTranslation} from "react-i18next";
import Strings from "util/locale/Strings";

const LoginPage: FC = () => {
    const history = useHistory();
    const {t} = useTranslation();

    const onSuccessfulLogin = () => {
        if (AuthManager.user.isAdmin) {
            history.push(adminDashboardPath());
        } else {
            history.push('/');
        }
    }

    if (AuthManager.user.isAuthenticated) {
        return (AuthManager.user.isAdmin ?
            <Redirect to={adminDashboardPath()}/> : <Redirect to={'/'}/>);
    } else {
        return (<>
            <HtmlMetaDecorator
                title={t(Strings.login)}/>

            <div className={"login-page-main"}>
                <div className={"login-page-main"}>
                    <div className={"login-page-content"}>
                        <Container maxWidth="xs">
                            <Paper variant={"elevation"} elevation={5} style={{borderRadius: 12}}>
                                <Box py={5} my={2}>
                                    <LoginControl onSuccess={onSuccessfulLogin}/>
                                </Box>
                            </Paper>
                        </Container>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default LoginPage;