class ApiResponseOptions {

    returnResponse: boolean = false;
    returnErrors: boolean = false;

    constructor(params: Partial<ApiResponseOptions> = {}) {
        Object.assign(this, params);
    }

}

export default ApiResponseOptions;