import React, {FC, useEffect, useState} from 'react';
import 'App.scss';
import Order, {getItemsTotalPrice, OrderStatus, orderStatuses} from "models/Order";
import {
    Box,
    Button,
    Card, CardContent,
    CardHeader, Chip,
    Container, Divider, FormControl,
    Grid, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@material-ui/core";
import {HorizontalGridLayout} from "components/controls/helpers/GridLayout";
import OrderItemsList from "admin/components/order/OrderItemsList";
import Transaction from "models/Transaction";
import Api from "util/api/Api";
import TransactionStatus from "models/enums/TransactionStatus";
import AuthManager from "util/AuthManager";
import {useLocation} from "react-router-dom";
import WaveLoading, {WaveLoadingPage} from "components/controls/common/WaveLoading";
import DataNotFound from "components/controls/common/DataNotFound";
import {useAppSettings} from "contexts/AppSettingsProvider";
import MessagingManager from "util/MessagingManager";
import {Typography} from "components/controls/helpers/Typography";

interface Props {
    orderId: string;
    shopId: string;
}

const allowedTransactionStatuses = [
    TransactionStatus.Pending,
    TransactionStatus.Succeeded,
    TransactionStatus.Failed,
    TransactionStatus.Cancelled
]

const DetailsPage: FC<Props> = (props) => {
    const location = useLocation<Order>();
    const appSettings = useAppSettings();

    const [isLoading, setIsLoading] = useState(!location.state);
    const [order, setOrder] = useState<Order | undefined>(location.state);
    const [transaction, setTransaction] = useState<Transaction | undefined>();

    useEffect(() => {
        void async function fetchData() {
            if (!order || order.id !== props.orderId) {
                await Api.cart.admin.getOrderAsync(props.orderId, props.shopId)
                    .then(async data => {
                        const orders = data as Order[];
                        if (orders && orders.length) {
                            const o = orders[0];
                            setOrder(o);

                            // TODO: Fix the function dependency issue 'loadTransaction', and remove the below.
                            let trans: Transaction;

                            const data = (o.user ?
                                await Api.transaction.admin.getTransaction(props.shopId, o.transactionId, o.user.id) :
                                await Api.transaction.admin.getAnonymousTransaction(props.shopId, o.transactionId, o.anonymousCartId ?? "")) as Transaction[];

                            // TODO: This is a workaround, show proper data and allow admin to make different actions based on the status.
                            //  Currently, there will be cases where the admin will not see any transaction (ex: NONPENDING), and they will
                            //  not be able to do anything to the transaction.
                            if (data && data.length) {
                                const succeededTransactions = data.filter(t => t.status === TransactionStatus.Succeeded);

                                if (succeededTransactions.length) {
                                    trans = getLatestTransaction(succeededTransactions);
                                } else {
                                    const allowedTransactions = data.filter(t => {
                                        return allowedTransactionStatuses.some(s => s === t.status);
                                    });
                                    if (allowedTransactions.length) {
                                        trans = getLatestTransaction(allowedTransactions);
                                    } else {
                                        trans = getLatestTransaction(data);
                                        trans.status = TransactionStatus.Failed;
                                    }
                                }
                                setTransaction(trans);
                            }
                        }

                        setIsLoading(false);
                    });
            } else if (!transaction) {
                // TODO: Fix the function dependency issue 'loadTransaction', and remove the below.
                let trans: Transaction;

                const data = (order.user ?
                    await Api.transaction.admin.getTransaction(props.shopId, order.transactionId, order.user.id) :
                    await Api.transaction.admin.getAnonymousTransaction(props.shopId, order.transactionId, order.anonymousCartId ?? "")) as Transaction[];

                // TODO: This is a workaround, show proper data and allow admin to make different actions based on the status.
                //  Currently, there will be cases where the admin will not see any transaction (ex: NONPENDING), and they will
                //  not be able to do anything to the transaction.
                if (data && data.length) {
                    const succeededTransactions = data.filter(t => t.status === TransactionStatus.Succeeded);

                    if (succeededTransactions.length) {
                        trans = getLatestTransaction(succeededTransactions);
                    } else {
                        const allowedTransactions = data.filter(t => {
                            return allowedTransactionStatuses.some(s => s === t.status);
                        });
                        if (allowedTransactions.length) {
                            trans = getLatestTransaction(allowedTransactions);
                        } else {
                            trans = getLatestTransaction(data);
                            trans.status = TransactionStatus.Failed;
                        }
                    }
                    setTransaction(trans);
                }
                setIsLoading(false);
            }
        }();
    }, [props.shopId, props.orderId, order, transaction]);

    function getLatestTransaction(transactions: Transaction[]) {
        return transactions.reduce((t1, t2) =>
            (t1.modifiedAt?.toDate() || 0) > (t2.modifiedAt?.toDate() || 0) ? t1 : t2);
    }

    async function loadTransactions() {
        if (order) {
            let trans: Transaction;

            const data = (order.user ?
                await Api.transaction.admin.getTransaction(props.shopId, order.transactionId, order.user.id) :
                await Api.transaction.admin.getAnonymousTransaction(props.shopId, order.transactionId, order.anonymousCartId ?? "")) as Transaction[];

            // TODO: This is a workaround, show proper data and allow admin to make different actions based on the status.
            //  Currently, there will be cases where the admin will not see any transaction (ex: NONPENDING), and they will
            //  not be able to do anything to the transaction.
            if (data && data.length) {
                const succeededTransactions = data.filter(t => t.status === TransactionStatus.Succeeded);

                if (succeededTransactions.length) {
                    trans = getLatestTransaction(succeededTransactions);
                } else {
                    const allowedTransactions = data.filter(t => {
                        return allowedTransactionStatuses.some(s => s === t.status);
                    });
                    if (allowedTransactions.length) {
                        trans = getLatestTransaction(allowedTransactions);
                    } else {
                        trans = getLatestTransaction(data);
                        trans.status = TransactionStatus.Failed;
                    }
                }
                setTransaction(trans);
            }
            setIsLoading(false);
        }
    }

    async function confirmTransaction(amount: number, currency: string) {
        if (transaction) {
            const details = {
                method: transaction.method,
                // TODO: Allow the admin to set the value.
                acquirer: AuthManager.user.name,
                status: "Paid",
                amount: amount,
                currency: currency,
                shop: props.shopId
            };

            const isAnonymous = Boolean(transaction.order.anonymousCartId);
            const userId = isAnonymous ? transaction.order.anonymousCartId! : (transaction.order.user.id ?? "");
            await Api.transaction.admin.confirmTransaction(transaction.id, userId,
                isAnonymous, transaction.provider, details);

            // TODO: Refresh the Order as well
            await loadTransactions();
        }
    }

    const onOrderStatusChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOrder(ps => ({...ps!, status: event.target.value as OrderStatus}))
    }

    const onSaveOrderStatusClicked = async () => {
        const data = await Api.cart.admin.updateOrderStatusAsync(order!) as Order;
        if (data) {
            MessagingManager.toast({
                message: "Order status updated.",
                type: "success"
            })
        }
    }

    function getStatusLabel(status: string) {
        const style = {
            backgroundColor: "",
            color: ""
        };

        switch (status) {
            case TransactionStatus.Succeeded:
                style.backgroundColor = "limegreen";
                style.color = "darkgreen";
                break;
            case TransactionStatus.Pending:
                break;
            default:
                style.backgroundColor = "orangered";
                style.color = "white";
        }

        return (
            <Chip size={"small"} style={style} label={status}/>
        );
    }

    if (isLoading) {
        return (<WaveLoadingPage/>);
    } else if (!order) {
        return (<DataNotFound/>);
    } else {
        const subtotal = getItemsTotalPrice(order.items || []);
        const totalPrice = (subtotal + order.shippingCost);
        const currency = order.items![0].currency;

        return (
            <Container>
                <HorizontalGridLayout spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"}>
                            <strong>Order Details</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardHeader subheader={"Order Info"}/>
                            <Divider/>
                            <CardContent style={{padding: 0}}>
                                <Table size="small">
                                    <TableBody>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Order#
                                            </TableCell>
                                            <TableCell>
                                                {order.id}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Date
                                            </TableCell>
                                            <TableCell>
                                                {order.modifiedAt?.toFriendlyDateTime()}
                                            </TableCell>
                                        </TableRow>

                                        {
                                            order.comment &&
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Comments
                                                </TableCell>
                                                <TableCell>
                                                    {order.comment}
                                                </TableCell>
                                            </TableRow>
                                        }

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Shipping Cost
                                            </TableCell>
                                            <TableCell>
                                                {order.shippingCost.toFixed(appSettings.priceDecimals)} {currency}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Items Price
                                            </TableCell>
                                            <TableCell>
                                                {subtotal.toFixed(appSettings.priceDecimals)} {currency}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <strong>Total Price</strong>
                                            </TableCell>
                                            <TableCell>
                                                <strong>{totalPrice.toFixed(appSettings.priceDecimals)} {currency}</strong>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Order Status
                                            </TableCell>
                                            <TableCell>
                                                <HorizontalGridLayout spacing={2} alignItems={"center"}>
                                                    <Grid item style={{flexGrow: 1}}>
                                                        <FormControl size={"small"} variant="outlined" fullWidth>
                                                            <Select
                                                                fullWidth
                                                                value={order.status || "Pending"}
                                                                onChange={event => onOrderStatusChanged(event)}>
                                                                {orderStatuses.map(s => {
                                                                    return (<MenuItem key={s} value={s}>{s}</MenuItem>)
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Button
                                                        onClick={onSaveOrderStatusClicked}
                                                        variant={"contained"}
                                                        color={"primary"}>
                                                        Save
                                                    </Button>
                                                </HorizontalGridLayout>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardHeader subheader={"Payment Details"}/>
                            <Divider/>
                            <CardContent style={{padding: 0}}>
                                {transaction ?
                                    <Table size="small">
                                        <TableBody>

                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Method
                                                </TableCell>
                                                <TableCell>
                                                    {transaction.provider}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Status
                                                </TableCell>
                                                <TableCell>
                                                    {getStatusLabel(transaction.status)}
                                                </TableCell>
                                            </TableRow>

                                            {/*{*/}
                                            {/*    transaction.provider.toLowerCase() === "offline" &&*/}
                                            {/*    <TableRow>*/}
                                            {/*        <TableCell component="th" scope="row">*/}
                                            {/*            Acquirer*/}
                                            {/*        </TableCell>*/}
                                            {/*        <TableCell>*/}
                                            {/*            {*/}
                                            {/*                (transaction.status === TransactionStatus.Succeeded ||*/}
                                            {/*                    transaction.status === TransactionStatus.Failed) ?*/}
                                            {/*                    // TODO: Show the Acquirer value*/}
                                            {/*                    <p></p>*/}
                                            {/*                    :*/}
                                            {/*                    <Formik*/}
                                            {/*                        enableReinitialize={true}*/}
                                            {/*                        initialValues={{acquirer: ""}}*/}
                                            {/*                        validationSchema={*/}
                                            {/*                            Yup.object().shape<any>({*/}
                                            {/*                                acquirer: Yup.string().required('Acquirer is required')*/}
                                            {/*                            })}*/}
                                            {/*                        onSubmit={async values => {*/}
                                            {/*                            await this.confirmTransaction(totalPrice);*/}
                                            {/*                        }}>*/}
                                            {/*                        <Form id={"confirm-transaction-form"}>*/}
                                            {/*                            <Field*/}
                                            {/*                                name={"acquirer"}*/}
                                            {/*                                label={"Acquirer"}*/}
                                            {/*                                component={(props: FieldProps) => {*/}
                                            {/*                                    return (*/}
                                            {/*                                        <CustomInputComponent*/}
                                            {/*                                            {...props}*/}
                                            {/*                                            required/>*/}
                                            {/*                                    );*/}
                                            {/*                                }}/>*/}
                                            {/*                        </Form>*/}
                                            {/*                    </Formik>*/}
                                            {/*            }*/}
                                            {/*        </TableCell>*/}
                                            {/*    </TableRow>*/}
                                            {/*}*/}

                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <strong>Amount</strong>
                                                </TableCell>
                                                <TableCell>
                                                    <HorizontalGridLayout spacing={2} alignItems={"center"}>
                                                        <Grid item style={{flexGrow: 1}}>
                                                            <strong>{totalPrice.toFixed(appSettings.priceDecimals)} {currency}</strong>
                                                        </Grid>
                                                        {
                                                            transaction.provider.toLowerCase() === "offline" &&
                                                            transaction.status !== TransactionStatus.Succeeded &&
                                                            transaction.status !== TransactionStatus.Failed &&
                                                            <Button
                                                                onClick={() => confirmTransaction(totalPrice, currency)}
                                                                variant={"contained"}
                                                                color={"primary"}>
                                                                Paid
                                                            </Button>
                                                        }
                                                    </HorizontalGridLayout>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                    :
                                    <Box p={2}>
                                        <WaveLoading size={"small"}/>
                                    </Box>
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    {
                        order.user &&
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined">
                                <CardHeader subheader={"Customer Info"}/>
                                <Divider/>
                                <CardContent style={{padding: 0}}>
                                    <Table size="small">
                                        <TableBody>

                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Name
                                                </TableCell>
                                                <TableCell>
                                                    {order.user.name}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Phone Number
                                                </TableCell>
                                                <TableCell>
                                                    {order.user.phone}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Email
                                                </TableCell>
                                                <TableCell>
                                                    {order.user.email}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    }

                    {
                        order.shippingAddress && order.shippingAddress.fields &&
                        <Grid item xs={12} md={order.user ? 6 : 12}>
                            <Card variant="outlined">
                                <CardHeader subheader={"Shipping Details"}/>
                                <Divider/>
                                <CardContent style={{padding: 0}}>
                                    <Table size="small">
                                        <TableBody>
                                            {
                                                Object.keys(order.shippingAddress.fields).map(key => {
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell component="th" scope="row">
                                                                {key}
                                                            </TableCell>
                                                            <TableCell>
                                                                {order.shippingAddress.fields![key]}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardHeader subheader={"Order Items"}/>
                            <Divider/>
                            <CardContent style={{padding: 0}}>
                                <OrderItemsList items={order.items!}/>
                            </CardContent>
                        </Card>
                    </Grid>

                </HorizontalGridLayout>
            </Container>
        );
    }
}

export default DetailsPage;