import React, {FC} from 'react';
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import {Container} from "@material-ui/core";
import OptionGroupsList from "admin/components/product/options/OptionGroupsList";

interface Props {
    shopId: string;
}

const IndexPage: FC<Props> = (props) => {
    return (<>
        <HtmlMetaDecorator title={"Deals"}/>

        <Container maxWidth={"xl"}>
            <OptionGroupsList
                productId={""}
                shopId={props.shopId}/>
        </Container>
    </>);
}

export default IndexPage;