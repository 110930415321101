import Model from "./Model";
import CustomField from "models/CustomField";

export const productFields: CustomField[] = [
    {
        name: "name",
        label: "Name",
        settings: {
            indexed: true,
            filterable: false,
            searchable: true
        }
    },
    {
        name: "email",
        label: "Email Address",
        settings: {
            indexed: true,
            filterable: true,
            searchable: true
        }
    },
    {
        name: "country",
        label: "Country",
        settings: {
            indexed: true,
            filterable: true
        }
    },
    {
        name: "phoneNumber",
        label: "Phone Number",
        settings: {
            indexed: true,
            filterable: true,
            searchable: true
        }
    },
    {
        name: "subscriptionStatus",
        label: "Subscription Status",
        settings: {
            indexed: true,
            filterable: true
        }
    },
    {
        name: "lifecycle",
        label: "Lifecycle",
        type: "lookup",
        values: "lifecycle",
        settings: {
            indexed: true,
            filterable: true,
            searchable: false
        }
    },
    {
        name: "notes",
        label: "Notes",
        settings: {
            multiline: true
        }
    },
    {
        name: "attachments",
        label: "Attachments",
        type: "attachments",
        settings: {
            indexable: false
        }
    }
];

interface Product extends Model {
    isOffer: boolean;
    imageUrls: string[];
    description: string;
    shortDescription: string;
    sku: string;
    tags: string;
    size: string;
    unit: string;
    brand: string;
    currency: string;
    price: number;
    shopId: string;
    categoryId: string;
    skipIndex: boolean;
    isAvailable: boolean;
    isHidden: boolean;

    // TODO: Remove this. It's a temporary solution to allow the product details page to load the details
    //  in case of product details coming form the search.
    reload: boolean;
}

export default Product;