import React, {FC, useEffect, useState} from 'react';
import Modal from 'components/controls/Modal';
import Shop from 'models/Shop';
import {useHistory} from 'react-router-dom';
import Api from 'util/api';
import {Avatar, Box, Button, Chip, Container, Link} from "@material-ui/core";
import Panel from "components/controls/common/Panel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import 'App.scss';
import {VerticalGridLayout, HorizontalGridLayout} from "components/controls/helpers/GridLayout";
import HtmlMetaDecorator from "components/HtmlMetaDecorator";
import MessagingManager from "util/MessagingManager";
import {Typography} from "components/controls/helpers/Typography";
import TabsControl, {TabPanel} from "components/controls/common/TabsControl";
import AppCache from "util/AppCache";
import AuthManager from "util/AuthManager";
import {useAppSettings} from "contexts/AppSettingsProvider";
import ProductsIndexPage from "admin/pages/product/IndexPage";
import {WaveLoadingPage} from "components/controls/common/WaveLoading";
import DataNotFound from "components/controls/common/DataNotFound";
import CategoriesIndexPage from "admin/pages/category/IndexPage";
import OrdersIndexPage from "admin/pages/order/IndexPage";
import {editShopPath, shopIndexPath} from "admin/routers/PathBuilder";
import UsersIndexPage from "admin/pages/user/IndexPage";

interface Props {
    shopId: string;
}

interface State {
    shop?: Shop;
    isDeleteModalShown: boolean;
    isLoading: boolean;
}

const DetailsPage: FC<Props> = (props) => {
    const appSettings = useAppSettings();
    const history = useHistory();

    const [state, setState] = useState<State>({
        isDeleteModalShown: false,
        isLoading: true
    });

    useEffect(() => {
        void async function fetchData() {
            if (!AuthManager.user.isAppAdmin && AuthManager.user.shopId !== props.shopId) {
                setState(prevState => ({...prevState, isLoading: false}));
                return;
            }

            const shop = await AppCache.getShop(props.shopId);
            setState(prevState => ({
                ...prevState,
                shop: shop,
                isLoading: false
            }));
        }();
    }, [props.shopId]);

    function toggleDeleteModal() {
        setState(prevState => ({
            ...prevState,
            isDeleteModalShown: !prevState.isDeleteModalShown
        }));
    }

    async function deletedShop() {
        const shopId = state.shop?.id;
        if (shopId) {
            await Api.shop.admin.deleteShopAsync(shopId)
                .then(() => {
                    MessagingManager.toast({
                        message: `'${state.shop?.name}' was deleted`,
                        type: "success"
                    })
                    history.push(shopIndexPath());
                });
        }
    }

    function onEditButtonClicked() {
        if (state.shop?.id) {
            history.push(editShopPath(state.shop.id));
        }
    }

    const isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    const shop = state.shop;

    if (state.isLoading) {
        return (<WaveLoadingPage/>);
    } else if (!shop) {
        return (<DataNotFound/>);
    } else {
        return (<>
            <HtmlMetaDecorator
                title={shop.name}/>

            <Container>
                <TabsControl>

                    <TabPanel title={"Details"}>
                        <Box pt={2}>
                            <VerticalGridLayout spacing={2}>
                                <HorizontalGridLayout justify={"flex-end"} spacing={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<EditIcon/>}
                                        onClick={onEditButtonClicked.bind(this)}>Edit</Button>

                                    {isMultiShop &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<DeleteIcon/>}
                                        onClick={toggleDeleteModal.bind(this)}>Delete</Button>}
                                </HorizontalGridLayout>

                                <Panel>
                                    <VerticalGridLayout spacing={3}>

                                        <HorizontalGridLayout alignItems={"center"} spacing={3}>
                                            <Link href={shop.logoUrl} target={"_blank"}>
                                                <Avatar src={shop.logoUrl} style={{width: 100, height: 100}}/>
                                            </Link>

                                            <VerticalGridLayout spacing={1}>
                                                <Typography variant={"h5"}>{shop.name}</Typography>
                                                {
                                                    shop.isHidden &&
                                                    <Chip color={"secondary"} label={"Hidden"}/>
                                                }
                                            </VerticalGridLayout>
                                        </HorizontalGridLayout>

                                        {
                                            shop.shortDescription &&
                                            <Typography variant={"body1"}>
                                                <strong>Short Description: </strong>
                                                {shop.shortDescription}
                                            </Typography>

                                        }

                                        {
                                            shop.description &&
                                            <Typography variant={"body1"} style={{whiteSpace: "pre-wrap"}}>
                                                <strong>Description: </strong>
                                                {shop.description}
                                            </Typography>
                                        }

                                        <Typography variant={"body1"}>
                                            <strong>Shipping Cost: </strong>
                                            {`${shop.shippingCost} ${appSettings.defaultCurrency}`}
                                        </Typography>
                                    </VerticalGridLayout>
                                </Panel>

                                <p style={{color: "gray"}}>
                                    <strong>Last Update: </strong>
                                    {shop.modifiedAt?.toFriendlyDateTime()}
                                </p>

                            </VerticalGridLayout>
                        </Box>
                    </TabPanel>

                    {isMultiShop &&
                    <TabPanel title={"Categories"}>
                        <Box pt={2}>
                            <CategoriesIndexPage   shopId={shop.id}/>
                        </Box>
                    </TabPanel>}

                    {isMultiShop &&
                    <TabPanel title={"Products"}>
                        <Box pt={2}>
                            <ProductsIndexPage shopId={shop.id}/>
                        </Box>
                    </TabPanel>}

                    {isMultiShop &&
                    <TabPanel title={"Orders"}>
                        <Box pt={2}>
                            <OrdersIndexPage shopId={shop.id}/>
                        </Box>
                    </TabPanel>}

                    {isMultiShop &&
                    <TabPanel title={"Admin Users"}>
                        <Box pt={2}>
                            <UsersIndexPage shopId={shop.id}/>
                        </Box>
                    </TabPanel>}
                </TabsControl>
            </Container>

            <Modal
                title={`Are you sure you want to delete ${shop.name}?`}
                open={state.isDeleteModalShown}
                toggleModal={toggleDeleteModal}>

                <HorizontalGridLayout justify="flex-end" spacing={1}>
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<CancelIcon/>}
                        onClick={toggleDeleteModal}>Cancel</Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon/>}
                        onClick={deletedShop}>Delete</Button>
                </HorizontalGridLayout>
            </Modal>
        </>);
    }
}

export default DetailsPage;