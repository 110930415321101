import React, {FC} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import IndexPage from 'admin/pages/shop/IndexPage';
import CreatePage from 'admin/pages/shop/CreatePage';
import DetailsPage from 'admin/pages/shop/DetailsPage';
import EditPage from 'admin/pages/shop/EditPage';
import {parseQuery} from "router/PathBuilder";
import terminologies from "terminologies";
import AuthManager from "util/AuthManager";
import {useAppSettings} from "contexts/AppSettingsProvider";

const ShopRouter: FC = () => {
    const t = terminologies;
    const appSettings = useAppSettings();

    const isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    const getShopId = (params: any) => isMultiShop ?
        params[t.shopId] : appSettings.isSingleShop ?
            appSettings.singleShopId : AuthManager.user.shopId;

    return (
        <Switch>
            {!appSettings.isSingleShop &&
            <Route exact path={`/${t.shop}/create`}
                   render={({location}) =>
                       <CreatePage categoryId={parseQuery(location.search)?.[t.appCategoryId]}/>
                   }/>}

            <Route exact path={`/${t.shop}/:${t.shopId}?/edit`}
                   render={({match}) => <EditPage shopId={getShopId(match.params)}/>}/>

            <Route exact path={`/${t.shop}/:${t.shopId}`}
                   render={({match}) => <DetailsPage shopId={getShopId(match.params)}/>}/>

            <Route exact path={`/(${t.shop})`}
                   render={({match}) => isMultiShop ?
                       <IndexPage/> : <DetailsPage shopId={getShopId(match.params)}/>
                   }/>

            <Redirect exact to={"/404"}/>
        </Switch>
    );
}

export default ShopRouter;