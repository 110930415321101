class TransactionStatus {
    static Invalid = "INVALID";
    static Pending = "PENDING";
    static Unverified = "UNVERIFIED";
    static Succeeded = "SUCCEEDED";
    static Failed = "FAILED";
    static Unauthorized = "UNAUTHORIZED";
    static NonPending = "NONPENDING";
    static Cancelled = "CANCELLED";
    static InvalidItem = "INVALIDITEM";
    static IncorrectAmount = "INCORRECTAMOUNT";
}

export default TransactionStatus;