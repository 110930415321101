import React, {FC} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import IndexPage from 'admin/pages/order/IndexPage';
import DetailsPage from 'admin/pages/order/DetailsPage';
import terminologies from "terminologies";
import AuthManager from "util/AuthManager";
import {useAppSettings} from "contexts/AppSettingsProvider";

const OrderRouter: FC = () => {
    const t = terminologies;
    const appSettings = useAppSettings();

    const isMultiShop = AuthManager.user.isSuperAdmin ||
        (AuthManager.user.isAppAdmin && !appSettings.isSingleShop);

    const getShopId = (params: any) => isMultiShop ?
        params[t.shopId] : appSettings.isSingleShop ?
            appSettings.singleShopId : AuthManager.user.shopId;

    return (
        <Switch>
            <Route exact
                   path={`/(${t.shop})?/:${t.shopId}?/${t.order}/:${t.orderId}`}
                   render={({match}) =>
                       <DetailsPage
                           shopId={getShopId(match.params)}
                           orderId={match.params[t.orderId]}/>
                   }/>

            <Route exact path={`/(${t.shop})?/:${t.shopId}?/${t.order}`}
                   render={({match}) =>
                       <IndexPage shopId={getShopId(match.params)}/>
                   }/>

            <Redirect to="/404"/>
        </Switch>
    );
}

export default OrderRouter;