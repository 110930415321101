import React, {FC} from 'react'
import {Box, Dialog, DialogContent, DialogTitle, Divider, IconButton} from "@material-ui/core";
import {GridItem, HorizontalGridLayout} from "components/controls/helpers/GridLayout";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    title?: string;
    open: boolean;
    disableBackdropClick?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    toggleModal: () => void;
}

// TODO: Allow managing elements and attributes, like footer, positive and negative footer buttons, etc.
const Modal: FC<Props> = (props) => {
    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={props.maxWidth}
            disableBackdropClick={props.disableBackdropClick}
            onClose={props.toggleModal}>
            <HorizontalGridLayout alignItems={"center"}>
                <GridItem style={{flexGrow: 1}}>
                    <DialogTitle>{props.title}</DialogTitle>
                </GridItem>
                <IconButton onClick={props.toggleModal}>
                    <CloseIcon/>
                </IconButton>
            </HorizontalGridLayout>
            <Divider/>
            <DialogContent>
                <Box py={2}>
                    {props.children}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default Modal;