import BaseApi from "./BaseApi";
import ApiResponseOptions from "./models/ApiResponseOptions";
import Shop from "models/Shop";
import ApiResponse from "./models/ApiResponse";

class ShopApi extends BaseApi {

    private _admin?: AdminShopApi;

    get admin() {
        if (!this._admin) {
            this._admin = new AdminShopApi();
        }
        return this._admin;
    }

    async getShopAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Shop[] | ApiResponse<Shop[]> | undefined> {
        const path = `shop?id=${shopId}`;
        return this.get(path, options);
    }

    async getShopsAsync(options?: ApiResponseOptions):
        Promise<Shop[] | ApiResponse<Shop[]> | undefined> {
        return this.get(`shop`, options);
    }
}

class AdminShopApi extends BaseApi {

    async getShopAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Shop[] | ApiResponse<Shop[]> | undefined> {
        const path = `shop?id=${shopId}&showHidden=true`;
        return this.get(path, options);
    }

    async getShopsAsync(options?: ApiResponseOptions):
        Promise<Shop[] | ApiResponse<Shop[]> | undefined> {
        return this.get(`shop?showHidden=true`, options);
    }

    async createShopAsync(shop: Partial<Shop>, options?: ApiResponseOptions):
        Promise<Shop | ApiResponse<Shop> | undefined> {
        return this.post<Shop>("shop", shop, options);
    }

    async updateShopAsync(shop: Partial<Shop>, options?: ApiResponseOptions):
        Promise<Shop | ApiResponse<Shop> | undefined> {
        return this.put<Shop>("shop", shop, options);
    }

    async deleteShopAsync(shopId: string, options?: ApiResponseOptions):
        Promise<Shop | ApiResponse<Shop> | undefined> {
        const body = {id: shopId};
        return this.delete<Shop>("shop", body, options);
    }

    async removeShopFromCategoryAsync(shopId: string, categoryId: string, options?: ApiResponseOptions):
        Promise<Shop | ApiResponse<Shop> | undefined> {
        const body = {
            id: shopId,
            categoryId: categoryId
        };
        return this.delete<Shop>("shop", body, options);
    }

}

export default ShopApi;